import React, { FC } from "react";
import { TickMarkIcon } from "../../../assets/svg/SVGIconsCollection";
import { isMobile } from "../../../helper/HelperFunctions";
import { useAppSelector } from "common/hooks/redux-hooks";
import { AppState } from "store";
import { gatherStepsWithStatus } from "common/model/gatherStepsWithStatus";
import { GatherStepStatus } from "common/enum";

const StepComponent: FC = () => {

    const stepsWithStatus: gatherStepsWithStatus[] = useAppSelector((state: AppState) => state.gatherStepsWithStatusReducer?.stepsWithStatus);

    const currentStep = stepsWithStatus.find((step) => step.current === true)

    const getDoneStatus = (i: number) => {
        return stepsWithStatus[i].status === GatherStepStatus.Done;
    };

    return (<div className="header-step-container">
        {stepsWithStatus.map((item, i) => {
            return <>
                {i !== 0 && <div className="horizontal-line" key={i}></div>}
                <div key={i} className={`step-wrapper ${currentStep?.name === item.name ? "current" : ""} ${getDoneStatus(i) ? "done" : ""}`}>
                    <div className={`step ${currentStep?.name === item.name ? "current" : ""}`} key={`step-${item}-index`}>
                        {!getDoneStatus(i) ? i + 1 :
                            <TickMarkIcon height={isMobile() ? 8 : 10} width={isMobile() ? 10 : 13} color="#000" />}
                    </div>
                    <div className="step-name">{item.name}</div>
                </div>
            </>
        })}
    </div>)
}

export default StepComponent;