/* This is to keep all the API enpoints in one place . Here, You can add/update any API route and You can add/update any API endpoint
   You can use this endpoints anywhere in the application by importing the API object
   Sample usage:
   For this enpoint `https://orion-dev-organizers-cpa-api.safesendwebsites.com/api/organizer/clients/GetClientInfoAsync`,
   You can use the below 
   {API.ORGANIZER.CLIENTS.GET_CLIENT_INFO_ASYNC}
*/

const API_BASE_URL = `${process.env.REACT_APP_API_URL}api`;
const SSSUITE_API_BASE_URL = `${process.env.REACT_APP_SSSUITE_API_URL}api`;

// API routes
const MY_ACCOUNT = "MyAccount";
const WEB_SOCKET = "web-socket";
const DOWNLOAD = "download";
const MENU = "menu";
const COVERPAGE = "CoverPage";
const GATHER = "gather";
const OTP = "OTP";
const CUSTOM_QUESTION = "CustomQuestionnaire";
const DOCUMENT_REQUEST = "document-request-mapping";
const PREPARERMESSAGE = "preparer-message";
const SOURCE_DOCUMENT = "source-document-upload";
const ORGANIZER = "organizer-sign";
const ESIGN = "esign";
const SIGNALR = "signalr";
const COMPANY_SETTINGS = "company-settings";
const DOCUMENT = "document";
const ONEHUB = "OneHub";

// API endpoints
export const APP_INSIGHT_URL = `${API_BASE_URL}/ApplicationInsights`;

export const API = {
    // login and user 
    STAY_ALIVE_ASYNC: `${API_BASE_URL}/${MY_ACCOUNT}/StayAliveAsync`,
    MENU: {
        PRODUCT_LEFT_MENU: `${SSSUITE_API_BASE_URL}/${MENU}/product-left-menu`,
        PRODUCT_WIDGET_MENU: `${SSSUITE_API_BASE_URL}/${MENU}/widget-menu`,
    },
    GATHER_CLIENT: {
        GET_SPOUSE_INFO: `${API_BASE_URL}/gather-client/spouse-info`,
        HIDE_SPOUSE_INFO_WARNING: `${API_BASE_URL}/gather-client/hide-warning`,
        GET_TAXPAYER_INFO:`${API_BASE_URL}/gather-client/taxpayer-info`,
        SAVE_MOBILE_EMAIL:`${API_BASE_URL}/gather-client/update-email-and-mobile`
    },
    ORGANIZER: {
        GET_ORGANIZER_DOCUMENT: `${API_BASE_URL}/${ORGANIZER}/document-model`,
        GET_ORGANIZER_CONTROL_DATA: `${API_BASE_URL}/${ORGANIZER}/controls-link`,
        SAVE_ORGANIZER_TAXPAYER_NOTES: `${API_BASE_URL}/${ORGANIZER}/form-note`,
        DELETE_ORGANIZER_TAXPAYER_NOTES: `${API_BASE_URL}/${ORGANIZER}/form-note`,
        SAVE_ORGANIZER_DOCUMENT: `${API_BASE_URL}/${ORGANIZER}/save`,
        AUTO_SAVE_ORGANIZER_DOCUMENT: `${API_BASE_URL}/${ORGANIZER}/autoSave`,
        FINISH_ORGANIZER_DOCUMENT: `${API_BASE_URL}/${ORGANIZER}/finish`,
        GET_ORGANIZER_META_DATA: `${API_BASE_URL}/${ORGANIZER}/meta-data`,
        SEND_ORGANIZER_FOR_REVIEW: `${API_BASE_URL}/${ORGANIZER}/send-for-review`,
        REVIEW_ORGANIZER_DOCUMENT: `${API_BASE_URL}/${ORGANIZER}/review-organizer`
    },
    COVER_PAGE : {
        GET_AUTHENTICATION_TYPE :`${API_BASE_URL}/${COVERPAGE}/authentication-type`,
        GET_HEADER_DETAILS :`${API_BASE_URL}/${COVERPAGE}/header-info`,
        GET_CLIENT_ID : `${API_BASE_URL}/${COVERPAGE}/clientId`,
        GET_CLIENT_MOBILE_NUMBER :`${API_BASE_URL}/${COVERPAGE}/client-mobile-number`,
        GET_CLIENT_INFO:`${API_BASE_URL}/${COVERPAGE}/client-info`
    },
    OTP: {
        VALIDATE_LINK: `${API_BASE_URL}/${OTP}`,
        GENERATE_MOBILE_OTP: `${API_BASE_URL}/${OTP}/Mobile/Generate`,
        GENERATE_OTP: `${API_BASE_URL}/${OTP}/Generate`,
        VALIDATE_OTP: `${API_BASE_URL}/${OTP}/Validate`,
        VALIDATE_MOBILE_OTP: `${API_BASE_URL}/${OTP}/Mobile/Validate`,
        LOGOUT:`${API_BASE_URL}/${OTP}/logout`,
        REFRESH_TOKEN:`${API_BASE_URL}/${OTP}/RefreshCookie`
    },
    CUSTOM_QUESTION :{
        GET_FORM_DATA:`${API_BASE_URL}/${CUSTOM_QUESTION}`,
        GET_CUSTOM_STATUS: `${API_BASE_URL}/${CUSTOM_QUESTION}/custom-question-status`,
        SAVE_FORM_DATA : `${API_BASE_URL}/${CUSTOM_QUESTION}/save`,
        FINISH_FORM : `${API_BASE_URL}/${CUSTOM_QUESTION}/finish`,
        AUTO_SAVE_FORM : `${API_BASE_URL}/${CUSTOM_QUESTION}/auto-save`
    },
    DOCUMENT_REQUEST : {
        GET_GATHER_DRL_NAMES :`${API_BASE_URL}/${DOCUMENT_REQUEST}/gather-drl-names`,
        UPDATE_DRL_MAPPING :`${API_BASE_URL}/${DOCUMENT_REQUEST}`,
        GET_GATHER_DRL_MAPPINGS : `${API_BASE_URL}/${DOCUMENT_REQUEST}`,
        UPDATE_NOT_APPLICABLE_STATUS : `${API_BASE_URL}/${DOCUMENT_REQUEST}/not-applicable`
    },
    SOURCE_DOCUMENT :{
        GET_SOURCE_DOCUMENTS_FOR_ORGANIZER :`${API_BASE_URL}/source-document`,
        GET_SOURCE_DOCUMENT_UPLOAD_LINK :`${API_BASE_URL}/${SOURCE_DOCUMENT}`,
        ADD_SOURCE_DOCUMENT :`${API_BASE_URL}/${SOURCE_DOCUMENT}`,
        DELETE_SOURCE_DOCUMENT : `${API_BASE_URL}/${SOURCE_DOCUMENT}`,
        MARK_SOURCE_DOCUMENT_STATUS_COMPLETED :`${API_BASE_URL}/${SOURCE_DOCUMENT}/Finish`,
        GET_SOURCE_DOCUMENT_STATUS :`${API_BASE_URL}/${SOURCE_DOCUMENT}/Status`,
        CONTINUE_LATER :`${API_BASE_URL}/${SOURCE_DOCUMENT}/continue-later`,
        GET_SOURCE_DOCUMENT_PREVIEW :`${API_BASE_URL}/${SOURCE_DOCUMENT}/Preview`
    },
    E_SIGN: {
        GET_ESIGN_DOCUMENT: `${API_BASE_URL}/${ESIGN}/document-model`,
        GET_ESIGN_BATCH_ENGAGEMENTLETTER_CONTROLSLINK: `${API_BASE_URL}/${ESIGN}/batch/engagement-letter-controls/link`,
        GET_ESIGN_STATUS: `${API_BASE_URL}/${ESIGN}/status`,
        POST_ESIGN_SIGN: `${API_BASE_URL}/${ESIGN}/sign`,
        POST_ESIGN_BATCHSIGN: `${API_BASE_URL}/${ESIGN}/batch-sign`,
        POST_ESIGN_SKIP: `${API_BASE_URL}/${ESIGN}/decline`,
        POST_ESIGN_BATCHSKIP: `${API_BASE_URL}/${ESIGN}/batch-decline`,
    },
    LANDING_PAGE :{
        GET_PREPARER_MESSAGE:`${API_BASE_URL}/${PREPARERMESSAGE}`,
        GET_STEPS_WITH_STATUS:`${API_BASE_URL}/${GATHER}/steps-with-status`,
        GET_ENGAGEMENT_LETTER_DOWNLOAD:`${API_BASE_URL}/${DOWNLOAD}/engagement-letter-download`,
        GET_ORGANIZER_DOCUMENT_DOWNLOAD:`${API_BASE_URL}/${DOWNLOAD}/organizer-document-download`,
        GET_CUSTOM_QUESTIONNAIRE_DOCUMENT_DOWNLOAD:`${API_BASE_URL}/${DOWNLOAD}/custom-questionnaire-document-download`
    },
    PREVIEW_PAGE:{
        GET_PREVIEW_DOCUMENT_STATUS: `${API_BASE_URL}/${DOCUMENT}/preview-status`
    },
    COMPANY_SETTINGS: {
        GET_COMPANY_SETTINGS: `${API_BASE_URL}/${COMPANY_SETTINGS}`,
        GET_UTE_SETTINGS: `${API_BASE_URL}/${COMPANY_SETTINGS}/ute-setting`
    },
    ONEHUB: {
        GET_ONEHUB_REDIRECT_URL: `${API_BASE_URL}/${ONEHUB}/onehub-redirect-url`
    },

    // common
    SIGNALR_NEGOTIATE_URL: `${SIGNALR}/Negotiate`,
    SIGNALR_ADD_TO_GROUP_URL: `${SIGNALR}/AddToGroup`,
    GET_WEBSOCKET_CONNECTION_URL: `${API_BASE_URL}/${WEB_SOCKET}/ConnectionInfo`,
    DOWNLOAD: `${API_BASE_URL}/${DOWNLOAD}/GetCompanySignatureUploadLinkAsync`,
};