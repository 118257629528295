import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IFormData, QuestionType, intialQuestionnaireState } from "common/model/custom-questions-model";
import { IHandleChoiceIdentifierPayload, IOnChangeHandlerPayload, IYesNoChoiceIdentifierPayload } from "store/types/cq-preview";


function deepClone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

const customQuestionSlice = createSlice({
    name: 'customQuestionPage',
    initialState: intialQuestionnaireState,
    reducers: {
        setQuestionnaire(state, action: PayloadAction<IFormData>) {
            state.formData = action.payload;
            state.intialFormData = action.payload;
            return state;
        },
        setInititalFormDataOnAutoSave(state, action: PayloadAction<IFormData>) {
            state.intialFormData = action.payload
            return state;
        },
        handleParagraphOnChange(state, action: PayloadAction<IOnChangeHandlerPayload>) {
            const { sectionIndex, sectionItemIndex, value, choiceId, subQuestionIndex } = action.payload;
            const selectedItem = state.formData.sections[sectionIndex]?.sectionItems[sectionItemIndex];
            if (choiceId && selectedItem?.choices?.length > 0) {
                selectedItem.choices.forEach((choice) => {
                    if (choice.id === choiceId && choice.subQuestions?.length > 0) {
                        choice.subQuestions[subQuestionIndex || 0].answer.answerText = value;
                    }
                })
            }
            else if (state.formData.sections[sectionIndex]) {
                state.formData.sections[sectionIndex].sectionItems[sectionItemIndex].answer.answerText = value;
            }
            state.formData.sections[sectionIndex].sectionItems[sectionItemIndex] = selectedItem;
            return state;
        },
        handleYesNoOnChange(state, action: PayloadAction<IYesNoChoiceIdentifierPayload>) {
            const { choiceId, sectionIndex, sectionItemIndex, subChoiceId } = action.payload;
            const selectedItem = state.formData.sections[sectionIndex].sectionItems[sectionItemIndex];

            if (selectedItem?.choices?.length > 0) {
                selectedItem.choices.forEach((choice) => {
                    if (choice.subQuestions?.length > 0) {
                        choice.subQuestions.forEach((subQuestion) => {
                            subQuestion.answer.answerText = "";
                        })
                    }
                    if (subChoiceId && choice.subQuestions?.length > 0) {
                        choice.subQuestions.forEach(subQuestion => {
                            let subQuestionChoices = subQuestion.choices;
                            subQuestionChoices.forEach((subChoice, index) => {
                                if (subChoiceId === subChoice.id) {
                                    subChoice.isSelected = !subChoice.isSelected;
                                    if (index === 0) { subQuestionChoices[1].isSelected = false }
                                    else { subQuestionChoices[0].isSelected = false }
                                }
                            })
                        })
                    }
                    else if (choiceId === choice.id) {
                        choice.isSelected = !choice.isSelected;
                    } else {
                        choice.isSelected = false;
                        if (choice.subQuestions?.length > 0) {
                            choice.subQuestions.forEach(subQuestion => {
                                let subQuestionChoices = subQuestion.choices;
                                subQuestionChoices?.forEach((subChoice) => {
                                    subChoice.isSelected = false;
                                })
                            })
                        }
                    }
                });
                state.formData.sections[sectionIndex].sectionItems[sectionItemIndex] = selectedItem;
                if (selectedItem.isRequired) {
                    let isAnySelected = selectedItem.choices.some(choice => choice.isSelected === true);

                    state.formData.sections[sectionIndex].sectionItems[
                        sectionItemIndex
                    ].isInValid = !isAnySelected;
                }
            }
            return state;
        },
        handleMcqOnChange(state, action: PayloadAction<IHandleChoiceIdentifierPayload>) {
            const { choiceId, sectionIndex, sectionItemIndex, isFollowUpQuestion, subQuestionId } = action.payload;
            const selectedItem = state.formData.sections[sectionIndex].sectionItems[sectionItemIndex];

            if (selectedItem?.choices?.length > 0) {
                selectedItem.choices.forEach((choice) => {
                    if (isFollowUpQuestion && choice.subQuestions?.length > 0) {
                        choice.subQuestions.forEach(subQuestion => {
                            if (subQuestion.sectionItemId === subQuestionId) {
                                let subQuestionChoices = subQuestion.choices;
                                subQuestionChoices.forEach((subChoice) => {
                                    if (choiceId === subChoice.id) {
                                        subChoice.isSelected = !subChoice.isSelected;
                                    }
                                    else {
                                        subChoice.isSelected = false;
                                    }
                                })
                            }
                        })
                    }
                    else {
                        if (choiceId === choice.id) {
                            choice.isSelected = !choice.isSelected;
                        }
                        else {
                            choice.isSelected = false;
                        }
                    }
                });
                state.formData.sections[sectionIndex].sectionItems[sectionItemIndex] = selectedItem;
                if (selectedItem.isRequired) {
                    let isAnySelected = selectedItem.choices.some(choice => choice.isSelected === true);

                    state.formData.sections[sectionIndex].sectionItems[
                        sectionItemIndex
                    ].isInValid = !isAnySelected;
                }
            }
            return state;
        },

        setIsInvalid(state, action: PayloadAction<IFormData>) {
            const updatedFormData = deepClone(action.payload);
            updatedFormData.sections.forEach((section, sectionIndex) =>
                section.sectionItems.forEach((sectionItem, sectionItemIndex) => {
                    if (sectionItem.isRequired) {
                        let isInValid = false;
                        switch (sectionItem.questionType) {
                            case QuestionType.PARAGRAPH: {
                                if (
                                    sectionItem.answer === undefined ||
                                    sectionItem.answer.answerText === undefined ||
                                    sectionItem.answer?.answerText?.trim().length === 0
                                ) {
                                    isInValid = true;
                                }
                                break;
                            }
                            case QuestionType.YESNO: {
                                const selectedItem = updatedFormData.sections[sectionIndex].sectionItems[sectionItemIndex];
                                const selectedChoices = selectedItem.choices;
                                if (!selectedChoices.some(choice => choice.isSelected === true)) {
                                    isInValid = true;
                                } else {
                                    sectionItem.choices.forEach((choice) => {
                                        if (choice.isSelected && choice.subQuestions?.length > 0) {
                                            choice.subQuestions.map(subQuestion => {
                                                if (subQuestion?.isRequired) {
                                                    if (subQuestion.choices.length > 0) {
                                                        if (!subQuestion.choices.some((choice) => choice.isSelected)) {
                                                            subQuestion.isInValid = true;
                                                        }
                                                    } else if (
                                                        subQuestion.answer === undefined ||
                                                        subQuestion.answer.answerText === undefined ||
                                                        subQuestion.answer.answerText?.trim().length === 0
                                                    ) {
                                                        subQuestion.isInValid = true;
                                                    }
                                                }
                                            });
                                        }
                                    });
                                }
                                break;
                            }
                            case QuestionType.MULTICHOICE: {
                                const selectedItem = updatedFormData.sections[sectionIndex].sectionItems[sectionItemIndex];
                                const selectedChoices = selectedItem.choices;
                                if (!selectedChoices.some(choice => choice.isSelected === true)) {
                                    isInValid = true;
                                }
                                break;
                            }
                        }
                        updatedFormData.sections[sectionIndex].sectionItems[sectionItemIndex].isInValid = isInValid;
                    }
                })
            );
            state.formData = updatedFormData;

            return state;
        }
    }

})


export const { setQuestionnaire, handleParagraphOnChange, handleYesNoOnChange, handleMcqOnChange, setInititalFormDataOnAutoSave, setIsInvalid } = customQuestionSlice.actions;

export default customQuestionSlice.reducer;