import * as React from "react";
import { ValidationAlertConstants } from "../../helper/Constants";
import { Alert } from "react-bootstrap";
import { ErrorIcon } from "../../assets/svg/SVGIconsCollection";

interface IValidationAlertProps {
    show: boolean;
    onClose(): void;
    validationAlertData: IValidationData[];
}
export interface IValidationData {
    sectionIndex: number;
    requiredSectionItems: number[];
}
export const intialModalData: IValidationData[] = [
    {
        sectionIndex: 0,
        requiredSectionItems: [],
    },
];

const ValidationAlert: React.FC<IValidationAlertProps> = ({
    show,
    onClose,
    validationAlertData,
}) => {
    return (
        <Alert className="validation-alert" show={show} dismissible onClose={onClose} variant="danger">
            <div className="d-flex align-items-center margin-left-10-px">
                <div className="margin-left-10-px">
                <div className="d-flex align-items-center">
                    <ErrorIcon/>
                    <span className="margin-left-10-px">{ValidationAlertConstants.ModalBodyText}</span>
                </div>
                    {validationAlertData.map((section, index) => (
                        <div key={index} className="validation-container margin-left-15-px">
                            <span><b>Section {section.sectionIndex + 1} </b></span>
                            <div className="questions-container">
                                {section.requiredSectionItems.length > 0 &&
                                    section.requiredSectionItems.map(
                                        (questions, questionIndex, questionsArray) => (
                                            <span><b key={questionIndex}>
                                                Q{questions + 1}
                                                {questionIndex < questionsArray.length - 1 && ","}
                                            </b></span>
                                        )
                                    )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Alert>
    );
};

export default ValidationAlert;
