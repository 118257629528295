import { NotificationType } from "common/enum"

export type statusUpdateModel = {
    isLoading?: boolean,
    isStatusChanged: boolean,
    notificationType: NotificationType
}

export const initialStatusUpdateModel: statusUpdateModel = {
    isLoading : false,
    isStatusChanged: false,
    notificationType: NotificationType.None
}