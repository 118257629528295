import { UploadStatus } from "../common/enum";
import { ConvertedUploadedFileModel, UploadedDocument, UploadedFile } from "../common/model/document-request-model";
import { FileUtilities, IFileUtilities } from "../core/utilities/FileUtilities";

const fileUtilities: IFileUtilities = new FileUtilities;

export function convertToUploadedFileModel(files: any, fileNamePrefix: string = ''): ConvertedUploadedFileModel {

    let convertedUploadedFileModel: ConvertedUploadedFileModel =
    {
        counterFileSizeLimitFailed: 0,
        counterUnsupportedFileExtensionFailed: 0,
        isFileSizeLimitExceeded: false,
        isUnsupportedFileExtension: false,
        uploadedFiles: []
    };

    for (let i = 0; i < files.length; i++) {

        let tmpFile = files[i];

        if (fileUtilities.isValidateSize(tmpFile, 536870912)) {

            let tmpFileName = fileNamePrefix + tmpFile.name;

            if (fileUtilities.isValidateExtension(tmpFileName, ["pdf", "jpg", "png", "jpeg", "xls", "xlsx", "doc", "docx", "qbx", "qbb", "qbm", "qbw"])) {

                convertedUploadedFileModel.uploadedFiles.push({
                    id: 0,
                    uploadedOn: new Date(),
                    name: fileUtilities.normalizeFileName(tmpFileName),
                    file: tmpFile,
                    status: UploadStatus.Wait,
                    uploadPercentage: 0,
                    documentGuid: "",
                    documentId: 0,
                    taxYear: 0
                });
            }
            else {
                convertedUploadedFileModel.isUnsupportedFileExtension = true;
                convertedUploadedFileModel.counterUnsupportedFileExtensionFailed++;
            }
        }
        else {
            convertedUploadedFileModel.isFileSizeLimitExceeded = true;
            convertedUploadedFileModel.counterFileSizeLimitFailed++;
        }
    }

    return convertedUploadedFileModel;
}

export function handleDuplicates(uploadedFiles: UploadedFile[], existingFiles: UploadedFile[]): UploadedFile[] {

    for (let i = 0; i < uploadedFiles.length; i++) {

        let file = uploadedFiles[i];
        let fileName: string = file.name || "";
        let fileExtension = fileUtilities.getExtension(fileName);

        let filecount = 1;
        while (isFileExist(fileName, existingFiles)) {
            fileName = file.name || "";
            fileName = fileName.replace("." + fileExtension, '');
            fileName = fileName + " (" + filecount + ")." + fileExtension;
            filecount++;
        }
        uploadedFiles[i].name = fileName;
    }

    return uploadedFiles;
}

export async function handleFilesWithZeroSize(uploadedFiles: UploadedFile[]): Promise<any> {

    let uploadFilesWithValidData: UploadedFile[] = [];

    for (let i = 0; i < uploadedFiles.length; i++) {
        const result = await GetFileMagicNumber(uploadedFiles[i].file);
        if (result != "") {
            uploadFilesWithValidData.push(uploadedFiles[i]);
        }
    }

    return uploadFilesWithValidData;
}

export function isFileExist(fileName: string, files: UploadedFile[]): boolean {

    let found: boolean = false;

    for (var i = 0; i < files.length; i++) {
        if (files[i].name == fileName) {
            found = true;
            break;
        }
    }
    return found;
}

export function ConvertToUploadedFileModel(files: UploadedDocument[]): UploadedFile[] {
    let uploadedFiles: UploadedFile[] = [];

    files.forEach((item: UploadedDocument, index: number) => {
        uploadedFiles.push({
            id: item.id,
            uploadedOn: item.uploadedOn,
            name: item.fileName,
            file: undefined,
            status: UploadStatus.Uploaded,
            uploadPercentage: 100,
            documentGuid: item.documentGuid,
            documentId: item.documentId,
            taxYear: item.taxYear
        });
    });

    return uploadedFiles;
}

export function GetFileMagicNumber(file: any): Promise<any> {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file.slice(0, 4));
    return new Promise((resolve) => {
        reader.onload = function () {
            if (this.readyState == reader.DONE) {
                const arrayBuffer: any = this.result;
                const uint = new Uint8Array(arrayBuffer).subarray(0, 4)
                let bytes: any = []
                uint.forEach((byte) => {
                    bytes.push(byte.toString(16))
                })
                return resolve(bytes.join('').toUpperCase());
            }
        }
    });
}
export function validateWordAndPDFFileContent(signature: any) {
    if (
        signature === '25504446' ||             //.pdf
        signature === 'D0CF11E0' ||             //.doc
        signature === '504B34' ||             //.docx
        signature === '89504E47' ||             //.png
        signature === 'FFD8FFE1' ||             //.jpg
        signature === '504B0304' ||             //.xlsx
        signature === 'D0CF11E0' ||             //.xls
        signature === 'FFD8FFE0' ||             //.jpeg
        signature === 'FFD8FFE2'                //.jpeg
    ) {
        return true;
    }
    return false;
}