import * as React from 'react';
import FileUploadProgressBar from '../../common/components/file-upload/FileUploadProgressBar';
import { ConflictErrorMessage, ConvertedUploadedFileModel, UploadedDocument, UploadedFile } from '../../common/model/document-request-model';
import { convertToUploadedFileModel, handleDuplicates, handleFilesWithZeroSize } from '../../helper/UploadDocumentHelper';
import { Toaster } from '../../common/components/Toasts';
import { GatherUploadedDocuments, UploadFileWarningMessage } from '../../helper/Constants';
import { FileUploadUtilities, IFileUploadUtilities } from '../../core/utilities/FileUploadUtilities';
import { AddUploadedFileToStorage, getFileSize, isMobile } from '../../helper/HelperFunctions';
import { FileIcon, FileUploadIcon } from '../../assets/svg/SVGIconsCollection';
import { ButtonComponent } from 'cp-common-ui-components';
import FileUpload from '../../common/components/file-upload/FileUpload';
import { StatusCodes, UploadStatus } from '../../common/enum';
import { getUploadLink as getDocumentUploadLink, addUploadedDocument as addDocument, changeSourceDocumentStatusToCompleted } from "../../store/services/sourceDocumentUpload";
import { useAppDispatch, useAppSelector } from '../../common/hooks/redux-hooks';
import { getDocumentRequestList } from '../../store/services/documentRequestList';
import { Button } from 'react-bootstrap';

export interface UploadDRLDocumentsProps {
    clientId: string;
    isCompleted: boolean;
    disabled?: boolean;
    isPreviewOrClientViewMode: boolean;
}

let totalUploadCompleted: number = 0;
let totalFiles: number = 0;
let filesUploaded: number = 0;

const fileUploadUtilities: IFileUploadUtilities = new FileUploadUtilities();

export const UploadDRLDocuments: React.FC<UploadDRLDocumentsProps> = (props) => {
    const [files, setFiles] = React.useState<any[]>([]);
    const [existingFiles, setExistingFiles] = React.useState<UploadedFile[]>([]);
    const [isFilesReadyForUpload, setIsFilesReadyForUpload] = React.useState<boolean>(false);
    const [fileUploadPercentage, setFileUploadPercentage] = React.useState<number>(0);
    const [uploadingFile, setUploadingFile] = React.useState<any>(null);
    const [isHandleAddTriggered, setIsHandleAddTrigered] = React.useState<boolean>(false);

    const dispatch = useAppDispatch();
    const { disabled, isPreviewOrClientViewMode } = props;
    const uploadedDocuments: UploadedFile[] = useAppSelector(state => state.documentRequestReducer.uploadedDocuments);

    const updateUploadedStatus = (status: UploadStatus) => { }

    const updateFileStatus = (status: UploadStatus, fileName: string) => {
        const updatedFileStatus = files.map(file => {
            if (file.name === fileName) {
                return {
                    ...file,
                    status: status
                }
            }
            else {
                return file;
            }
        });
        setFiles(updatedFileStatus);
    };

    React.useEffect(() => {
        if (uploadedDocuments) {
            setExistingFiles(uploadedDocuments);
        }
    }, [uploadedDocuments]);

    React.useEffect(() => {
        if (files?.length && isFilesReadyForUpload) {
            totalUploadCompleted = 0;
            getUploadLink(files);
        }
    }, [isFilesReadyForUpload]);

    React.useEffect(() => {
        if (files && isHandleAddTriggered) {
            handleAddFiles(files);
        }
    }, [files]);

    const handleAddFiles = (files: any) => {
        setIsHandleAddTrigered(false);
        if (!disabled) {
            let convertedUploadedFileModel: ConvertedUploadedFileModel = convertToUploadedFileModel(files);
            if (convertedUploadedFileModel.isFileSizeLimitExceeded) {
                Toaster.warn(UploadFileWarningMessage(
                    convertedUploadedFileModel.counterFileSizeLimitFailed,
                    files.length,
                    GatherUploadedDocuments.SupportedUploadFileSizeWarningMessage));
            }
            if (convertedUploadedFileModel.isUnsupportedFileExtension) {
                Toaster.warn(UploadFileWarningMessage(
                    convertedUploadedFileModel.counterUnsupportedFileExtensionFailed,
                    files.length,
                    GatherUploadedDocuments.SupportedFilesMessage));
            }
            convertedUploadedFileModel.uploadedFiles = handleDuplicates(convertedUploadedFileModel.uploadedFiles, existingFiles);

            handleFilesWithZeroSize(convertedUploadedFileModel.uploadedFiles).then((uploadFilesWithValidData) => {
                if (uploadFilesWithValidData.length != convertedUploadedFileModel.uploadedFiles.length) {
                    Toaster.warn(GatherUploadedDocuments.SupportedFilesSizeMessage);
                }
                if (uploadFilesWithValidData.length > 0) {
                    setFiles([...uploadFilesWithValidData]);
                    setIsFilesReadyForUpload(true);
                }
            });
        }
    }

    const getUploadLink = (files: UploadedFile[]) => {
        totalFiles = files.length;
        const { clientId } = props;
        setIsFilesReadyForUpload(false);
        updateUploadedStatus(UploadStatus.Uploading);
        files.filter((x: UploadedFile) => x.status == UploadStatus.Wait).forEach((file: UploadedFile, index: number) => {
            file.status = UploadStatus.Initiating;
            file.name = file.name.trim();
            dispatch(getDocumentUploadLink(clientId, file, onGetDocumentUploadLinkSuccess, () => {
                totalUploadCompleted++;
            }));
        })
    }

    const onGetDocumentUploadLinkSuccess = (data: any, file: UploadedFile) => {
        let errorFiles: number = 0;
        filesUploaded = 0;
        const uploadFiles: number = files?.filter(x => x.status == UploadStatus.Initiating).length;
        if (data?.statusCode === StatusCodes.Conflict) {
            errorFiles++;
            if (errorFiles === uploadFiles) {
                let uploadedFiles = files?.filter(x => x.status !== UploadStatus.Initiating && x.status !== UploadStatus.Wait);
                setFiles(uploadedFiles);
                dispatch(changeSourceDocumentStatusToCompleted(props.clientId));
                updateUploadedStatus(UploadStatus.Uploaded);
                totalUploadCompleted++;
            }
        }
        fileUploadUtilities.upload(file.file,
            data.sas,
            file.name ? file.name : "",
            uploadProgress,
            () => {
                filesUploaded++;
                handleAddDocument(file.name, getFileSize(file.file.size), uploadFiles, filesUploaded);
                setFileUploadPercentage(0);
            });
        totalFiles = files.length;
    }

    const uploadProgress = (progress: number, file: any) => {
        setUploadingFile(file);
        setFileUploadPercentage(progress);
    }

    const handleAddDocument = (fileName: string, fileSize: string, uploadFiles: number, fileUploaded: number) => {
        const { clientId } = props;
        let uploadedDocument: UploadedDocument = UploadedDocument.create(fileName, fileSize);
        AddUploadedFileToStorage(fileName);
        uploadFiles == fileUploaded
            ? dispatch(
                addDocument(
                    clientId,
                    uploadedDocument,
                    () => {
                        updateFileStatus(UploadStatus.Uploaded, fileName);
                        getUpdatedDocumentsList();
                        Toaster.success(GatherUploadedDocuments.Recognition);
                    },
                    () => {
                        let fileindex = files?.findIndex(f => f.name === fileName && f.status != UploadStatus.Uploaded);
                        let addedFiles: UploadedFile[] = files.filter((value, index) => index != fileindex);
                        setFiles(addedFiles);
                        getUpdatedDocumentsList();
                    }))
            : dispatch(
                addDocument(
                    clientId,
                    uploadedDocument,
                    () => {
                        updateFileStatus(UploadStatus.Uploaded, fileName);
                        getUpdatedDocumentsList();
                    },
                    getUpdatedDocumentsList));
    }

    const getUpdatedDocumentsList = () => {
        totalUploadCompleted++;
        if (totalUploadCompleted === totalFiles) {
            const { clientId } = props;
            getDocumentRequestList(clientId);
            totalFiles = 0;
            totalUploadCompleted = 0;
        }
    }
    return (<div title={isPreviewOrClientViewMode ? GatherUploadedDocuments.UploadNotAllowedMessage : props.isCompleted ? ConflictErrorMessage : ''} className='file-upload-outer-container'>
        {fileUploadPercentage > 0 ?
            <div className='file-upload-progress-container'>
                <FileIcon />
                <div className='file-upload-progress-inner-container'>
                    <label className='filename ellipsis'>{uploadingFile?.fileName}</label>
                    <label>{GatherUploadedDocuments.UploadInProgress}</label>
                    <FileUploadProgressBar isUploading percentage={fileUploadPercentage} />
                </div>
            </div> :
            <FileUpload className='drl-upload' onUpload={handleAddFiles}>
                <div className={"dz-open-file-browse"} style={disabled ? {
                    pointerEvents: "none",
                    opacity: 0.5,
                    cursor: "not-allowed"
                } : {}}>
                    <div className={"filepicker file-upload-inner-container"}>
                        <div className='margin-bottom-20-px'>
                            <FileUploadIcon />
                        </div>
                        {
                            !isMobile() ? <>
                                <div className='text-container'>
                                    <label>{GatherUploadedDocuments.ChooseFileOrDragDrop}</label>
                                    <label className='file-type-label'>{GatherUploadedDocuments.FileTypes}</label>
                                </div>
                                <ButtonComponent buttonClassName='rounded-button'>{GatherUploadedDocuments.ChooseFile}</ButtonComponent>
                            </> : <Button variant='link' className='text-sapphire margin-top-5-px text-decoration-none'>Upload Files</Button>
                        }
                    </div>
                </div>
            </FileUpload>
        }
    </div>
    );
}