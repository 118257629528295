import { ErrorStatus, OtpMode, StatusType } from "common/enum";
import { ThunkAction } from "redux-thunk";
import { AppState } from "store";
import { Action } from "@reduxjs/toolkit";
import Axios from "services/api/axios-interceptor"
import { API } from "services/api/api-config";
import { AuthResponse } from "common/model/authResponse";
import { setClientMobileNumber, setHeaderInfo, setIsLoading } from "store/slices/coverPage";
import { HeaderInfoViewModel } from "common/model/headerInfo";
import { setNotification } from "store/slices/notification";
import { OtpPageConstants } from "helper/Constants";
import { setLoader } from "store/slices/loader";


export const getAuthenticationType =(
    clientId:string,
    setOtpType : (type : OtpMode) => void,
    errorCallback? : (status : ErrorStatus) => void
) : ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
    dispatch(setLoader(true))
    try{
        Axios.get(`${API.COVER_PAGE.GET_AUTHENTICATION_TYPE}/${clientId}`,{
            method : 'GET',
            credentials : 'include'
        })
        .then((response) => response)
        .then((data)=>{
            let result : AuthResponse<OtpMode> = data;
            if(result.isError){
                errorCallback && errorCallback(result.errorStatus)
                dispatch(setLoader(false))
                return;
            }
            setOtpType(result.data);
            dispatch(setLoader(false))
        })
    }
    catch(error : any){
        dispatch(setNotification({
            type :StatusType.Error,
            message :OtpPageConstants.serverError
        }))
        dispatch(setLoader(false))
    }
}

export const requestHeaderInfo = (
    clientId:string,
    isPreview:boolean,
    callback?:(data:any)=>void,
    errorCallback?:(status:ErrorStatus)=>void): ThunkAction<void, AppState, unknown, Action> =>(dispatch)=>{
       try{
        dispatch(setIsLoading(true))
        dispatch(setLoader(true))
        Axios.get(`${API.COVER_PAGE.GET_HEADER_DETAILS}/${clientId}?isPreview=${isPreview}`,{
            method :'GET',
            credentials : 'include'
        })
        .then((response)=>response)
        .then((data)=>{
            const result:AuthResponse<HeaderInfoViewModel> = data;
            if (result.isError){
                errorCallback && errorCallback(result.errorStatus)
                dispatch(setLoader(false))
                return;
            }

            dispatch(setHeaderInfo(result.data))

            if (callback){
                callback(result.data)
            }
            dispatch(setLoader(false))
        })
       }
       catch(error : any){
        dispatch(setNotification({
            type :StatusType.Error,
            message :OtpPageConstants.serverError
        }))
        dispatch(setLoader(false))
       } 
}

export const getClientId = (
    clientId: string,
    navigate: (guid : string) => void ) : ThunkAction<void, AppState, unknown, Action>=> (dispatch)=>{
    try{
        dispatch(setLoader(true))
        Axios.get(`${API.COVER_PAGE.GET_CLIENT_ID}/${clientId}`,{
            method : 'GET',
            credentials : 'include'
        })
        .then((response)=>response)
        .then((data)=>{
            const result :string = data
            navigate(result)
            dispatch(setLoader(false))
        })
    }
    catch(error:any){
        dispatch(setNotification({
            type :StatusType.Error,
            message :OtpPageConstants.serverError
        }))
        dispatch(setLoader(false))
    }
}

export const getClientMobileNumber=(
    clientId:String
): ThunkAction<void, AppState, unknown, Action>=> (dispatch)=>{
    dispatch(setLoader(true))
    Axios.get(`${API.COVER_PAGE.GET_CLIENT_MOBILE_NUMBER}/${clientId}`,{
        method : 'GET',
        credentials : 'include'
    })
    .then((response) => response)
    .then((data)=>{
        let result : AuthResponse<string> = data;
        
        dispatch(setClientMobileNumber(result.data))
        dispatch(setLoader(false))
    })
    .catch((error:any)=>{
        dispatch(setNotification({
            type :StatusType.Error,
            message :OtpPageConstants.serverError
        }))
        dispatch(setLoader(false))
    })
}

export const requestClientInfo = (
    clientId:string,
    callback?:(res:ErrorStatus)=>void,successCallback?:()=>void):ThunkAction<void, AppState, unknown, Action>=> (dispatch)=>{
        dispatch(setLoader(true))
        Axios.get(`${API.COVER_PAGE.GET_CLIENT_INFO}/${clientId}`,{
            method : 'GET',
            credentials : 'include'
        })
        .then((response) => response)
        .then((data)=>{
            let result:AuthResponse<any> = data;

            if(result.isError){
               callback && callback(result.errorStatus)
            }
            if(!result.isError)
            {
                successCallback && successCallback()
            }
            dispatch(setLoader(false))
        })
        .catch((error:any)=>{
            dispatch(setNotification({
                type :StatusType.Error,
                message :OtpPageConstants.serverError
            }))
            dispatch(setLoader(false))
        })
    }