import { useEffect, useState } from "react";
import { PageURL } from "../../helper/Constants";
import { AppState } from "../../store";
import { GatherStepStatus } from "../enum";
import { gatherStepsWithStatus } from "../model/gatherStepsWithStatus";
import { useClientId } from "./client-hook";
import { useAppSelector } from "./redux-hooks";
import { TAXPAYER } from "route/paths";


const useNextNavigation = (isNext?: boolean) => {

    const stepsWithStatus: gatherStepsWithStatus[] = useAppSelector((state: AppState) => state.gatherStepsWithStatusReducer?.stepsWithStatus);
    const clientId = useClientId();
    const [nextURL, setNextURL] = useState<string>("");
    const [isLast,setIsLast] =useState(false)

    useEffect(() => {
        setNextNaviagtion();
    }, [stepsWithStatus]);

    const getNextPendingStepIndex =(indexOfCurrentStep:number):number =>{
        for(let i=indexOfCurrentStep+1; i<=stepsWithStatus.length;i++){
            if(i===stepsWithStatus.length){
                return -1;
            }
            else if(stepsWithStatus[i].status === GatherStepStatus.Pending){
                return i;
            }
        }
        return -1;
     }

    const setNextNaviagtion = () => {
        let indexOfCurrentStep = stepsWithStatus.findIndex((step) => step.current === true);
        if(indexOfCurrentStep === stepsWithStatus.length-1){
            setNextURL(`${TAXPAYER.LANDING_PAGE}${clientId}`)
            setIsLast(true)
        }

        const indexOfNextPendingStep = getNextPendingStepIndex(indexOfCurrentStep)
        if(indexOfNextPendingStep === -1){
            setNextURL(`${TAXPAYER.LANDING_PAGE}${clientId}`);
            setIsLast(true)
            return;
        }

        const nextStepName = stepsWithStatus[indexOfNextPendingStep]?.name??""
        const nextnavigationUrl = PageURL[nextStepName]
        setNextURL(`${nextnavigationUrl}${clientId}`);
    }

    return {nextURL,isLast};
};

export default useNextNavigation;