export type UserModel = {
    userId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    phone: string;
    extension: string;
    ptin: string;
    emailAddress: string;
    faxNumber: string;
    title: string;
    createdOn?: Date;    
    isDeceased: boolean;
}

export const initialUserModel: UserModel = {
    userId: 0,
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    extension: "",
    ptin: "",
    emailAddress: "",
    faxNumber: "",
    title: "",
    isDeceased: false
}