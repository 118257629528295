import React, { FC, useEffect, useRef } from "react";
import { ToastContainer } from "react-toastify";
import { useAppSelector } from "../../hooks/redux-hooks";
import { StatusType } from "../../enum";
import { Toaster } from "../Toasts";

const Notification: FC = () => {
    const { message, type } = useAppSelector(state => state.notificationReducer);
    const firstRender = useRef(true);
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        if (type == StatusType.Success) {
            Toaster.success(message);
        }
        else if (type == StatusType.Error) {
            Toaster.error(message);
        }
        else if (type == StatusType.Warning) {
            Toaster.warn(message);
        }
        else {
            Toaster.info(message);
        }
    }, [type, message]);

    return <ToastContainer />
};

export default Notification;