import React from 'react';

export enum CheckBoxSize {
    sm = 0,
    lg = 1
}

interface ICheckBoxComponent {
    checked?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    text: string;
    datatestAuto?: string;
    disabled?: boolean;
    value?: string | string[] | number;
    onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
    id: string;
    className?: string;
    size?: CheckBoxSize;
    indeterminate?: boolean;
    wrapperStyle?: React.CSSProperties;
}

const CheckBoxComponent: React.FC<ICheckBoxComponent> = (props) => {

    let checkBoxClass = "magic-checkbox";

    switch (props.size) {

        case CheckBoxSize.lg:
            checkBoxClass = props.indeterminate ? "magic-checkbox magic-checkbox-indeterminate" : "magic-checkbox";
            break;

        case CheckBoxSize.sm:
            checkBoxClass = props.indeterminate ? "magic-checkbox-sm magic-checkbox-sm-indeterminate" : "magic-checkbox-sm";
            break;
    }

    return (
        <div className={props.className} style={props.wrapperStyle}>
            <div className={'display-checkbox'}>
                <input
                    className={checkBoxClass}
                    type="checkbox"
                    id={props.id}
                    value={props.value}
                    checked={props.checked}
                    onChange={props.onChange}
                    data-test-auto={props.datatestAuto}
                    disabled={props.disabled}
                    onClick={props.onClick}
                />
                <label htmlFor={props.id} className={props.checked ? "checked" : ""}>
                    {props.text}
                </label>
            </div>
        </div>
    );
}

export default CheckBoxComponent;
