import React, { FC } from "react";
import { toast, ToastPosition } from "react-toastify";
import { CloseIcon, ErrorIcon, SuccessIcon, WarningIcon } from "assets/svg/SVGIconsCollection";
import "react-toastify/dist/ReactToastify.css";
import { isMobile } from "../../helper/HelperFunctions";

const options = {
  position: isMobile() ? "top-center" : ("top-right" as ToastPosition),
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  closeButton: (
    <div className="close-button">
      <CloseIcon />
    </div>
  ),
  style: { width: 400 }
};

const ToastBody: FC<{ message: string; type: string }> = ({ message, type }) => (
  <div className={`toast-wrapper ${type}`}>
    <div className="toast-icon">{toastIcon(type)}</div>
    <div>{message}</div>
  </div>
);

export class Toaster {
  static success = (message: string) => {
    return toast.success(<ToastBody message={message} type={"success"} />, { ...options });
  };
  static error = (message: string) => {
    return toast.error(<ToastBody message={message} type={"error"} />, { ...options });
  };
  static warn = (message: string) => {
    return toast.warn(<ToastBody message={message} type={"warning"} />, { ...options });
  };
  static info = (message: string) => {
    return toast.info(<ToastBody message={message} type={"info"} />, { ...options });
  };
}

const toastIcon = (type: string) => {
  switch (type) {
    case "success":
      return <SuccessIcon />;
    case "error":
      return <ErrorIcon />;
    case "warning":
      return <WarningIcon />;
    default:
      return <></>;
  }
};
