import React, { FC, useEffect, useState } from "react";
import { FooterComponent } from "cp-common-ui-components";
import Header from "../../common/components/layout/Header";
import { FormGroupStatus, HeaderType } from "../../common/enum";
import CqProgressBar from "./CqProgressBar";
import { Footer } from "./Footer";
import Section from "./Section";
import ValidationAlert, { IValidationData, intialModalData } from "./ValidationAlert";
import { IChoiceModel, IFormData, ISectionItems, ISections, QuestionType } from "../../common/model/custom-questions-model";
import { IsClientViewFromCache, IsPreviewModeFromCache, isEqual } from "../../helper/HelperFunctions";
import { Toaster } from "../../common/components/Toasts";
import { AutoSave, StepName } from "../../helper/Constants";
import { useNavigate } from "react-router-dom";
import { getCustomQuestionStatus, getFormData, onSaveForm, onFinishForm, onAutoSave } from "store/services/customQuestionStore";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { AppState } from "store";
import { setInititalFormDataOnAutoSave } from "store/slices/customQuestion";
import { useClientId } from "common/hooks/client-hook";
import { useAutoSave } from "common/hooks/auto-save";
import { useUpdateCurrentStep } from "common/hooks/step-hook";
import { TAXPAYER } from "../../route/paths";
import useNextNavigation from "../../common/hooks/useNextNavigation";
import "./index.scss";
import { getGatherMetaData } from "store/services/esign";
import { setIsInvalid } from "store/slices/customQuestion";
import { useCompleted } from "common/hooks/useCompletedHook";

const CustomQuestions: FC = () => {
    const setIsInvalidFlag = () => { };

    const [clientId] = useClientId();

    const navigate = useNavigate();

    const dispatch = useAppDispatch();


    const formData = useAppSelector((state: AppState) => state.customQuestionPageReducer?.formData)

    const initialFormData = useAppSelector((state: AppState) => state.customQuestionPageReducer?.intialFormData)

    const [showValidationAlert, setShowValidationAlert] = useState(false);
    const [validationAlertData, setValidationAlertData] = useState<IValidationData[]>(intialModalData);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [filledQuestions, setFilledQuestions] = useState(0);
    const isClientView = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isClientView);
    const isPreview = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isPreview);


    useUpdateCurrentStep(StepName.Answer)

    useEffect(() => {

        dispatch(getGatherMetaData(clientId));

        getCustomQuestionStatus(clientId)
            .then((data: FormGroupStatus) => {
                validateCustomQuestionStatus(data);
            })
            .catch((err: string) => {
                navigate(TAXPAYER.LANDING_PAGE + clientId)
            });
    }, []);

    useEffect(() => {
        const totalCount = findTotalQuestionsCount();

        if (totalCount && totalCount > 0) {
            setTotalQuestions(totalCount);
        }
        calculateProgress();
    }, [formData?.sections]);



    const validateCustomQuestionStatus = (
        customQuestionStatus: FormGroupStatus
    ) => {
        if (customQuestionStatus === FormGroupStatus.QuestionnaireCompleted) {
            navigate(TAXPAYER.LANDING_PAGE + clientId)
        } else {
            dispatch(getFormData(clientId));
        }
    };

    const nextNavigation = useNextNavigation();

    const autoSaveCallback = () => {
        Toaster.success(AutoSave.Success)
    }

    const autoSave = () => {
        if (!isEqual(formData, initialFormData)) {
            dispatch(onAutoSave(clientId, formData, autoSaveCallback))
            dispatch(setInititalFormDataOnAutoSave(formData))
        }
    }

    useAutoSave(formData, initialFormData, autoSave)

    const handleSkipAndFinishForPreview = (isSave: boolean) => {
        if (isPreview) {
            if (isSave) {
                navigate(`${TAXPAYER.LANDING_PAGE}${clientId}`);
            } else {
                navigate(nextNavigation.nextURL);
            }
            return true;
        }
        if (isClientView) {
            navigate(`${TAXPAYER.LANDING_PAGE}${clientId}`);
            return true;
        }
        return false;
    }

    useEffect(() => {
        if (validationAlertData.length !== 0) {
            navigateToFirstmandatoryQuestion()
        }
    }, [validationAlertData])

    useCompleted(StepName.Answer)



    const isFormDataValid = (data: IFormData) => {
        const output: IValidationData[] = [
            {
                sectionIndex: 0,
                requiredSectionItems: [],
            },
        ];
        dispatch(setIsInvalid(data))
        const addSectionItemToSection = (index: number, item: number) => {
            const existingItemIndex = output.findIndex(
                (outputItem) => outputItem.sectionIndex === index
            );
            if (existingItemIndex !== -1) {
                if (!output[existingItemIndex].requiredSectionItems.includes(item)) {
                    output[existingItemIndex].requiredSectionItems.push(item);
                }
            } else {
                const temp: IValidationData = {
                    sectionIndex: 0,
                    requiredSectionItems: [],
                };
                temp.sectionIndex = index;
                temp.requiredSectionItems.push(item);
                output.push(temp);
            }
        };

        const trimEmptySection = () => {
            const existingItemIndex = output.findIndex(
                (outputItem) => outputItem.requiredSectionItems.length === 0
            );
            if (existingItemIndex !== -1) {
                output.splice(existingItemIndex, 1);
            }
        };

        data?.sections?.forEach((section, sectionIndex) => {
            section.sectionItems.forEach((sectionItem, sectionItemIndex) => {
                if (sectionItem.isRequired) {
                    if (sectionItem.questionType === QuestionType.PARAGRAPH &&
                        (sectionItem.answer === undefined ||
                            sectionItem.answer.answerText === undefined ||
                            sectionItem.answer.answerText?.trim().length === 0
                        )) {
                        addSectionItemToSection(sectionIndex, sectionItemIndex);
                    }
                    else if (sectionItem.choices?.length > 0) {
                        if (!sectionItem.choices.some((choice) => choice.isSelected)) {
                            addSectionItemToSection(sectionIndex, sectionItemIndex);
                        }
                        else {
                            sectionItem.choices.forEach((choice) => {
                                if (choice.isSelected && choice.subQuestions?.length > 0) {
                                    choice.subQuestions.map(subQuestion => {
                                        if (subQuestion.isRequired) {
                                            if (subQuestion.choices.length > 0) {
                                                if (!subQuestion.choices.some((choice) => choice.isSelected)) {
                                                    addSectionItemToSection(sectionIndex, sectionItemIndex);
                                                }
                                            }
                                            else if (subQuestion.answer === undefined ||
                                                subQuestion.answer.answerText === undefined ||
                                                subQuestion.answer.answerText?.trim().length === 0
                                            ) {
                                                addSectionItemToSection(sectionIndex, sectionItemIndex);
                                            }
                                        }
                                    })
                                }
                            })
                        }
                    }
                }
            });
        });

        trimEmptySection();

        if (output.length > 0) {
            setValidationAlertData(output);
            setShowValidationAlert(true);
            return false;
        } else {
            return true;
        }
    };
    const onFinishCallback = () => {
        navigate(nextNavigation.nextURL)
    }

    const onSaveCallback = () => {
        navigate(TAXPAYER.LANDING_PAGE + clientId)
    }

    const onSaveClick = () => {
        if (!handleSkipAndFinishForPreview(true)) {
            dispatch(onSaveForm(clientId, formData, onSaveCallback));
        }
    };

    const onFinshClick = () => {
        if (!handleSkipAndFinishForPreview(false) && isFormDataValid(formData)) {
            dispatch(onFinishForm(
                clientId,
                formData,
                onFinishCallback
            ));
        }
    };

    const navigateToFirstmandatoryQuestion = () => {
        let scrollId = "";
        if (validationAlertData[0].requiredSectionItems.length !== 0) {
            scrollId = `s-${validationAlertData[0].sectionIndex}_q-${validationAlertData[0].requiredSectionItems[0]}`;
        }
        const element = document.getElementById(scrollId);
        element && element.scrollIntoView({ block: "center" });
    }

    const onCloseValidationAlert = () => {
        setShowValidationAlert(false);
        setIsInvalidFlag();
        setValidationAlertData(intialModalData);
    }

    const findTotalQuestionsCount = () => {
        let totalQuestions = 0;
        if (formData?.sections?.length > 0) {
            formData.sections.forEach((section: ISections) => {
                totalQuestions = totalQuestions + section.sectionItems?.length;
            });
            return totalQuestions;
        }
    };

    const calculateProgress = () => {
        let totalFilledQuestions = 0;
        if (formData && formData.sections?.length > 0) {
            formData.sections.forEach((section: ISections) => {
                section.sectionItems?.forEach((sectionItem: ISectionItems) => {
                    if (sectionItem.choices.length > 0) {
                        sectionItem.choices.forEach((choice: IChoiceModel) => {
                            if (choice.isSelected) {
                                if (choice.subQuestions.length > 0 && sectionItem.isRequired) {
                                    let totalInnerSubQuestion = choice.subQuestions.length;
                                    let subQuestionIsSelected = 0;
                                    choice.subQuestions.forEach(subQuestion => {
                                        if (subQuestion.choices.length > 0) {
                                            subQuestion.choices.forEach((subChoice) => {
                                                if (subChoice.isSelected) {
                                                    subQuestionIsSelected++;
                                                }
                                            })
                                        }
                                        else if (subQuestion.answer.answerText?.trim().length > 0) {
                                            subQuestionIsSelected++;
                                        }
                                        if (subQuestionIsSelected === totalInnerSubQuestion) {
                                            totalFilledQuestions++;
                                        }
                                    })
                                }
                                else {
                                    totalFilledQuestions++;
                                }
                            }
                        })
                    }
                    else if (sectionItem.answer?.answerText?.trim().length > 0) {
                        totalFilledQuestions++;
                    }
                });
            });
            setFilledQuestions(totalFilledQuestions);
        }
    };
    return <div>
        <Header type={HeaderType.WITHSTEP} hideContact={true} />
        <div className="cq-tab-wrapper">
            {formData && formData.sections?.length > 0 && (
                <div className="custom-questions-tab-template-container">
                    <div className="cq-container">
                        <div className="questionnaire-header-container">
                            <div className="title-description-container">
                                <div className="title ellipsis noborder" title={formData.title}>
                                    {formData.title}
                                </div>
                                <div className="description-container ellipsis">
                                    <div
                                        title={formData.description}
                                        className="description ellipsis noborder"
                                    >
                                        {formData.description}
                                    </div>
                                </div>
                            </div>
                            <CqProgressBar
                                progressBarSize={100}
                                strokeWidth={5}
                                filledQuestions={filledQuestions}
                                totalQuestions={totalQuestions}
                            />
                        </div>

                        <div className="questionnaire-section-container">
                            {formData &&
                                formData.sections?.length > 0 &&
                                formData.sections.map((section: ISections, sectionIndex: number) => (
                                    <Section
                                        key={sectionIndex}
                                        sectionIndex={sectionIndex}
                                        section={section}
                                        sectionsCount={formData.sections.length}
                                        calculateProgress={calculateProgress}
                                        alertComponent={<ValidationAlert
                                            show={showValidationAlert}
                                            onClose={onCloseValidationAlert}
                                            validationAlertData={validationAlertData}
                                        />}
                                    />
                                ))}
                        </div>
                    </div>
                </div>
            )}
        </div>

        <Footer showSaveBtn={true} isLast={nextNavigation.isLast} onSave={onSaveClick} onFinish={onFinshClick} />
        <FooterComponent />
    </div>
};

export default CustomQuestions;