import { CustomModal } from "common/components/UIComponents/CustomModal";
import { useClientId } from "common/hooks/client-hook";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { SourceDocumentMappingDetails, SourceDocumentMappingModel } from "common/model/sourceDocumentMappingModel";
import { ButtonConstant, SourceDocumentConstants } from "helper/Constants";
import { FC, useEffect, useState } from "react";
import { getCategoryList, getDocumentRequestList, updateDocumentType, updateNotApplicableStatus } from "store/services/documentRequestList";
import { setSourceDocumentMappingModel } from "store/slices/sourceDocumentMappingSlice";
import { IDocumentRequestList } from "./DocumentRequestList";

interface IShowModel {
    id: number,
    show: boolean
}

const ReMapDrlModal: FC = () => {
    const [showModels, setShowModels] = useState<IShowModel[]>([]);
    const [clientId] = useClientId();
    const dispatch = useAppDispatch();
    let sourceDocumentMappingModel  = useAppSelector((state) => state.sourceDocumentMappingReducer) as SourceDocumentMappingModel;
    let sourceDocumentMappingDetails = useAppSelector((state) => state.sourceDocumentMappingDetailsReducer) as SourceDocumentMappingDetails;  
    const { documentRequestList } = useAppSelector((state) => state.documentRequestReducer);  
    const [documents, setDocuments] = useState<SourceDocumentMappingDetails[]>([]);
    useEffect(() => {
        let loadShowModels: IShowModel[] = [];
        let tempDocuments = sourceDocumentMappingModel.documents.map(d => {
            return d;
        });
        setDocuments(tempDocuments.sort((d1, d2) => d2.id - d1.id));
        sourceDocumentMappingModel.documents.map(d => loadShowModels.push({
            id: d.id,
            show: true
            }));
            setShowModels(loadShowModels);           
    }, [sourceDocumentMappingModel, documentRequestList]);

    useEffect(() => {
        if (sourceDocumentMappingDetails && sourceDocumentMappingDetails.gatherdocumentRequestId != 0)
        {
            processNewSourceDocumentMapping();
        }

    }, [sourceDocumentMappingDetails]);
    
    const processNewSourceDocumentMapping = () => {
        let drlName = "";
        let notApplicableRecord = false;
        documentRequestList?.map((category: IDocumentRequestList, index: number) => {
            category.documents.map(dt => {
                if (dt.id === sourceDocumentMappingDetails.gatherdocumentRequestId)
                {
                    drlName = dt.name;
                    if (dt.notApplicable)
                    {
                        notApplicableRecord = true;
                    }                      
                }
            })
        });
        if (notApplicableRecord) 
        {            
            let details = {...sourceDocumentMappingDetails, id: sourceDocumentMappingModel.currentIdentifier + 1, drlName: drlName};
            let updatedSourceDocuments = sourceDocumentMappingModel.documents.map(d => {
                return d;
            });
            updatedSourceDocuments.push(details);
            dispatch(setSourceDocumentMappingModel({...sourceDocumentMappingModel, documents: updatedSourceDocuments, currentIdentifier: details.id}));
        }
        else 
        {
            dispatch(getDocumentRequestList(clientId));
            dispatch(getCategoryList(clientId));
        }
    };

    const handleModalClose = (document: SourceDocumentMappingDetails) => {
        let updatedShowModels = showModels.map(sm => {
            if (sm.id == document.id) {
                sm.show = false;
            }
            return sm;
        });
        let filteredDocuments = documents.filter(d => {
            if (d.gatherdocumentRequestId != document.gatherdocumentRequestId)
            {
                return d;   
            }
        });
        dispatch(setSourceDocumentMappingModel({...sourceDocumentMappingModel, documents: filteredDocuments}));        
        setShowModels(updatedShowModels); 
    };

    const handleMappingDocument = (document: SourceDocumentMappingDetails) => {
        let category = documentRequestList?.find((category: IDocumentRequestList, index: number) => 
        {
            if (category.documents.find(d => d.id == document.gatherdocumentRequestId)){
                return category;
            }
        });
        const payload = {
            documentId: document.sourceDocumentId,
            name: '',
            gatherDocumentRequestId: document.gatherdocumentRequestId,
            isOtherOptionEnabled: false,
            categoryId: category.categoryId
        };
        dispatch(updateDocumentType(clientId, payload, () => {
            dispatch(getCategoryList(clientId));
            dispatch(getDocumentRequestList(clientId));
        }));
        handleModalClose(document);
    }

    return (
    <>
        {documents.map(d => 
            <CustomModal
                className='status-update-notification-modal'
                show= {showModels.find(sd => sd.id == d.id)?.show ?? false}
                onHide={() => handleModalClose(d)}
                title= {SourceDocumentConstants.ReMapTitle}
                confirmButtonName={ButtonConstant.Remap}
                additionalActionButtonName={ButtonConstant.LeaveUncategorized}
                onAdditionalButtonClick={() => handleModalClose(d)}
                onCancelButtonClick={() => handleModalClose(d)}
                onSubmit={() => handleMappingDocument(d)}>  
                <div>
                    <p>We have identified the file <b>{d.sourceDocumentName}</b> as <b>{d.drlName}</b>
                       &nbsp;but you have mapped it as Not Applicable.<br /><br />
                       Would you like to undo the Not Applicable and remap the
                       file to <b>{d.drlName}</b>? 
                    </p>
                </div>                        
            </CustomModal>
        )}
    </>)
}

export default ReMapDrlModal;