import React from 'react';
interface FileUploadProgressBarProps {
    isUploading: boolean;
    percentage: number;
}
const FileUploadProgressBar: React.FC<FileUploadProgressBarProps> = ({ isUploading, percentage }) => {
    const update = () => {
        if (isUploading) {
            let elem = document.getElementById("myBar");
            if (elem) {
                elem.style.width = percentage + '%';
            }
        }
    }
    update();
    return <div className="file-upload-progress-bar-track">
        <div id="myBar" className="file-upload-progress-bar-thumb">
        </div>
    </div>
}
export default FileUploadProgressBar;