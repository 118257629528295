import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SourceDocumentMappingModel, initialSourceDocumentMappingModel } from "common/model/sourceDocumentMappingModel";

const sourceDocumentMappingSlice = createSlice({
    name :'sourceDocumentMapping',
    initialState : initialSourceDocumentMappingModel,
    reducers :{
        setIsLoading :(state,action:PayloadAction<boolean>)=>{
            state.loading = action.payload
        },
        setSourceDocumentMappingModel: (state, action:PayloadAction<SourceDocumentMappingModel>)=>{
            state.currentIdentifier = action.payload.currentIdentifier;
            state.documents = action.payload.documents;
            state.loading = false;
        }
    }
})

export const {setIsLoading, setSourceDocumentMappingModel} = sourceDocumentMappingSlice.actions;

export default sourceDocumentMappingSlice.reducer;