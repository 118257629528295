import React, { FC } from "react";
import { DownloadIcon, TickMarkIcon, ViewIcon } from "../../assets/svg/SVGIconsCollection";
import { GatherStepStatus, Action } from "common/enum";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { AppState } from "store";
import { gatherStepsWithStatus } from "common/model/gatherStepsWithStatus";
import {
  downloadCustomQuestionnaireDocument,
  downloadEngagementLetter,
  downloadOrganizerDocument
} from "../../store/services/landingPageStore";
import { useClientId } from "common/hooks/client-hook";
import { StepFullName, StepName } from "helper/Constants";
import { useNavigate } from "react-router-dom";
import { TAXPAYER } from "route/paths";
import { Toaster } from "common/components/Toasts";

const VerticalSteps: FC = () => {
  const [clientId] = useClientId();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isDone = (status: GatherStepStatus) => status === GatherStepStatus.Done;
  const stepsWithStatus: [gatherStepsWithStatus] = useAppSelector(
    (state: AppState) => state.gatherStepsWithStatusReducer?.stepsWithStatus
  );
  const isClientView = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isClientView);
  const isStepLoading: boolean = useAppSelector(
    (state: AppState) => state.gatherStepsWithStatusReducer?.isLoading
  );

  const viewDocument = (name: string) => {
    switch (name) {
      case StepName.Upload:
        navigate(TAXPAYER.UPLOAD_DOCUMENTS + `${clientId}`);
        break;
      default:
        break;
    }
  };

  const noDownloadShowForAnswerIfCompleteStepDone = (stepsWithStatus: [gatherStepsWithStatus]) : boolean => {
    let completeStep = stepsWithStatus.find((step) => step.name === StepName.Complete);
    return completeStep?.status !== GatherStepStatus.Done;
  }

  const downloadDocument = (name: string) => {
    if(isClientView){
      Toaster.warn("Document is not available for download in clientView");
      return;
    }
    switch (name) {
      case StepName.Sign:
        dispatch(downloadEngagementLetter(clientId));
        break;
      case StepName.Complete:
        dispatch(downloadOrganizerDocument(clientId));
        break;
      case StepName.Answer:
        dispatch(downloadCustomQuestionnaireDocument(clientId));
        break;
      default:
        break;
    }
  };

  const getActionButton = (action: Action, name: string) => {
    switch (action) {
      case Action.Download:
        return (
          <button className="action-button" onClick={() => downloadDocument(name)}>
            <DownloadIcon className="margin-right-5-px" /> Download
          </button>
        );
      case Action.View:
        return (
          <button className="action-button" onClick={() => viewDocument(name)}>
            <ViewIcon className="margin-right-5-px" />
            View
          </button>
        );
      default:
        return <></>;
    }
  };
  return (
    <div className={`vertical-steps-container ${!isStepLoading ? "visible" : "invisible"}`}>
      <div className="scroll-wrapper">
        {stepsWithStatus?.map((step: gatherStepsWithStatus, i: number) => {
          return (
            <>
              <div className={`step ${isDone(step.status) ? "done" : ""}`}>
                <div className={"count"}>{step.status === GatherStepStatus.Pending ? i + 1 : <TickMarkIcon color="#000" />}</div>
                <div className="caption-container">
                  <div className="caption">{StepFullName[step.name]}</div>
                  {(isDone(step.status)) && (step.name === StepName.Answer ? noDownloadShowForAnswerIfCompleteStepDone(stepsWithStatus) ? getActionButton(step.action, step.name) : "" : getActionButton(step.action, step.name))}
                </div>
              </div>
              {i !== stepsWithStatus.length - 1 && <div className="vertical-line"></div>}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default VerticalSteps;
