import Axios from "services/api/axios-interceptor"
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { API } from "services/api/api-config";
import { AppState } from "store";
import { setIsLoading, setPreviewDocumentData, setSourceDocumentStatus } from "store/slices/source-document-upload-slice";
import { setNotification } from "store/slices/notification";
import { StatusType } from "common/enum";
import { UploadedFile, UploadedDocument, UploadedDocumentStatus, IPreviewFileData } from "common/model/document-request-model";
import { AuthResponse } from "common/model/authResponse";
import { ClientResponse } from "common/model/clientResponse";
import { ErrorMessages } from "helper/Constants";
import { getCategoryList, getDocumentRequestList } from "./documentRequestList";


export const getUploadLink = (
    clientId: string,
    file: UploadedFile,
    successCallback: (data: any, file: UploadedFile) => void,
    errorCallback?: () => void
): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        try {
            dispatch(setIsLoading(true))

            Axios.get(`${API.SOURCE_DOCUMENT.GET_SOURCE_DOCUMENT_UPLOAD_LINK}/${encodeURIComponent(clientId)}?fileName=${file.name}`, {
                method: 'GET',
                credentials: 'include'
            })
                .then((response) => response)
                .then((data) => {
                    const result: ClientResponse<any> = data

                    if (result.data) {
                        dispatch(setIsLoading(false))
                        successCallback && successCallback(result.data, file);
                    }
                    else {
                        errorCallback && errorCallback();
                        return;
                    }
                })
        }
        catch (error: any) {
            dispatch(setNotification({
                type: StatusType.Error,
                message: ErrorMessages.UploadDocumentError
            }))
            errorCallback && errorCallback();
        }
    }

export const addUploadedDocument = (
    clientId: string,
    uploadedDocument: UploadedDocument,
    successCallback?: () => void,
    failureCallback?: () => void)
    : ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
        try {
            dispatch(setIsLoading(true))
            let apiEndPoint = `${API.SOURCE_DOCUMENT.ADD_SOURCE_DOCUMENT}/${clientId}`;

            Axios.post(apiEndPoint, uploadedDocument, {
                method: 'POST',
                credentials: 'include'
            })
                .then((response) => response)
                .then((data) => {
                    let result: AuthResponse<any> = data
                    if (result) {
                        dispatch(setIsLoading(false))
                        successCallback && successCallback();
                        dispatch(getDocumentRequestList(clientId))
                        dispatch(getCategoryList(clientId))
                    }
                    else {
                        failureCallback && failureCallback();
                        return;
                    }
                })
        }
        catch (error: any) {
            dispatch(setNotification({
                type: StatusType.Error,
                message: ErrorMessages.UploadDocumentError
            }))
        }
    }

export const deleteUploadedDocument = (
    clientId: string,
    id: number,
    successCallback?: () => void,
    failureCallback?: (errorDescription: string) => void
): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
    try {
        dispatch(setIsLoading(true))
        let apiEndPoint = `${API.SOURCE_DOCUMENT.DELETE_SOURCE_DOCUMENT}/${clientId}?sourceDocumentId=${id}`;

        Axios.delete(apiEndPoint)
            .then((response) => response)
            .then((data) => {
                const result: ClientResponse<any> = data
                if (!result?.isSuccess) {
                    failureCallback && failureCallback(result.errorDescription);
                    return;
                }
                else {
                    dispatch(setIsLoading(false))
                    successCallback && successCallback();
                }
            })
    }
    catch (error: any) {
        dispatch(setNotification({
            type: StatusType.Error,
            message: ErrorMessages.UploadDocumentError
        }))
    }
}

export const continueLater = (
    clientId: string,
    successCallback?: () => void,
    failureCallback?: () => void
): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
    try {
        dispatch(setIsLoading(true))
        let apiEndPoint = `${API.SOURCE_DOCUMENT.CONTINUE_LATER}/${clientId}`;

        Axios.post(apiEndPoint, {})
            .then((response) => response)
            .then((data) => {
                const result: AuthResponse<any> = data
                dispatch(setIsLoading(false))
                successCallback && successCallback();
            }).catch(() => {
                failureCallback && failureCallback();
            })
    }
    catch (error: any) {
        dispatch(setNotification({
            type: StatusType.Error,
            message: ErrorMessages.UploadDocumentError
        }))
    }
}

export const changeSourceDocumentStatusToCompleted = (
    clientId: string,
    successCallback?: (description: string) => void,
    failureCallback?: (errorDescription: string) => void
): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
    try {
        dispatch(setIsLoading(true))
        let apiEndPoint = `${API.SOURCE_DOCUMENT.MARK_SOURCE_DOCUMENT_STATUS_COMPLETED}/${clientId}`;

        Axios.post(apiEndPoint, {})
            .then((response) => response)
            .then((data) => {
                const result: ClientResponse<any> = data
                if (result?.isSuccess) {
                    dispatch(setIsLoading(false))
                    successCallback && successCallback(result?.errorDescription);
                }
                else {
                    failureCallback && failureCallback(result?.errorDescription);
                    return;
                }
            })
    }
    catch (error: any) {
        dispatch(setNotification({
            type: StatusType.Error,
            message: ErrorMessages.UploadDocumentError
        }))
    }
}

export const requestUploadedDocumentStatus = (
    clientId: string,
    successCallback?: () => void,
    failureCallback?: () => void
): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
    try {
        dispatch(setIsLoading(true));
        let apiEndPoint = `${API.SOURCE_DOCUMENT.GET_SOURCE_DOCUMENT_STATUS}/${clientId}`;

        Axios.get(apiEndPoint)
            .then((response) => response)
            .then((data) => {
                const result: UploadedDocumentStatus = data;
                if (result) {
                    dispatch(setIsLoading(false));
                    dispatch(setSourceDocumentStatus(result));
                    successCallback && successCallback();
                }
                else {
                    failureCallback && failureCallback();
                    return;
                }
                dispatch(setIsLoading(false));
            })
    }
    catch (error: any) {
        dispatch(setIsLoading(false));
        dispatch(setNotification({
            type: StatusType.Error,
            message: ErrorMessages.UploadDocumentError
        }))
    }
}

export const getSourceDocumentPreview = (
    clientId: string,
    sourceDocumentId: number,
    successCallback?: () => void,
    failureCallback?: () => void
): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
    try {
        dispatch(setIsLoading(true))
        let apiEndPoint = `${API.SOURCE_DOCUMENT.GET_SOURCE_DOCUMENT_PREVIEW}/${clientId}?sourceDocumentId=${sourceDocumentId}`;

        Axios.get(apiEndPoint)
            .then((response) => response)
            .then((data) => {
                const result: AuthResponse<IPreviewFileData> = data;
                if (data) {
                    dispatch(setIsLoading(false))
                    dispatch(setPreviewDocumentData(data));
                    successCallback && successCallback();
                }
                else {
                    failureCallback && failureCallback();
                    return;
                }
            })
    }
    catch (error: any) {
        dispatch(setNotification({
            type: StatusType.Error,
            message: ErrorMessages.UploadDocumentError
        }))
    }
}
