import React, { FC, useEffect, useState } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { DownArrowIcon } from "../../../assets/icons/IconCollection";
import { CustomModal } from "../UIComponents/CustomModal";
import { MyAccountConstants, UserProfileConstant } from "../../../helper/Constants";
import MyAccountForm from "./MyAccountForm";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { ClientModel, initialClientModel } from "common/model/clients";
import { saveClientDetails } from "store/services/clients";
import { useClientId } from "common/hooks/client-hook";
import { AppState } from "store";
import { ValidateEmailAddress, ValidateMobileNumber, getCurrentClient, getOtherClient } from "helper/HelperFunctions";
import { Toaster } from "../Toasts";
import { logOut } from "store/services/otpStore";
import { useNavigate } from "react-router-dom";
import { getGatherMetaData } from "store/services/esign";
import { GatherMetadata } from "common/model/organizer";
import { getOneHubRedirectUrl } from "store/services/companySettings";


interface IUserDropdownProps {
    isUteEnabled: boolean
}

const UserDropdown: FC<IUserDropdownProps> = ({ isUteEnabled }) => {
    const [myAccountVisible, setMyAccountVisible] = useState<boolean>(false);


    const [gatherMetadata, setGatherMetadata] = useState<GatherMetadata>(GatherMetadata?.createNullObject());

    useEffect(() => {
        dispatch(getGatherMetaData(clientId, (metaData: GatherMetadata) => {
            setGatherMetadata(metaData);
        }));
    }, [])

    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    const [clientId] = useClientId();

    const taxpayer: ClientModel = useAppSelector((state: AppState) => state.clientReducer?.taxpayer)

    const spouse: ClientModel = useAppSelector((state: AppState) => state.clientReducer?.spouse)

    const [currentClient, setCurrentClient] = useState(initialClientModel);

    const [otherClient, setOtherClient] = useState(initialClientModel);

    const isClientView = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isClientView);
    const isPreview = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isPreview);
    const isAccessedFromOneHub = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isAccessedFromOneHub);

    const saveDetils = (currentClient: ClientModel, otherClient: ClientModel) => {
        if (gatherMetadata?.isClientView || gatherMetadata?.isPreview) {
            Toaster.warn(MyAccountConstants.ClientViewUpdateWarn)
            return;
        }

        if (!ValidateMobileNumber(currentClient.mobileNumber, currentClient.countryCode)) {
            Toaster.warn(MyAccountConstants.NumberValidation);
            return;
        }
        if (otherClient.clientGuid && !ValidateMobileNumber(otherClient.mobileNumber, otherClient.countryCode)) {
            Toaster.warn(MyAccountConstants.NumberValidation);
            return;
        }
        if (otherClient.clientGuid && !ValidateEmailAddress(otherClient?.emailAddress)) {
            Toaster.warn(MyAccountConstants.EmailValidation);
            return;
        }

        let showSuccess = true;
        if (taxpayer.current) {
            const successCallback = () => { showSuccess = false };
            if (currentClient.mobileNumber !== taxpayer.mobileNumber || currentClient.countryCode !== taxpayer.countryCode) {
                dispatch(saveClientDetails(clientId, currentClient, successCallback))
            }
            if (otherClient.mobileNumber !== spouse.mobileNumber || otherClient.emailAddress !== spouse.emailAddress || otherClient.countryCode !== spouse.countryCode) {
                dispatch(saveClientDetails(clientId, otherClient, successCallback))
            }
            else if (spouse.current) {
                if (currentClient.mobileNumber !== spouse.mobileNumber || currentClient.countryCode !== spouse.countryCode) {
                    dispatch(saveClientDetails(clientId, currentClient, successCallback))
                }
                if (otherClient.mobileNumber !== taxpayer.mobileNumber || otherClient.emailAddress !== taxpayer.emailAddress || otherClient.countryCode !== taxpayer.countryCode) {
                    dispatch(saveClientDetails(clientId, otherClient, successCallback))
                }
            }
            showSuccess && Toaster.success(UserProfileConstant.SaveSuccessMessage);
        }
        setMyAccountVisible(false)
    }
    const handleLogout = () => {
        if (isClientView || isPreview) {
            Toaster.warn("Action unavailable in Preview or Client view.")
            return;
        }
        dispatch(logOut(clientId, logOutCallback))
    }

    const logOutCallback = (encodedClientId: string) => {
        navigate("/" + encodedClientId)
    }

    const handleOneHubNavigation = () => {
        if (!isClientView && !isPreview) {
            dispatch(getOneHubRedirectUrl(clientId, (url: string) => {
                window.location.href = url;
            }))
        } else {
            Toaster.warn(MyAccountConstants.OneHubWarning)
        }
    };

    useEffect(() => {
        setCurrentClient(getCurrentClient(taxpayer, spouse))
        setOtherClient(getOtherClient(taxpayer, spouse))
    }, [taxpayer, spouse])

    const popover = (
        <Popover id="popover-user-dropdown">
            <Popover.Body>
                <div className="margin-bottom-10-px user-name ellipsis" title={currentClient?.name}><b>{MyAccountConstants.Greeting}, {currentClient.name}!</b></div>
                <div className={`cursor-pointer ${isUteEnabled ? "margin-bottom-5-px" : ""}`} onClick={() => setMyAccountVisible(true)}>{MyAccountConstants.Profile}</div>
                {isUteEnabled && !isAccessedFromOneHub && <div className="cursor-pointer" onClick={handleOneHubNavigation}>{MyAccountConstants.OneHub}</div>}
                <hr />
                <div className="cursor-pointer" onClick={() => { handleLogout() }}><b>{MyAccountConstants.LogOut}</b></div>
            </Popover.Body>
        </Popover>
    );

    return <>
        <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
            <div className="user-dropdown">
                <span className="margin-right-5-px ellipsis" title={currentClient?.name}>{currentClient.name}</span>
                <DownArrowIcon />
            </div>
        </OverlayTrigger>
        <CustomModal
            className="my-account-modal"
            size="lg" title="My Account"
            show={myAccountVisible}
            onHide={() => setMyAccountVisible(false)}
            onSubmit={() => { saveDetils(currentClient, otherClient) }}
            closeVariant="white"
            confirmButtonName="Save">
            <MyAccountForm
                otherClient={otherClient}
                currentClient={currentClient}
                setOtherClient={setOtherClient}
                setCurrentClient={setCurrentClient}
            />
        </CustomModal>
    </>;
};

export default UserDropdown;