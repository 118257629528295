
export type SourceDocumentMappingDetails  = {
    id: number,
    sourceDocumentId: number,
    gatherdocumentRequestId: number,
    sourceDocumentName: string,
    drlName: string
}

export type SourceDocumentMappingModel = {
   documents:SourceDocumentMappingDetails[],
   currentIdentifier:number,
   loading: boolean
}

export const initialSourceDocumentMappingModel: SourceDocumentMappingModel = {
    documents: [],
    currentIdentifier: 0,
    loading: false
}

export const initialSourceDocumentMappingDetails: SourceDocumentMappingDetails = {
    id: 0,
    gatherdocumentRequestId: 0,
    sourceDocumentId: 0,
    sourceDocumentName: '',
    drlName: ''
}