import React from "react";
import { AuthenticationLayoutConstants } from "helper/Constants";
import { useAppSelector } from "common/hooks/redux-hooks";
import { AppState } from "store";
import ContactPopup from "./ContactPopup";
import { CalenderIcon } from "assets/icons/IconCollection";
import "../styles/AuthenticationLayout.scss";
import { isMobile } from "helper/HelperFunctions";


interface AuthenticationLayoutProps {
  children: React.ReactNode;
};

export const AuthenticationLayout: React.FC<AuthenticationLayoutProps> = ({ children }) => {

  const icon = useAppSelector((state: AppState) => state.coverPageReducer?.companyLogoPath)
  const contactPerson = useAppSelector((state: AppState) => state.coverPageReducer?.contactPerson)

  return <div className="auth-layout">
    <div className="auth-layout-left-section">
      <main className="left-container">
        <div className="auth-layout-company-logo-container">
            <img src={icon} alt="company-logo" />
        </div>
        {children}
        <div className="otp-auth-contact-container">
          <p className="ssn-auth-contact-text margin-right-5-px">{AuthenticationLayoutConstants.needHelpText}</p>
          <ContactPopup
            placement="bottom"
            fromCoverPage={true}
            name={contactPerson?.firstName + " " + contactPerson?.lastName}
            mobileNo={contactPerson?.phone}
            emailAddress={contactPerson?.emailAddress}
          />
        </div>
        {!isMobile() ? <footer>
          <a href={AuthenticationLayoutConstants.termsLink} rel="noreferrer" target="_blank" >{AuthenticationLayoutConstants.termsOfService}</a>
          <span> | </span>
          <a href={AuthenticationLayoutConstants.privacyLink} rel="noreferrer" target="_blank" >{AuthenticationLayoutConstants.privacyPolicy}</a>
        </footer> : <></>}
      </main>
    </div>
    <div className="auth-layout-right-section">
      <CalenderIcon />
    </div>
  </div>;
};