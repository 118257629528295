import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ESignStoreState } from "common/model/esign";

const initialESignState: ESignStoreState = {
    loading: false,
}

const esignPageSlice = createSlice({
    name: 'esignPage',
    initialState: initialESignState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
    }
})

export const { setIsLoading } = esignPageSlice.actions;

export default esignPageSlice.reducer;