import { OtpPageConstants, ButtonConstant } from "helper/Constants";
import React, { useEffect } from "react";
import OtpInputControl from "common/components/UIComponents/OtpInputControl";
import { AuthenticationLayout } from "common/components/layout/AuthenticationLayout";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { getAuthenticationType, getClientMobileNumber, requestClientInfo, requestHeaderInfo } from "store/services/coverPage";
import { ErrorStatus, OtpMode } from "common/enum";
import { useNavigate } from "react-router-dom";
import { generateOTP, validateLink, verifyOTP } from "store/services/otpStore";
import { Toaster } from "common/components/Toasts";
import { AppState } from "store";
import { hideMobileNumber } from "helper/HelperFunctions";
import { LINK_LOCKED, TAXPAYER } from "../../route/paths";
import { useClientId } from "common/hooks/client-hook";
import "./index.scss";
import { ERROR_PAGE } from "route/paths";
import { ButtonComponent } from "cp-common-ui-components";

const { SendCode, Confirm } = ButtonConstant;
const {
  title,
  subtitle,
  helperText,
  helperTextMFA,
  otpExpiredText, 
  otpExpiresText, 
  otpHelperText, 
  otpHelperTextMFA, 
  otpIncorrectText, 
  otpJunkText, 
  otpLength,
  otpToasterText,
  resendCode
} = OtpPageConstants;

const OtpAuthPage: React.FC = () => {
  const clientMobileNumber = useAppSelector((state: AppState) => state.coverPageReducer?.clientMobileNumber);

  const [showOtpControl, setShowOtpControl] = React.useState<boolean>(false);
  const [otp, setOtp] = React.useState<string>("");
  const [isOtpInvalid, setIsOtpInvalid] = React.useState<boolean>(false);
  const [enableConfirmButton, setEnableConfirmButton] = React.useState<boolean>(false);
  const [otpErrorText, setOtpErrorText] = React.useState<string>("");
  const [isMobile, setIsMobile] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [encodedClientId] = useClientId();

  useEffect(() => {
    dispatch(
      validateLink(encodedClientId, () => {
        navigate(TAXPAYER.LINK_LOCKED + encodedClientId);
      })
    );
    dispatch(requestClientInfo(encodedClientId, invalidCallBack));
    dispatch(requestHeaderInfo(encodedClientId, false));
    dispatch(getAuthenticationType(encodedClientId, setOtpType));
    dispatch(getClientMobileNumber(encodedClientId));
  }, []);

  const setOtpType = (otpType: OtpMode) => {
    if (otpType === OtpMode.Email) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  const handleOtpChange = (otp: string) => {
    if (otp === "") {
      setIsOtpInvalid(true);
    }
    if (otp.length === 8) {
      setEnableConfirmButton(true);
    } else {
      setEnableConfirmButton(false);
    }
    setOtp(otp);
  };

  const invalidCallBack = (errorStatus: ErrorStatus) => {
    if (errorStatus === ErrorStatus.ClosedByFirm) {
      navigate(TAXPAYER.CLOSED + encodedClientId);
    } else if (errorStatus === ErrorStatus.OrganizerDeleted) {
      navigate(ERROR_PAGE + encodedClientId);
    }
  };

  const handleSendCode = () => {
    setShowOtpControl(true);
    dispatch(generateOTP(encodedClientId, isMobile, otpGenerated));
  };

  const otpGenerated = () => {
    Toaster.success(otpToasterText)
  }

  const handleInValidOTP = () => {
    setIsOtpInvalid(true);
  };

  const handleOtpSubmit = () => {
    dispatch(verifyOTP(otp, encodedClientId, isMobile, verificationSuccessfull, verificationFailed, handleInValidOTP));
  };

  const verificationSuccessfull = (clientId: string) => {
    navigate(`${TAXPAYER.LANDING_PAGE}${clientId}`);
    localStorage.setItem("encodedClientId_" + clientId, encodedClientId);
  };

  const verificationFailed = (errorCode: string, retryLeft: number) => {
    if(retryLeft!==0){
      setOtpErrorText(otpIncorrectText.replace("<n>", retryLeft.toLocaleString()));
    }
    if (errorCode === "OTP_LOCKED") {
      localStorage.setItem("lockedTime", Date.now().toString());
      navigate(LINK_LOCKED + encodedClientId);
    } else if (errorCode === "OTP_EXPIRED") {
      Toaster.error(otpExpiredText);
    }
  };

  return (
    <AuthenticationLayout>
      <div className="otp-auth-page" style={{ paddingTop: !showOtpControl ? "50px" : "20px" }}>
        <h2 className="title-h2">{title}</h2>
        {!showOtpControl && <h4 className="title-h4">{subtitle}</h4>}
        {!showOtpControl && <p className="otp-auth-helper-text">{isMobile ? helperTextMFA + hideMobileNumber(clientMobileNumber) : helperText}</p>}
        {showOtpControl && <div>
          <p className="otp-auth-helper-text-when-controls-shown">
            {isMobile ? otpHelperTextMFA + hideMobileNumber(clientMobileNumber) : otpHelperText}
            <span className="otp-auth-expires-text">{otpExpiresText}</span>
          </p>
          <OtpInputControl
            otp={otp}
            setOtp={handleOtpChange}
            otpLength={otpLength}
            isInvalid={isOtpInvalid}
            errorText={otpErrorText}
          />
        </div>}

        {showOtpControl &&
          <div>
            <p className="otp-auth-junk-text">{otpJunkText}</p>
            <button className="otp-auth-resend-btn" onClick={handleSendCode}>
              {resendCode}
            </button>
          </div>
        }
        
        <ButtonComponent
          disabled={!enableConfirmButton && showOtpControl}
          buttonClassName="otp-auth-btn"
          onClick={!showOtpControl ? handleSendCode : handleOtpSubmit}>{!showOtpControl ? SendCode : Confirm}</ButtonComponent>

      </div>
    </AuthenticationLayout>
  );
};

export default OtpAuthPage;
