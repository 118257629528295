import { ErrorStatus, StatusType } from "common/enum";
import { ThunkAction } from "redux-thunk";
import { AppState } from "store";
import { Action } from "@reduxjs/toolkit";
import Axios from "services/api/axios-interceptor"
import { API } from "services/api/api-config";
import { setPreparerMessage, setIsLoading as setPMSIsLoading} from "store/slices/preparerMessageSlice";
import { setGatherStepsWithStatus, setIsLoading as setGSWIsLoading } from "store/slices/gatherStepsWithStatusSlice";
import { prepareMessageModel } from "common/model/preparerMessage";
import { setNotification } from "store/slices/notification";
import { OtpPageConstants } from "helper/Constants";
import { gatherStepsWithStatus } from "common/model/gatherStepsWithStatus";
import { showFile } from "../../helper/DownloadDocumentHelper";
import { setLoader } from "store/slices/loader";

export const requestPreparerMessage = (
    clientId:string,
    callback?:(data:any)=>void,
    errorCallback?:(status:ErrorStatus)=>void): ThunkAction<void, AppState, unknown, Action> =>(dispatch)=>{
        dispatch(setLoader(true))
        
        Axios.get(`${API.LANDING_PAGE.GET_PREPARER_MESSAGE}/${clientId}`,{
            method :'GET'
        })
        .then((response)=>response)
        .then((data)=>{
            const result:prepareMessageModel = data;

            dispatch(setPreparerMessage(result))

            if (callback){
                callback(result)
            }
            dispatch(setLoader(false))
        })
        .catch((error : any) => {
            dispatch(setNotification({
                type :StatusType.Error,
                message :OtpPageConstants.serverError
            }))
            dispatch(setLoader(false))
       }) 
}

export const requestGatherStepsWithStatus = (
    clientId:string,
    callback?:(data:any)=>void,
    errorCallback?:(status:ErrorStatus)=>void): ThunkAction<void, AppState, unknown, Action> =>(dispatch)=>{     
        dispatch(setLoader(true))
        dispatch(setGSWIsLoading(true))
        Axios.get(`${API.LANDING_PAGE.GET_STEPS_WITH_STATUS}/${clientId}`,{
            method :'GET'
        })
        .then((response)=>response)
        .then((data)=>{
            const result:gatherStepsWithStatus[] = data;

            dispatch(setGatherStepsWithStatus(result))

            if (callback){
                callback(result)
            }
            dispatch(setLoader(false))
            dispatch(setGSWIsLoading(false))
        })
        .catch((error : any) => {
            dispatch(setNotification({
                type :StatusType.Error,
                message :OtpPageConstants.serverError
            }))
            dispatch(setLoader(false))
        }) 

       
}

export const downloadEngagementLetter = (
    clientId:string): ThunkAction<void, AppState, unknown, Action>=>(dispatch)=>{   
        dispatch(setLoader(true))
        Axios.get(`${API.LANDING_PAGE.GET_ENGAGEMENT_LETTER_DOWNLOAD}/${clientId}`,{
            method :'GET'
        }, true,'blob')
        .then((response)=>{
            let fileName: string = "";
            const contentDisposition = response.headers['content-disposition'];
            const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
            if (fileNameMatch && fileNameMatch.length > 1) {
                fileName = fileNameMatch[1];
            }
            showFile(response.data, fileName);
            dispatch(setLoader(false))
        })
        .catch((error : any) => {
            setNotification({
                type :StatusType.Error,
                message :OtpPageConstants.serverError
            })
            dispatch(setLoader(false))
       }) 
}

export const downloadOrganizerDocument = (clientId:string): ThunkAction<void, AppState, unknown, Action>=>(dispatch)=>{
        dispatch(setLoader(true))     
        Axios.get(`${API.LANDING_PAGE.GET_ORGANIZER_DOCUMENT_DOWNLOAD}/${clientId}`,{
            method :'GET'
        },true,'blob')
        .then((response)=>{
            let fileName: string = "";
            const contentDisposition = response.headers['content-disposition'];
            const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
            if (fileNameMatch && fileNameMatch.length > 1) {
                fileName = fileNameMatch[1];
            }
            showFile(response.data, fileName);
            dispatch(setLoader(false))  
        })
        .catch((error : any) => {
            setNotification({
                type :StatusType.Error,
                message :OtpPageConstants.serverError
            })
            dispatch(setLoader(false))  
       }) 
}

export const downloadCustomQuestionnaireDocument = (clientId:string): ThunkAction<void, AppState, unknown, Action>=>(dispatch)=>{ 
        dispatch(setLoader(true))  
        Axios.get(`${API.LANDING_PAGE.GET_CUSTOM_QUESTIONNAIRE_DOCUMENT_DOWNLOAD}/${clientId}`,{
            method :'GET'
        },true,'blob')
        .then((response)=>{
            let fileName: string = "";
            const contentDisposition = response.headers['content-disposition'];
            const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
            if (fileNameMatch && fileNameMatch.length > 1) {
                fileName = fileNameMatch[1];
            }
            showFile(response.data, fileName);
            dispatch(setLoader(false)) 
        })
        .catch((error : any) => {
            setNotification({
                type :StatusType.Error,
                message :OtpPageConstants.serverError
            })
            dispatch(setLoader(false)) 
       }) 
}