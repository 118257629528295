import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HeaderInfoViewModel, initialHeaderInfoViewModel } from "common/model/headerInfo";

const coverPageSlice = createSlice({
    name: 'coverPage',
    initialState: initialHeaderInfoViewModel,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setHeaderInfo(state, action: PayloadAction<HeaderInfoViewModel>) {
            state.clientName = action.payload.clientName;
            state.taxYear = action.payload.taxYear;
            state.companyLogoPath = action.payload.companyLogoPath;
            state.companyName = action.payload.companyName;
            state.contactPerson = action.payload.contactPerson;
            state.companyWhiteLogoPath = action.payload.companyWhiteLogoPath;
            state.companyId = action.payload.companyId;
            state.clientEmailAddress = action.payload.clientEmailAddress;
            state.isTestCompany = action.payload.isTestCompany;
            state.isLoading = false;
        },
        setClientMobileNumber(state, action: PayloadAction<string>) {
            state.clientMobileNumber = action.payload
        }
    }
})

export const { setIsLoading, setHeaderInfo, setClientMobileNumber } = coverPageSlice.actions;

export default coverPageSlice.reducer;