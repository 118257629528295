import { ExclaimationIcon } from "assets/svg/SVGIconsCollection";
import React, { ChangeEvent, FC, useRef } from "react";

interface IOtpInputControlProps {
  otp: string;
  setOtp: (otp: string) => void;
  isInvalid?: boolean;
  errorText?: string;
  otpLength?: number;
}

const OtpInputControl: FC<IOtpInputControlProps> = ({ otp, setOtp, isInvalid, errorText, otpLength }) => {
  const inputRef = useRef<HTMLInputElement[]>(Array(otpLength).fill(null));

  const handleOtpChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = (e.target as HTMLInputElement).value;
    if (/^\d+$/.test(value) || "") {
      const otpArray = otp.split("");
      otpArray[index] = value;
      setOtp(otpArray.join(""));

      if (value.length === 1 && index < (otpLength ?? 8) - 1) {
        inputRef.current[index + 1]?.focus();
      }

      if (value.length === 0 && index > 0) {
        inputRef.current[index - 1]?.focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text").trim();

    // Ensure that the pasted data is numeric and has exactly 8 digits
    if (/^\d{8}$/.test(pastedData)) {
      const otpArray = pastedData.split("");

      otpArray.forEach((char, index) => {
        if (inputRef.current[index]) {
          inputRef.current[index]!.value = char;
        }
      });

      setOtp(pastedData);
      inputRef.current[otpArray.length - 1]?.focus();
    }
  };



  const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace") {
      inputRef.current[index - 1]?.focus();
      const otpArray = otp.split("");
      otpArray[index] = "";
      setOtp(otpArray.join(""));
    }
  };
  return (
    <div className={`otp-input-control-wrapper${isInvalid ? " error" : ""}`}>
      <div className="position-relative">
        {Array.from({ length: otpLength ?? 8 }).map((_, index) => (
          <input
            key={index}
            className="otp-input"
            maxLength={1}
            value={otp.charAt(index)}
            onChange={(e) => handleOtpChange(e, index)}
            ref={(ref) => (inputRef.current[index] = ref as HTMLInputElement)}
            onPaste={handlePaste}
            onKeyDown={(e) => handleKeydown(e, index)}
          />
        ))}
        {isInvalid && (
          <div className="info-icon">
            <ExclaimationIcon color="#CC4A43" />
          </div>
        )}
      </div>
      {errorText && <span className="invalid-feedback">{errorText}</span>}
    </div>
  );
};

export default OtpInputControl;
