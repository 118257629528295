import { ClientModel, initialClientModel } from "common/model/clients";
import { PreviewConstants, SourceDocumentConstants } from "./Constants";

export function phoneNumberDisplay(phoneNumber: string): string {
    return phoneNumber && phoneNumber.length == 10 ? "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3, 6) + "-" + phoneNumber.substring(6, 10) : phoneNumber;
};

export function IsClientViewFromCache() {
    const value = sessionStorage.getItem(PreviewConstants.IsClientView);
    return (value == null) ? false : StringToBoolean(value);
}

export function IsPreviewModeFromCache() {
    const value = sessionStorage.getItem(PreviewConstants.IsPreviewMode);
    return (value == null) ? false : StringToBoolean(value);
}

export function StringToBoolean(value?: string): boolean {
    if (!value) {
        return false
    }

    switch (value.toLocaleLowerCase()) {
        case 'true':
        case '1':
            return true
        default:
            return false
    }
}

export function getFileSize(fileSizeInBytes: number) {
    let file = parseInt((fileSizeInBytes / 1024).toString(), 10);
    let fileSize = "";
    fileSize = file < 1024 ? (file + " KB").toString() : (parseInt((file / 1024).toString(), 10) + " MB").toString()
    return fileSize;
}


export function AddUploadedFileToStorage(filename: string) {
    let existingList = sessionStorage.getItem(SourceDocumentConstants.Added);
    if (existingList) {
        existingList += ":" + filename;
        sessionStorage.setItem(SourceDocumentConstants.Added, existingList);
    }
    else {
        sessionStorage.setItem(SourceDocumentConstants.Added, filename);
    }
}

export const hideMobileNumber = (mobileNumber: string) => {
    const lastFourNumber = mobileNumber.substring(6, 10)
    return `{xxx}xxx-${lastFourNumber} `
}

export const isMobile = (): boolean => {
    const navigatorCheck = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const widthCheck = window.matchMedia("(max-width: 600px)").matches;
    const portrait = window.matchMedia("(orientation: portrait)").matches;
    return (navigatorCheck && widthCheck && portrait) || (navigatorCheck && !widthCheck && !portrait);
}


export const isJointFilling = (taxpayer: ClientModel, spouse: ClientModel) => {

    if (!isEqual(taxpayer, initialClientModel) && !isEqual(spouse, initialClientModel)) {
        return true;
    }
    return false;
}

export const getOtherClient = (taxPayer: ClientModel, spouse: ClientModel) => {
    if (!taxPayer.current) {
        return taxPayer
    }
    else if (!spouse.current) {
        return spouse
    }
    return initialClientModel
}

export const getCurrentClient = (taxPayer: ClientModel, spouse: ClientModel) => {
    if (taxPayer.current) {
        return taxPayer
    }
    else if (spouse.current) {
        return spouse
    }
    return initialClientModel
}

export const ValidateMobileNumber = (mobileNumber: string, countryCode: string) => {
    if (mobileNumber?.length !== 0) {
        const pattern = new RegExp(`^${countryCode}\\d{10}$`);
        const isValidMobileNumber = pattern.test(countryCode + mobileNumber);
        return isValidMobileNumber;
    }
    return true;
}

export const ValidateEmailAddress = (email: string) => {
    if (email?.length !== 0) {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = pattern.test(email);
        return isValidEmail;
    }
}

export const hasTokenCookie = () => {
    const token = document.cookie.split(';').find(row => row.includes('_Token'));
    return !!token;
};

export const getClientId = () => {
    const clientId = document.cookie.split(';').find(row => row.includes('_Token'));
    return clientId?.split('_Token=')[0];
}

export const isEqual = (obj1: any, obj2: any) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        if (!obj2.hasOwnProperty(key)) {
            return false;
        }
        if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
            if (!isEqual(obj1[key], obj2[key])) {
                return false;
            }
        } else {
            if (obj1[key] !== obj2[key]) {
                return false;
            }
        }
    }
    return true;
}