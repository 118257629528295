import React, { useEffect, useState } from "react";
import { LinkLockIcon } from "assets/icons/IconCollection";
import { LinkLockPageConstants, LockOutTime, ButtonConstant } from "helper/Constants";
import Header from "../../common/components/layout/Header";
import { HeaderType } from "common/enum";
import { useAppDispatch } from "common/hooks/redux-hooks";
import { requestHeaderInfo } from "store/services/coverPage";
import { useNavigate } from "react-router-dom";
import { useClientId } from "common/hooks/client-hook";
import { FooterComponent } from "cp-common-ui-components";
import { isMobile } from "helper/HelperFunctions";
import { validateLink } from "store/services/otpStore";
import "./index.scss";

const { GoHome } = ButtonConstant;
const { lockTextAfterMinutes, lockTextFirstLine, lockTextSecondLine, minutesText } = LinkLockPageConstants;

export const LinkLockPage: React.FC = () => {

  const dispatch = useAppDispatch();
  const [clientId] =useClientId();
  const [disabled,setDisabled] = useState(true);
  const navigate =useNavigate();

  useEffect(() => {
    dispatch(requestHeaderInfo(clientId, false));

    dispatch(validateLink(clientId,undefined,(validLink:boolean)=>{
      if(validLink){
        localStorage.removeItem('lockedTime');
        navigate('/'+clientId)
      }
    }))

    const lockPageTimeString = localStorage.getItem('lockedTime');

    if (lockPageTimeString) {

      const lockedtimestamp = parseInt(lockPageTimeString, 10);
      const intervalId = setInterval(() => {        
          const elapsedTime = Date.now() - lockedtimestamp;
          if (elapsedTime > LockOutTime) {
          localStorage.removeItem('lockedTime');
          setDisabled(false)
        }
      }, 1000)
      return () => clearInterval(intervalId);
    }
    else{
      navigate('/'+clientId)
    }
  }, []);

  const goToHome = ()=>{
    navigate('/'+clientId)
  }
  
  return (
    <div className="link-lock-page-continer">
      <Header type={HeaderType.BLUE} fromLinkLock={true}/>
      <div className="link-lock-page">
        <LinkLockIcon />
        <div className="link-lock-text-container">
          <div>
            <span className="link-lock-text">{lockTextFirstLine}</span>
          </div>
          <div className="second-sentence-container">
          <span className="link-lock-text">{lockTextSecondLine}</span>
            <span className="link-lock-text highlight">{minutesText}</span>
            <span className="link-lock-text">{lockTextAfterMinutes}</span>
          </div>
        </div>

        <button 
            className="go-home-button ss-btn-primary "
            onClick={goToHome}
            disabled={disabled}
            >
              {GoHome}
            </button>
      </div>

      {!isMobile() && <FooterComponent />}
    </div>
  );
};