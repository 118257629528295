export type BrandingSettings = {
    layoutSetting: ThemeSettings,
    coverPageSetting: ThemeSettings,
}

type ThemeSettings = {
    bgColorCode:string,
    foreColorCode:string,
}

export const initialThemeSettings: ThemeSettings = {
    bgColorCode: "",
    foreColorCode:"",
}

export const initialBrandingSettings: BrandingSettings = {
    layoutSetting: initialThemeSettings,
    coverPageSetting: initialThemeSettings,
}