
import { UploadMethod, IGatherClientDetail } from "./organizer";
import { ClientType, Control, ControlValue, FormGroup, FormGroupStatus, GroupType, SignatureStatus } from "common/enum";

export interface ESignStoreState {
    loading: boolean;
}

export interface IESignModel {
    gatherId: number;
    uniqueId: string;
    uploadMethod: UploadMethod;
    clients: IGatherClientDetail[];
    formGroups: FormGroup[];
    gatherDocumentSetting: any;
    engagementLetterUrl: string;
    organizerSignPageUrl: string;
    additionalESignDocuments: any[];
}

export enum ESignDocumentControlState {
    None = 0,
    NoControles = 1,
    RequiredControlExists = 2,
    RequiredControlNotExists = 3,
}

export interface IFormData {
}

export class EngagementLetterFormData implements IFormData {
    controlList: Control[];

    constructor(controlList: Control[]) {
        this.controlList = controlList;
    }

    public static createNullObject(controlList: Control[]): EngagementLetterFormData {
        return new EngagementLetterFormData(controlList);
    }
}

export class ValueFormData implements IFormData {
    controlValues: ControlValue[];

    constructor(controlValues: ControlValue[]) {
        this.controlValues = controlValues;
    }

    public static createNullObject(): ValueFormData {
        return new ValueFormData([]);
    }
}
