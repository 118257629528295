import { FC } from "react";
import { ButtonComponent } from "cp-common-ui-components";
import { useAppDispatch } from "../../common/hooks/redux-hooks";
import { continueLater } from "../../store/services/sourceDocumentUpload";
import { useClientId } from "../../common/hooks/client-hook";
import { useNavigate } from "react-router-dom";
import { TAXPAYER } from "../../route/paths";
import { Button } from "react-bootstrap";

interface IDrlFooterProps {
  onFinishClick: () => void;
  isCompleted: boolean;
  isPreview: boolean;
  isDocumentListEmptySD: boolean;
}

const DrlFooter: FC<IDrlFooterProps> = ({ onFinishClick, isCompleted, isPreview, isDocumentListEmptySD }) => {
  const dispatch = useAppDispatch();
  const [clientId] = useClientId();
  const navigate = useNavigate();

  const handleContinueLater = () => {
    if (isPreview) {
      handleClose();
    } else {
      dispatch(continueLater(clientId, () => navigate(TAXPAYER.LANDING_PAGE + `${clientId}`)));
    }
  };

  const handleClose = () => {
    navigate(TAXPAYER.LANDING_PAGE + `${clientId}`);
  };

  return (
    <div className="drl-footer">
      {isCompleted ? (
        <ButtonComponent onClick={handleClose}>Close</ButtonComponent>
      ) : (
        <>
          <ButtonComponent variant="link" buttonClassName="btn-save-and-close" onClick={handleContinueLater}>
            Save & Close
          </ButtonComponent>
          <ButtonComponent buttonClassName="green-button button-medium" onClick={onFinishClick} disabled={isPreview || isDocumentListEmptySD} >
            Finish
          </ButtonComponent>
        </>
      )}
    </div>
  );
};

export default DrlFooter;
