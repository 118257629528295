import { BrowserRouter } from 'react-router-dom';
import AppRoutes from 'route/routes';
import ErrorBoundary from 'common/components/ErrorBoundary';
import Notification from './common/components/UIComponents/Notification';
import './App.scss';
import 'styles/index.scss';

function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <AppRoutes />
        <Notification />
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
