import { Action } from "redux"
import { ThunkAction } from "redux-thunk"
import { setIsLoading } from "store/slices/esign"
import { AppState } from "store"
import { API } from "services/api/api-config"
import Axios from "services/api/axios-interceptor"
import { setNotification } from "store/slices/notification";
import { FormGroupStatus, GroupType, StatusType } from "common/enum"
import { IESignModel } from "common/model/esign"
import { GatherMetadata, IGatherClientDetail, UploadMethod } from "common/model/organizer"
import { AuthResponse } from "common/model/authResponse"
import { ClientModel } from "common/model/clients"
import { setLoader } from "store/slices/loader"
import { setGatherMetadata } from "store/slices/gather-metadata-slice"

export const getESignModelData = (
    clientId: string,
    successCallback: (data: any) => void,
    errorCallback?: () => void
): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        try {
            dispatch(setIsLoading(true))
            Axios.get(`${API.E_SIGN.GET_ESIGN_DOCUMENT}/${clientId}`)
                .then((response) => response)
                .then((data: IESignModel) => {
                    const result: IESignModel = data
                    dispatch(setIsLoading(false))
                    if (result) {
                        successCallback && successCallback(result)
                    } else {
                        errorCallback && errorCallback()
                    }
                })

        } catch (e) {
            dispatch(setNotification({
                type: StatusType.Error,
                message: "Error while fetching E-sign data"
            }))
            errorCallback && errorCallback();
        }
    }

export const getBatchEngagementLtterControlsData = (clientId: string, successCallback: (data: any) => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        try {
            dispatch(setIsLoading(true))
            Axios.get(`${API.E_SIGN.GET_ESIGN_BATCH_ENGAGEMENTLETTER_CONTROLSLINK}/${clientId}`)
                .then((response) => response)
                .then((data: string) => {
                    const result: string = data
                    if (result) {
                        getFormControlsJsonFromUrl(result).then((formControlsJson) => {
                            successCallback && successCallback(formControlsJson)
                        });
                    }
                })

        } catch (e) {
            dispatch(setNotification({
                type: StatusType.Error,
                message: "Error while fetching E-sign data"
            }))
        }
    }

export const getESignStatus = (clientId: string, callback: (data: FormGroupStatus) => void)
    : ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            Axios.get(`${API.E_SIGN.GET_ESIGN_STATUS}/${clientId}`)
                .then((response) => response)
                .then((data: FormGroupStatus) => {
                    callback && callback(data);
                })
        }
        catch (e) {
            dispatch(setNotification({
                type: StatusType.Error,
                message: "Error while fetching E-sign Status"
            }));
        }
    }

export const getGatherMetaData = (clientId: string, callback?: (data: GatherMetadata) => void)
    : ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            Axios.get(`${API.ORGANIZER.GET_ORGANIZER_META_DATA}/${clientId}`)
                .then((response) => response)
                .then((data: GatherMetadata) => {
                    data && dispatch(setGatherMetadata(data));
                    callback && callback(data)
                })
        }
        catch (e) {
            dispatch(setNotification({
                type: StatusType.Error,
                message: "Error while fetching gather meta data"
            }));
        }
    }

export const getSoupseInfo = (clientId: string, callback: (data: ClientModel) => void)
    : ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            Axios.get(`${API.GATHER_CLIENT.GET_SPOUSE_INFO}/${clientId}`)
                .then((response) => response)
                .then((data: ClientModel) => {
                    callback && callback(data)
                })
        }
        catch (e) {
            dispatch(setNotification({
                type: StatusType.Error,
                message: "Error while fetching spouse"
            }));
        }
    }

export const submitSignedDocument = (clientId: string, dataModel: IESignModel, callback: () => void)
    : ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
        try {
            const endpoint = dataModel.uploadMethod === UploadMethod.Proforma ? `${API.E_SIGN.POST_ESIGN_SIGN}/${clientId}` : `${API.E_SIGN.POST_ESIGN_BATCHSIGN}/${clientId}`;
            var json = {
                formGroups: dataModel.formGroups,
                eSignDocuments: dataModel.additionalESignDocuments
            }
            
            dispatch(setLoader(true));
            Axios.post(endpoint, json).then((response) => response).then((data) => {
                const result: AuthResponse<any> = data
                if (result) {
                    dispatch(setLoader(false));
                    callback && callback()
                }
            });
        }
        catch (e) {
            dispatch(setLoader(false));
            dispatch(setNotification({
                type: StatusType.Error,
                message: "Error while submiting documents"
            }));
        }
    }

export const skipEsign = (clientId: string, dataModel: IESignModel, callback: () => void)
    : ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
        try {
            const endpoint = dataModel.uploadMethod === UploadMethod.Proforma ? `${API.E_SIGN.POST_ESIGN_SKIP}/${clientId}` : `${API.E_SIGN.POST_ESIGN_BATCHSKIP}/${clientId}`;
            const json = dataModel.formGroups == undefined ? [{ forms: [], type: GroupType.Engagement }] : dataModel.formGroups;
            dispatch(setLoader(true));
            Axios.post(endpoint, json)
                .then((response) => response)
                .then((data) => {
                    const result: AuthResponse<any> = data
                    if (result) {
                        dispatch(setLoader(false));
                        callback && callback()
                    }
                })
        }
        catch (e) {
            dispatch(setLoader(false));
            dispatch(setNotification({
                type: StatusType.Error,
                message: "Error while submiting documents"
            }));
        }
    }

export const getFormControlsJsonFromUrl = (url: string): Promise<any> => {
    return new Promise(function (resolve, reject) {

        fetch(url).
            then(data => data.json())
            .then(function (data) {
                resolve(data);

            }).catch(function (error) {
                reject(error);
            });
    });
}

export const downloadFile =
    (documentUrl: string, filename: string): ThunkAction<void, AppState, unknown, Action> =>
        () => {
            fetch(documentUrl)
                .then((response) => response.blob())
                .then((blobresp) => {

                    var blob = new Blob([blobresp], { type: "octet/stream" });
                    var url = window.URL.createObjectURL(blob);

                    var a = document.createElement("a");
                    a.href = url;

                    // For iOS, open in a new window instead of directly downloading
                    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
                    if (iOS) {
                        window.open(url);
                    } else {
                        // For other browsers, trigger a click to download
                        a.download = filename;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }

                    // Clean up the Blob URL
                    window.URL.revokeObjectURL(url);
                });
        };