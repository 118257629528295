import React from "react";
interface CqProgressBarProps {
	progressBarSize: number;
	strokeWidth: number;
	filledQuestions: number;
	totalQuestions: number;
}

const CqProgressBar: React.FC<CqProgressBarProps> = ({
	progressBarSize,
	strokeWidth,
	filledQuestions,
	totalQuestions,
}) => {
	const radius = progressBarSize / 2 - strokeWidth;
	const circumference = 2 * Math.PI * radius;

	const getProgressPercentage = () => {
		let percentage = 0;
		if (totalQuestions !== 0) {
			percentage = Math.round((filledQuestions / totalQuestions) * 100);
			if (percentage < 1 && percentage > 0) {
				return 1;
			} else if (percentage > 99 && percentage < 100) {
				return 99;
			}
		}
		return percentage;
	};
	
	return (
		<div className="progress-bar-wrapper">
			<div className="number-wrapper">
				<span>
					{getProgressPercentage()}
					<sup>%</sup>
				</span>
				<span>
					{filledQuestions}/{totalQuestions}
				</span>
			</div>
			<svg>
				<circle
					className="progress-bar-circle"
					strokeDasharray={circumference}
					strokeDashoffset={`${
						circumference - (circumference * getProgressPercentage()) / 100
					}px`}
					cx={progressBarSize / 2}
					cy={progressBarSize / 2}
					r={radius}
				></circle>
			</svg>
		</div>
	);
};

export default CqProgressBar;
