import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SourceDocumentMappingDetails, initialSourceDocumentMappingDetails } from "common/model/sourceDocumentMappingModel";

const sourceDocumentMappingDetailsSlice = createSlice({
    name :'sourceDocumentMappingDetails',
    initialState : initialSourceDocumentMappingDetails,
    reducers :{
        setSourceDocumentMappingDetails: (state, action:PayloadAction<SourceDocumentMappingDetails>)=>{
            state.drlName = action.payload.drlName;
            state.gatherdocumentRequestId = action.payload.gatherdocumentRequestId;
            state.id = action.payload.id;
            state.sourceDocumentName = action.payload.sourceDocumentName;
            state.sourceDocumentId = action.payload.sourceDocumentId;
        }
    }
})

export const { setSourceDocumentMappingDetails} = sourceDocumentMappingDetailsSlice.actions;

export default sourceDocumentMappingDetailsSlice.reducer;