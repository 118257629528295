import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import { initialPreparerMessageModel, prepareMessageModel } from "common/model/preparerMessage";

const preparerMessageSlice = createSlice({
    name : 'preparerMessage',
    initialState : initialPreparerMessageModel,
    reducers:{
        setIsLoading(state, action: PayloadAction<boolean>){
            state.isLoading = action.payload;
        },
        setPreparerMessage(state, action: PayloadAction<prepareMessageModel>){
            state.message = action.payload.message;
            state.isLoading =false;
        },
    }
})

export const { setIsLoading, setPreparerMessage } = preparerMessageSlice.actions;
export default preparerMessageSlice.reducer;