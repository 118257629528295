import { StatusType } from "common/enum";
import { AuthResponse } from "common/model/authResponse";
import { ClientModel } from "common/model/clients";
import { error } from "console";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { API } from "services/api/api-config";
import Axios from "services/api/axios-interceptor";
import { AppState } from "store";
import { setCurrentClient, setSpouseInfo, setTaxpayerInfo } from "store/slices/clients";
import { setModalLoader } from "store/slices/loader";
import { setNotification } from "store/slices/notification";
import { setIsLoading } from "store/slices/organizer";

export const getSpouseInfo = (
  clientId: string,
  successCallback?: (data: any) => void,
  errorCallback?: () => void
): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
      try {
        dispatch(setModalLoader(true))
        Axios.get(`${API.GATHER_CLIENT.GET_SPOUSE_INFO}/${clientId}`)
              .then((response) => response)
              .then((data) => {
                  const result: ClientModel = data

                  if (result) {
                      dispatch(setIsLoading(false))
                      successCallback && successCallback(result);
                      dispatch(setSpouseInfo(result))
                      dispatch(setCurrentClient(clientId))
                      dispatch(setModalLoader(false))
                  }
                  else {
                      errorCallback && errorCallback();
                      dispatch(setModalLoader(false))
                      return;
                  }
              })
      }
      catch (error: any) {
          dispatch(setNotification({
              type: StatusType.Error,
              message: "Error while fetching Taxpayer's Spouse Info."
          }))
          errorCallback && errorCallback();
      }
  }

  export const hideWarning = (
    clientId: string,
    successCallback: (data: any) => void,
    errorCallback?: () => void
  ): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        try {
            dispatch(setIsLoading(true))
            Axios.post(`${API.GATHER_CLIENT.HIDE_SPOUSE_INFO_WARNING}/${clientId}`,{})
                .then((response) => response)
                .then((data) => {
                    const result: AuthResponse<any> = data
  
                    if (result) {
                        dispatch(setIsLoading(false))
                        successCallback && successCallback(result);
                    }
                    else {
                        errorCallback && errorCallback();
                        return;
                    }
                })
        }
        catch (error: any) {
            dispatch(setNotification({
                type: StatusType.Error,
                message: "Error while hiding Spouse Info Warning."
            }))
            errorCallback && errorCallback();
        }
    }


export const getTaxPayer =
(clientId:string): ThunkAction<void, AppState, unknown, Action>  => 
    (dispatch)=>{
        dispatch(setModalLoader(true))
        Axios.get(`${API.GATHER_CLIENT.GET_TAXPAYER_INFO}/${clientId}`)
            .then((response)=>response)
            .then((data)=>{

                const result:ClientModel =data
                dispatch(setTaxpayerInfo(result))
                dispatch(setCurrentClient(clientId))
                dispatch(setModalLoader(false))
            })
            .catch((error:any)=>{
                dispatch(setNotification({
                    type:StatusType.Error,
                    message:error.message
                }))
                dispatch(setModalLoader(false))
            })
    }

export const saveClientDetails =
    (
        clientId: string,
        clientData: ClientModel,
        successCallback?: () => void
    ): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch)=>{
        Axios.post(`${API.GATHER_CLIENT.SAVE_MOBILE_EMAIL}/${clientId}`,clientData)
        .then((response)=>response)
        .then((data)=>{
            const result:boolean = data;
            if(result){
                successCallback && successCallback();
            }
        })
        .catch((error:any)=>{
            dispatch(setNotification({
                type:StatusType.Error,
                message:error.message
            }))
        })
    }  
