// Route Paths

export const DEFAULT = '/';
export const INVALID_LINK = '/invalid-link';
export const LINK_LOCKED = '/lock/';
export const ERROR_PAGE = '/error/';

export const TAXPAYER = {
    LANDING_PAGE: '/landing-page/',
    CUSTOM_QUESTIONS: '/custom-questions/',
    E_SIGN: '/esign/',
    ORGANIZER: '/organizer/',
    UPLOAD_DOCUMENTS: '/upload-documents/',
    CONTACT_INFORMATION: '/contact-information/',
    MY_ACCOUNT: '/my-account/',
    LINK_LOCKED:'/lock/',
    CLOSED:'/closed/',
    Preview:'/preview/',
    ClientView:'/client-view/'
}