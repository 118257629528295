import React, { useEffect } from "react";
import ContactContent from "../../common/components/layout/ContactContent";
import MobileHeader from "../../common/components/layout/MobileHeader";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { AppState } from "store";
import { requestHeaderInfo } from "store/services/coverPage";
import { useClientId } from "common/hooks/client-hook";

const ContactInformation = () => {

    const dispatch = useAppDispatch()

    const [clientId] =useClientId()

    useEffect(()=>{
        dispatch(requestHeaderInfo(clientId,false))
    },[])

    const contactPerson =useAppSelector((state:AppState)=>state.coverPageReducer?.contactPerson)

    return <div>
        <MobileHeader />
        <ContactContent name={contactPerson.firstName+" "+contactPerson.lastName} mobileNo={contactPerson.phone} emailAddress={contactPerson.emailAddress} isMobile />
    </div>
};

export default ContactInformation;