import React, { FC } from "react";

interface IDrawerProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode;
    className?: string;
}
const Drawer: FC<IDrawerProps> = ({ show, setShow, children, className }) => {
    return (
        <>
            <div className={`drawer ${show ? "open" : ""} ${className}`}>
                <div className="drawer-body">
                    {children}
                </div>
            </div>
            <div className={`drawer-backdrop ${show ? "show" : ""}`} onClick={() => setShow(false)} />
        </>
    );
}

export default Drawer;