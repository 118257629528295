import { SignatureMode } from "awesome-pdf-viewer/dist/Controls/ControlBase";
import { SignatureControlRole } from "./common";
import { ChoosableControlData, ClientType } from "common/enum";

export enum ControlType {
    Textbox = 1,
    Checkbox = 2,
    Date = 3,
    Dropdown = 4,
    Grid = 5,
    Signature = 6,
    RadioButton = 7,
    RadioButtonGroup = 8,
    Question = 9,
    Section = 10,
    SignatureCheckBoxControl = 11,
    SignatureRadioButtonControl = 12
}


export enum TextPlacement {
    Left = 1,
    Right = 2,
    Center = 3
}

export enum DataType {
    None = 0,
    AlphaNumeric = 1,
    Numeric = 2,
    SSN = 3,
    TextArea = 4,
    Label = 5,
    Amount = 6
}

export class BoundingRectangle {

    left: number;
    top: number;
    width: number;
    height: number;

    constructor(left: number, top: number, width: number, height: number) {
        this.left = left;
        this.top = left;
        this.width = left;
        this.height = left;
    }
}


export interface IControl {
    id: string;
    top?: number;
    left?: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean
    controlType?: number
}



export class Control implements IControl {

    id: string;
    controlType: ControlType;
    name: string;
    readonly: boolean;
    tooltip: string;
    required: boolean;
    boundingRectangle: BoundingRectangle;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;


    constructor(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean    ) {


        this.id = id;
        this.controlType = controlType;
        this.name = name;
        this.readonly = readonly;
        this.tooltip = tooltip;
        this.required = required;
        this.boundingRectangle = boundingRectangle;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;

    }

}

export interface ISignatureControl extends IControl {
}

export interface ITextBoxControl extends IControl {
    readOnly: boolean;
}

export interface ILabelControl extends IControl {
}

export interface IInitialControl extends ITextBoxControl {
}

export interface ITitleControl extends ITextBoxControl {
}

export interface ICompanyControl extends ITextBoxControl {
}

export interface ICustomTextControl extends ITextBoxControl {
}

export interface IDateControl extends ILabelControl {
}

export interface IChoosableControlResult extends IControlData {
    checked: boolean;
    id: string;
}

export interface ICustomData {
    role?: SignatureControlRole;
}

export interface IControlData {
}

export interface ISignatureData extends IControlData {
    name: string;
    signature: string;
    signatureMode: SignatureMode;
}

export interface ITextData extends IControlData {
    text: string;
}

export class ChoosableControlResult implements IChoosableControlResult {
    id: string;
    checked: boolean;

    constructor(id: string,
        checked: boolean
    ) {
        this.id = id;
        this.checked = checked;
    }

    public static create(
        id: string,
        checked: boolean
    ) {

        var data = new ChoosableControlResult(id, checked);
        return data;
    }
}


export class TextboxData implements IControlData {
    value: string;
    filledBy: ClientType;

    constructor(value: string) {
        this.value = value;
        this.filledBy = ClientType.Undefined;
    }
}

export class SignatureControl implements ISignatureControl {

    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ISignatureControl {

        return new SignatureControl(
            id,
            top,
            left,
            tooltip,
            required,
            customData,
            data,
            disabled);

    }
}

export class InitialControl implements ITextBoxControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ITextBoxControl {

        return new InitialControl(
            id,
            top,
            left,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled);

    }
}

export class TitleControl implements ITextBoxControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ITextBoxControl {

        return new TitleControl(
            id,
            top,
            left,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled);
    }
}

export class CompanyControl implements ITextBoxControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ITextBoxControl {

        return new CompanyControl(
            id,
            top,
            left,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled);
    }
}

export class CustomTextControl implements ITextBoxControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ITextBoxControl {

        return new CustomTextControl(
            id,
            top,
            left,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled);
    }
}

export class DateControl implements ILabelControl {

    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;

    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ILabelControl {

        return new DateControl(
            id,
            top,
            left,
            tooltip,
            required,
            customData,
            data,
            disabled);
    }

}

export class SignatureData implements ISignatureData {

    name: string;
    signature: string;
    signatureMode: SignatureMode

    constructor(name: string,
        signature: string,
        signatureMode: SignatureMode) {

        this.name = name;
        this.signature = signature;
        this.signatureMode = signatureMode
    }

    static create(name: string,
        signature: string,
        signatureMode: SignatureMode): ISignatureData {
        return new SignatureData(name, signature, signatureMode);
    }
}

export class TextData implements ITextData {

    text: string;

    constructor(text: string) {
        this.text = text;
    }

    static create(text: string): ITextData {
        return new TextData(text);
    }

}

export interface ISignerInfo extends ICustomData {
    email: string;
}

export interface IControlRole extends ICustomData {
    role: SignatureControlRole;
}

export class SignerInfo implements ISignerInfo {
    email: string;

    constructor(email: string) {
        this.email = email;
    }

    static create(email: string): ISignerInfo {
        return new SignerInfo(email);
    }

}

export class ControlRole implements IControlRole {
    role: SignatureControlRole;

    constructor(role: SignatureControlRole) {
        this.role = role;
    }

    static create(role: SignatureControlRole): IControlRole {
        return new ControlRole(role);
    }

}

export class CheckBoxControl extends Control {

    constructor(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        items: ChoosableControlData[],
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        super(id,
            controlType,
            name,
            readonly,
            tooltip,
            required,
            boundingRectangle,
            customData,
            data,
            disabled);

        this.items = items;
    }
   
    items: ChoosableControlData[];

    static create(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        items: ChoosableControlData[],
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): CheckBoxControl {

        return new CheckBoxControl(id,
            controlType,
            name,
            readonly,
            tooltip,
            required,
            items,
            boundingRectangle,
            customData,
            data,
            disabled);
    }
}

export class RadioButtonControl extends Control {

    constructor(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        items: ChoosableControlData[],
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        super(id,
            controlType,
            name,
            readonly,
            tooltip,
            required,
            boundingRectangle,
            customData,
            data,
            disabled);

        this.items = items;
    }

    items: ChoosableControlData[];

    static create(id: string,
        controlType: ControlType,
        name: string,
        readonly: boolean,
        tooltip: string,
        required: boolean,
        items: ChoosableControlData[],
        boundingRectangle: BoundingRectangle,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): CheckBoxControl {

        return new CheckBoxControl(id,
            controlType,
            name,
            readonly,
            tooltip,
            required,
            items,
            boundingRectangle,
            customData,
            data,
            disabled);
    }
}

export class ControlValue {

    formGuid: string;
    controlGuid: string;
    value: string;
    filledBy: ClientType;

    constructor(
        formGuid: string,
        controlGuid: string,
        value: string,clientType?: ClientType) {

        this.formGuid = formGuid;
        this.controlGuid = controlGuid;
        this.value = value;
        this.filledBy = clientType ? clientType : ClientType.Undefined;

    }

    public static create(formGuid: string,
        controlGuid: string,
        value: string,
        clientType?: ClientType): ControlValue {

        return new ControlValue(formGuid, controlGuid, value,clientType);
    }

}