import React, { FC } from "react";

interface IContactContentProps {
    name: string;
    mobileNo: string;
    emailAddress: string;
    isMobile?: boolean;
}
const ContactContent: FC<IContactContentProps> = ({ name, mobileNo, emailAddress, isMobile }) => {
    return <div className={isMobile ? "padding-25-px margin-left-right-15-px" : ""}>
        {isMobile && <div className="margin-bottom-15-px title-h3">Contact Information</div>}
        <div className="margin-bottom-5-px ellipsis" title={name}><b>{name}</b></div>
        <div className="margin-bottom-5-px">{mobileNo}</div>
        <div className="text-sapphire ellipsis email-address" title={emailAddress}><a href={`mailto:${emailAddress}`}>{emailAddress}</a></div>
    </div>
}

export default ContactContent;