import React, { ReactNode } from "react";
import { Modal } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Loader from "common/components/UIComponents/Loader";
import { useAppSelector } from "common/hooks/redux-hooks";
import { AppState } from "store";
import { ButtonComponent } from "cp-common-ui-components";

interface ICustomModalProps {
    children: ReactNode;
    show: boolean;
    cancelButtonName?: string;
    hideCancelButton?: boolean;
    confirmButtonName?: string;
    additionalActionButtonName?: string;
    title?: string;
    hideFooter?: boolean;
    onSubmit: () => void;
    onAdditionalButtonClick?: () => void;
    onHide: () => void;
    onCancelButtonClick?: () => void;
    size?: "sm" | "lg" | "xl" | undefined;
    className?: string;
    additionalFooterElement?: ReactNode;
    disableConfirmButton?: boolean;
    closeVariant?: "black" | "white" | undefined;
    backdropValue?: "static" | true;
}

export const CustomModal: React.FC<ICustomModalProps> = (props) => {
    const onHideModal = () => {
        if (props.onCancelButtonClick) {
            props.onCancelButtonClick();
            return;
        } else {
            props.onHide();
        }
    };
    const isModalLoading =  useAppSelector((state: AppState) => state.loaderReducer?.isModalLoading);
    return (
        <Modal
            className={"custom-modal-container " + props.className}
            show={props.show}
            onHide={props.onHide}
            onClick={(e: any) => {
                e.stopPropagation();
            }}
            size={props.size}
            backdrop={props.backdropValue == "static" ? props.backdropValue : true}
        >

            <ModalHeader closeButton closeVariant={props.closeVariant ?? "black"}>
                <Modal.Title>{props.title}</Modal.Title>
            </ModalHeader>
            <Modal.Body>                
            {isModalLoading && <Loader/> }
                {props.children}
            </Modal.Body>
            {!props.hideFooter && (
                <Modal.Footer className={props.additionalFooterElement ? "d-flex justify-content-between" : ""}>
                    {props.additionalFooterElement && <div className="additional-action">{props.additionalFooterElement}</div>}
                    <div className="d-flex">
                        {!props.hideCancelButton && <ButtonComponent
                            variant="outline-tertiary"
                            buttonClassName="mr-2"
                            onClick={() => {
                                onHideModal();
                            }}
                        >
                            {props.cancelButtonName ?? "Cancel"}
                        </ButtonComponent>}
                        <ButtonComponent disabled={props.disableConfirmButton} onClick={props.onSubmit}>
                            {props.confirmButtonName ?? "Confirm"}
                        </ButtonComponent>
                        {props.additionalActionButtonName && (
                            <ButtonComponent buttonClassName="ml-2" onClick={props.onAdditionalButtonClick}>
                                {props.additionalActionButtonName}
                            </ButtonComponent>
                        )}
                    </div>
                </Modal.Footer>
            )}
        </Modal>
    );
};
