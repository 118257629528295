import React from "react";
import { FooterComponent } from "cp-common-ui-components";
import { isMobile } from "../../helper/HelperFunctions";
import { InvalidPageConstants } from "../../helper/Constants";
import "../link-lock/index.scss";

export interface InvalidPageProps {
    message?:string
}

const InvalidPage: React.FC<InvalidPageProps> = (props) => {
    return (
        <div className="link-lock-page-continer invalid-page">
            <div className="header-placeholder"></div>
            <div className="link-lock-page">
                <div className="link-lock-text-container">
                    {props.message?props.message:InvalidPageConstants.InvalidLink}
                </div>
            </div>
            {!isMobile() && <FooterComponent />}
        </div>
    );
};

export default InvalidPage;