import { ClientType, FormGroup, SignatureStatus } from "common/enum";

export interface OrganizerStoreState {
  loading: boolean;
}

export enum ReviewStatus {
  None = 0,
  SentForReview = 1,
  PendingReview = 2,
  Reviewed = 3,
  Ignored = 4
}
export interface IFormNote {
  formId: number;
  message: string;
  bookMark: string;
  pageNo: number;
}

export enum GatherStatus {
  Uploaded = 1,
  ProcessingUpload = 2,
  ErrorProcessingUpload = 3,
  Ready = 4,
  InProcess = 5,
  DeliveryInProgress = 6,
  DeliveryFailed = 7,
  Delivered = 8,
  Completed = 9
}
export enum UploadMethod {
  None = 0,
  Proforma = 1,
  Batch = 2
}
export interface IOrganizerSignModel {
  gatherId: number;
  uniqueId: string;
  uploadMethod: UploadMethod;
  clients: IGatherClientDetail[];
  formGroups: FormGroup[];
  gatherDocumentSetting: any; // TODO: Change to IGatherDocumentSetting
  organizerUrl: string;
}

export interface IOrganizerSaveModel {
  formGroup: FormGroup;
  signature?: string;
  isReviewed: boolean;
}
// TODO: Move to clients model

export interface IClient {

  id: number;
  name: string;
  emailAddress: string;
  ssn: string;
  mobileNumber: string;
  countryCode: string;
  dob: Date;
  address: string;
  city: string;
  state: string;
  zip: string;
  isDeceased: boolean;

}
export interface IGatherClientDetail extends IClient {
  clientGuid: string;
  signatureStatus: SignatureStatus;
  signingOrder: number;
  signedOn: Date;
  clientType: ClientType;
  reviewStatus: ReviewStatus;
  hideWarning: boolean;
  isSigner: boolean;
  locationId: number;
  ero: number;
  gatherStatus: GatherStatus;
}

export class GatherMetadata {
  clientName: string;
  senderName: string;
  taxYear: number;
  isPreview: boolean;
  isClientView: boolean;
  isAccessedFromOneHub: boolean;

  constructor(
      clientName: string,
      senderName: string,
      taxYear: number,
      isPreview: boolean,
      isClientView: boolean,
      isAccessedFromOneHub: boolean) {
      this.clientName = clientName;
      this.senderName = senderName;
      this.taxYear = taxYear;
      this.isPreview = isPreview;
      this.isClientView = isClientView;
      this.isAccessedFromOneHub = isAccessedFromOneHub;
  }

  public static createNullObject(): GatherMetadata {
      return new GatherMetadata("", "", 0, false, false, false);
  }
}