import React, { FC } from 'react'
import "../styles/Loader.scss"

const Loader: FC = () => {
    return (
        <>
            <div className='loader-style'>
                
                    <div className="spinner-border text-primary" role="status">
                    </div>
                        <span className=''>Loading...</span>
                
            </div>
        </>

    )
}

export default Loader