export interface ClientModel {
  clientGuid: string;
  signatureStatus: number;
  signingOrder: number;
  signedOn: Date | null;
  clientType: number;
  reviewStatus: number;
  hideWarning: boolean;
  isSigner: boolean;
  locationId: number;
  ero: number;
  gatherStatus: number;
  id: number;
  name: string;
  emailAddress: string;
  ssn: string | null;
  mobileNumber: string;
  countryCode: string;
  isDeceased: boolean;
  current:boolean
}

export interface Clients{
  taxpayer: ClientModel
  spouse : ClientModel
}

export const initialClientModel: ClientModel = {
  clientGuid: "",
  signatureStatus: 0,
  signingOrder: 0,
  signedOn: null,
  clientType: 0,
  reviewStatus: 0,
  hideWarning: false,
  isSigner: false,
  locationId: 0,
  ero: 0,
  gatherStatus: 0,
  id: 0,
  name: "",
  emailAddress: "",
  ssn: null,
  mobileNumber: "",
  countryCode: "",
  isDeceased: false,
  current:false
}

export const intialClients:Clients ={
  taxpayer:initialClientModel,
  spouse: initialClientModel
}