import { Action } from "redux"
import { ThunkAction } from "redux-thunk"
import { AppState } from "store"
import Axios from "services/api/axios-interceptor"
import { API } from "services/api/api-config"
import { PreviewStatus, StatusType } from "common/enum"
import { setNotification } from "store/slices/notification"

export const getPreviewDocumentStatus = (
    clientId: string,
    successCallback: (data: any) => void,
    errorCallback?: () => void
): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        try {
            Axios.get(`${API.PREVIEW_PAGE.GET_PREVIEW_DOCUMENT_STATUS}/${clientId}`)
                .then((response) => response)
                .then((data: PreviewStatus) => {
                    successCallback && successCallback(data)
                })

        } catch (e) {
            dispatch(setNotification({
                type: StatusType.Error,
                message: "Error while fetching Preview Status"
            }))
            errorCallback && errorCallback();
        }
    }