import { IControl } from "./controls";
import { IDocumentType } from "./viewModel/DocumentSignatureDataViewModel";
import { Guid } from "./common";

export interface IElForm {
    pageNo: number;
    controls: IControl[];
    allControlSigned?: boolean;
}

export class DocumentType {

    id: number;
    name: string;

    constructor(id: number, name: string) {
        this.id = id;
        this.name = name;
    }

    public static createNullObject() {
        return new DocumentType(0, "");
    }
}

export interface IPage {
    page: number;
    formName: string;
    bookmark: string;
    controls: IControl[];
}

export class Page implements IPage {
    page: number;
    formName: string;
    bookmark: string;
    controls: IControl[];


    constructor(page: number, formName: string, bookmark: string, controls: IControl[]) {
        this.bookmark = bookmark;
            this.formName = formName;
            this.page = page;
        this.controls = controls;

    }

    public static create(page: number, formName: string, bookmark: string, controls: IControl[]): IPage {
        return new Page(page, formName, bookmark, controls);
    }

}

export interface IElDocument {

    id: number;
    url: string;
    forms: IElForm[];
    type: ELDocType;
    signCompleted?: boolean;
    fileName?: string;
    documentType: IDocumentType;
    fileGuid: Guid;
}

export class ElDocument implements IElDocument {
    id: number;
    url: string;
    forms: IElForm[];
    type: ELDocType;
    signCompleted?: boolean;
    fileName: string;
    documentType: IDocumentType;
    fileGuid: Guid;

    constructor(id: number, url: string, forms: IElForm[], type: ELDocType, signCompleted: boolean, fileName: string, documentType: IDocumentType, fileGuid: Guid) {
        this.id = id;
        this.url = url;
        this.forms = forms;
        this.type = type;
        this.signCompleted = signCompleted;
        this.fileName = fileName;
        this.documentType = documentType;
        this.fileGuid = fileGuid;

    }

    public static create(id: number, url: string, forms: IElForm[], type: ELDocType, signCompleted: boolean, fileName: string, documentType: IDocumentType, fileGuid: Guid): IElDocument {
        return new ElDocument(id, url, forms, type, signCompleted, fileName, documentType, fileGuid);
    }

    public static createNullObject(): IElDocument {
        return new ElDocument(0, "", [], ELDocType.EL, false, "", IDocumentType.None, Guid.newGuid());
    }

}

export enum ELDocType {
    EL = 0,
    Additional = 1,
    OrganizerSignDocument = 2
}

export enum ELDocLabel {
    EL = "Engagement Letter",
    Additional = "Additional E-Sign Docs",
    Organizer = "Organizer Signature Docs"
}

export enum ELDocControlState {
    None = 0,
    NoControles = 1,
    RequiredControlExists = 2,
    RequiredControlNotExists = 3,
}

