import React from 'react'
import { Accordion as GtrAccordian, AccordionProps, Collapse } from 'react-bootstrap';
import './Accordian.scss';

interface GtrAccordianProps extends AccordionProps {
    isAlwaysOpen?: boolean;
    accordianItems: any[];
    className?: string;
}
let key: any = "0";
const Accordian: React.FC<GtrAccordianProps> = ({ isAlwaysOpen,
    accordianItems,
    className,
    ...props }) => {
    const [activeKey, setActiveKey] = React.useState<any>("0");

    const onAccordianSelectionChange = (selectedkey: any) => {
        key = selectedkey !== null ? selectedkey[0] : null;
        setActiveKey(key);
    }
    return <GtrAccordian className={className} {...props}
        activeKey={activeKey}
        defaultActiveKey="0" onSelect={onAccordianSelectionChange}
        alwaysOpen={isAlwaysOpen} >
        {accordianItems?.map((item: any, index: number) => {
            return <GtrAccordian.Item key={item.index + item.title}
                style={{ height: `${index}` === key ? `${100 - ((accordianItems.length - 2) * 10)}%` : 'fit-content' }}
                className={`${item.itemClassName} ${index == key ? "not-collapsed-item" : "collapsed-item"} `}
                eventKey={`${index}`}>
                <GtrAccordian.Header className={item.headerClassName}>{item.title}</GtrAccordian.Header>
                <GtrAccordian.Body className='accordian-body'>
                    {item.content}
                </GtrAccordian.Body>
            </GtrAccordian.Item>
        })
        }
    </GtrAccordian>
}
export default Accordian;