import React, { FC, useEffect, useState } from 'react';
import { MyAccountConstants } from '../../../helper/Constants';
import { Form } from 'react-bootstrap';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux-hooks';
import { AppState } from 'store';
import { getCurrentClient, getOtherClient, isEqual } from 'helper/HelperFunctions';
import { ClientModel, initialClientModel } from 'common/model/clients';
import { ClientType } from 'common/enum';
import { getSpouseInfo, getTaxPayer } from 'store/services/clients';
import { useClientId } from 'common/hooks/client-hook';

interface IMyAccountForm {
    otherClient: ClientModel,
    currentClient: ClientModel,
    setCurrentClient: React.Dispatch<React.SetStateAction<ClientModel>>,
    setOtherClient: React.Dispatch<React.SetStateAction<ClientModel>>,
}


const MyAccountForm: FC<IMyAccountForm> = ({
    otherClient,
    currentClient,
    setCurrentClient,
    setOtherClient
}) => {

    const [isSpouse, setIsSpouse] = useState(false)

    const taxpayer:ClientModel = useAppSelector((state: AppState) => state.clientReducer?.taxpayer)

    const spouse:ClientModel = useAppSelector((state: AppState) => state.clientReducer?.spouse)

    const [isJointFilling, setIsJointFilling] = useState(false)

    const dispatch = useAppDispatch();

    const [clientId] = useClientId()

    useEffect(() => {
        dispatch(getTaxPayer(clientId))
        dispatch(getSpouseInfo(clientId))
    }, [])


    useEffect(() => {
        setCurrentClient(getCurrentClient(taxpayer, spouse))
        setOtherClient(getOtherClient(taxpayer, spouse))
    }, [taxpayer, spouse])

    useEffect(() => {
        setIsSpouse(otherClient.clientType === ClientType.Spouse)
    }, [otherClient?.clientType])

    useEffect(() => {
        if (spouse.clientGuid) {
            setIsJointFilling(true)
        }
        else {
            setIsJointFilling(false)
        }
    }, [spouse])
    
    const handleOtherEmailChange = (email: string) => {
        setOtherClient({
            ...otherClient,
            emailAddress: email
        })
    }

    const handleOtherMobileChange = (fullValue: string, countryObject: CountryData) => {
        setOtherClient({
            ...otherClient,
            countryCode: `${countryObject?.dialCode}`,
            mobileNumber: fullValue.slice(countryObject.dialCode?.length)
        })
    }

    const handleCurrentMobileChange = (fullValue: string, countryObject: CountryData) => {
        setCurrentClient({
            ...currentClient,
            countryCode: `${countryObject?.dialCode}`,
            mobileNumber: fullValue.slice(countryObject.dialCode?.length)
        })
    }
    return (currentClient.name ? <div className='my-account-form'>
        <b className="margin-bottom-5-px">{MyAccountConstants.MobileNumber}</b>
        <div className="margin-bottom-15-px">{MyAccountConstants.MobileNumberAccessCode}</div>
        <div className="padding-5-px margin-bottom-15-px">
            <Form.Group>
                <Form.Label className="margin-bottom-5-px">{MyAccountConstants.MobileNumberLabel}</Form.Label>
                <PhoneInput
                    inputClass="ss-input"
                    country={'us'}
                    value={`${currentClient?.countryCode}${currentClient?.mobileNumber}`}
                    onChange={handleCurrentMobileChange} />
            </Form.Group>
        </div>
        {currentClient.signingOrder === 1 && isJointFilling &&
            <div>
                <hr />
                <div className="spouse-name">
                    {isSpouse ? MyAccountConstants.SpouseName : MyAccountConstants.TaxPayerName}
                    <span>{otherClient?.name}</span>
                </div>
                <div className="padding-5-px">
                    <Form.Group className="margin-bottom-5-px">
                        <Form.Label className="margin-bottom-5-px">{MyAccountConstants.EmailAddressLabel}</Form.Label>
                        <Form.Control size="sm" className="ss-input" type="email" value={otherClient?.emailAddress} onChange={(e) => handleOtherEmailChange(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="margin-bottom-5-px">{MyAccountConstants.MobileLabel}</Form.Label>
                        <PhoneInput
                            inputClass="ss-input"
                            country={'us'}
                            value={`${otherClient?.countryCode}${otherClient?.mobileNumber}`}
                            onChange={handleOtherMobileChange} />
                    </Form.Group>
                </div>
            </div>
        }
    </div> : <></>)
};

export default MyAccountForm;