import React, { FC, useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { CustomModal } from './CustomModal'
import { OtpPageConstants } from 'helper/Constants'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux-hooks'
import { useNavigate } from 'react-router-dom'
import { logOut, refreshToken } from 'store/services/otpStore'
import { useClientId } from 'common/hooks/client-hook'
import "../styles/IdleTimer.scss"
import { setNoticePopup } from 'store/slices/common-slice'
import { AppState } from 'store'

 const SESSION_TIME_OUT = Number(process.env.REACT_APP_SESSION_TIMEOUT_MINUTES);

const IdleTimer:FC = () => {

    const [showModal,setShowModal] =useState(false);

    const [second,setSecond] =useState(60);

    const dispatch = useAppDispatch();

    const navigate =useNavigate();

    const [clientId] =useClientId();

    const isPreview = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isPreview);
    const isClientView = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isClientView);


    useEffect(()=>{
        if(showModal===true){
            const interval = setInterval(()=>{

                setSecond(prevSecond => prevSecond -1)
            },1000)
                
            return ()=> clearInterval(interval)
        }
        
    },[showModal])
      
    const onPrompt = () => {
        setShowModal(true);
    }
      
    const handlelogOut = () =>{
        setShowModal(false);
        dispatch(setNoticePopup(false))
        dispatch(logOut(clientId, logOutCallback));
        localStorage.removeItem('encodedClientId_'+clientId)   
    }

    const logOutCallback = (encodedClientId: string) => {
        if(isClientView||isPreview){
            navigate("/session-timeout")
        }
        else{
            navigate("/" + encodedClientId);
        }
    }

    const onContinue = () =>{
        setShowModal(false);
        setSecond(60);
        idleTimer.reset();
    }
    const onAction = () =>{
        dispatch(refreshToken(clientId)) 
    }

    const idleTimer = useIdleTimer({
        timeout: 1000 * 60 * SESSION_TIME_OUT,
        promptBeforeIdle: 1000 * 60,
        onPrompt,
        onIdle:handlelogOut,
        onAction,
        debounce:10000
      })

  return (
    <>
     <CustomModal
          className='session-modal'
          show={showModal}
          cancelButtonName='Log Out'
          confirmButtonName='Continue Working'
          onHide={() => { setShowModal(false)}}
          title="Session Expiration"
          onCancelButtonClick={handlelogOut}
          onSubmit={onContinue}
          hideCancelButton={isPreview||isClientView}
        >
          <div>
            <div className='session-time'>
                <p>{OtpPageConstants.sessionExpiry}</p>     
                <span className='time'>{OtpPageConstants.time.replace('<n>',second.toLocaleString())}</span>
            </div>
            <p>{OtpPageConstants.continueWorking}</p>
          </div>
        </CustomModal>
    </>
  )
}

export default IdleTimer