import * as React from "react";
import Select from "react-select";

export interface IDropdownComponentProps {
  selectedValue?: any;
  options: any;
  onChange?: (selectedValue: any) => void;
  onOpen?: () => void;
  clearable?: boolean;
  disabled?: boolean;
  customPlaceHolder?: any;
  id: string;
  onFocus?: (event: any) => void;
  backspaceRemoves?: boolean;
  deleteRemoves?: boolean;
  searchable?: boolean;
}

export const DropdownComponent: React.FC<IDropdownComponentProps> = (props) => {
  const onChange = (event: any) => {
    const selectedValue = event ? event.value : event;
    if (props.onChange) {
      props.onChange(selectedValue);
    }
  };
  const onOpen = () => {
    if (props.onOpen) {
      props.onOpen();
    }
  };

  return (
    <Select
      className="ss-select"
      searchable={false}
      id={props.id}
      value={props.selectedValue}
      onChange={onChange}
      onOpen={onOpen}
      options={props.options}
      placeholder={props.customPlaceHolder && props.customPlaceHolder.length > 0 ? props.customPlaceHolder : "Select..."}
      clearable={props.clearable}
      disabled={props.disabled ? props.disabled : false}
      data-toggle="dropdown"
      data-boundary="window"
      onFocus={props.onFocus}
      backspaceRemoves={!props.backspaceRemoves}
      deleteRemoves={!props.deleteRemoves}
    />
  );
};

export default DropdownComponent;
