import * as React from "react";
import { Form } from "react-bootstrap";
import { IAnswerModel } from "../../common/model/custom-questions-model";
import { ClientType } from "../../common/enum";
import { IOnChangeHandlerPayload } from "../../store/types/cq-preview";
import { FormPreviewConstants } from "../../helper/Constants";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { handleParagraphOnChange } from "store/slices/customQuestion";
import { AppState } from "store";

interface ParagraphQuestionProps {
    isRequired: boolean;
    sectionIndex: number;
    sectionItemIndex: number;
    handleInputBlur?: () => void;
    handleInputFocus?: () => void;
    answer: IAnswerModel;
    isInvalid?: boolean;
    clientType?: ClientType;
    choiceId?: number;
    isFollowUpQuestion?: boolean;
    subQuestionIndex?: number;
}

const ParagraphQuestion: React.FC<ParagraphQuestionProps> = ({
    isRequired,
    sectionIndex,
    sectionItemIndex,
    answer,
    handleInputBlur,
    handleInputFocus,
    isInvalid,
    clientType,
    choiceId,
    subQuestionIndex,
    isFollowUpQuestion
}) => {
    const [answerState, setAnswerState] = React.useState(answer);
    const [isInvalidState, setIsInvalidState] = React.useState(isInvalid);
    const isClientView = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isClientView);
    const isPreview = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isPreview);

    const dispatch =useAppDispatch();

    React.useEffect(() => {
        setIsInvalidState(isInvalid);
    }, [isInvalid]);

    React.useEffect(() => {
        if (answer) {
            setAnswerState({ ...answer });
        }
    }, [choiceId]);

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isPreview || isClientView) {
            e.preventDefault();
            e.target.value = "";
            return;
        }

        const input = e.target.value.trimStart();
        setAnswerState({ ...answerState, answerText: input });
        if (isRequired) {
            if (input === "") {
                setIsInvalidState(true);
            } else {
                setIsInvalidState(false);
            }
        }

        const payload: IOnChangeHandlerPayload = {
            value: input,
            sectionIndex,
            sectionItemIndex,
            choiceId,
            subQuestionIndex
        };
        dispatch(handleParagraphOnChange(payload))
    };

    const getColorClassName = (clientType: any) => {
        let colorClass: string = "";
        if (clientType === ClientType.Spouse) {
            colorClass = "spouse-color";
        } else if (clientType === ClientType.Taxpayer) {
            colorClass = "taxpayer-color";
        }
        return colorClass;
    };

    return (
        <>
            <div className={`answer-wrapper ${sectionItemIndex >= 99 ? "padding-24" : sectionItemIndex >= 9 ? "padding-12" : ""} ${isFollowUpQuestion ? "follow-up-answer" : ""}`}>
                <Form.Control
                    id={`s-${sectionIndex}_q-${sectionItemIndex}`}
                    onChange={onChangeHandler}
                    as="textarea"
                    placeholder={FormPreviewConstants.ParagraphTypeQuestion.placeholder}
                    value={answerState.answerText}
                    required={isRequired}
                    maxLength={FormPreviewConstants.ParagraphTypeQuestion.maxLength}
                    className={
                        `${isInvalidState ? "invalid-input" : ""} ${getColorClassName(clientType)}`
                    }
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                />
                {isInvalidState && (
                    <p className="error-text">{FormPreviewConstants.RequiredValidation}</p>
                )}
            </div>
        </>
    );
};

export default ParagraphQuestion;
