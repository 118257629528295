
const MINIMUM_DATE = "0001-01-01T00:00:00";

export const getFormattedDate = (date: Date): string  => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export const getDateInMMDDYYYYFormat = (date: Date): string => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [month, day, year].join('-');
}

export const getCurrentDate = (): string => {

    var today = new Date();
    var dd = today.getDate().toString();

    var mm = (today.getMonth() + 1).toString();
    var yyyy = today.getFullYear();
    if (Number(dd) < 10) {
        dd = '0' + dd;
    }

    if (Number(mm) < 10) {
        mm = '0' + mm;
    }

    return mm + '/' + dd + '/' + yyyy;
}

export const isMinimumDate = (date: string): boolean => {
    return date == MINIMUM_DATE;
}