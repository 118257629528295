import * as React from "react";
import SectionItemPreview from "./SectionItemPreview";
import { ISections } from "../../common/model/custom-questions-model";

interface SectionProps {
    section: ISections;
    sectionsCount?: number;
    sectionIndex: number;
    calculateProgress: () => void;
    alertComponent?: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({
    section,
    sectionIndex,
    calculateProgress,
    alertComponent
}) => {
    const [questionaireSection, setQuestionaireSection] = React.useState({
        ...section,
    });
    React.useEffect(() => {
        setQuestionaireSection(section);
    }, [section]);

    return (
        <div className="preview-section-container">
            {alertComponent && !sectionIndex ? alertComponent : <></>}
            <div className="preview-section-header">
                <div className="section-title">{questionaireSection.sectionName}</div>
                <div><span className="cq-error-red">*</span>Required</div>
            </div>

            {questionaireSection &&
                questionaireSection.sectionItems?.length > 0 &&
                questionaireSection.sectionItems.map(
                    (sectionItem, sectionItemIndex) => (
                        <SectionItemPreview
                            key={sectionItemIndex}
                            sectionItem={sectionItem}
                            sectionItemIndex={sectionItemIndex}
                            sectionIndex={sectionIndex}
                            sectionItemsCount={questionaireSection.sectionItems.length}
                            calculateProgress={calculateProgress}
                        />
                    )
                )}
        </div>
    );
};

export default Section;
