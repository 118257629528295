import React, { FC, useEffect, useState } from 'react';
import MyAccountForm from '../../common/components/layout/MyAccountForm';
import MobileHeader from '../../common/components/layout/MobileHeader';
import { ButtonComponent } from 'cp-common-ui-components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux-hooks';
import { AppState } from 'store';
import { ClientModel, initialClientModel } from 'common/model/clients';
import { ValidateEmailAddress, ValidateMobileNumber, getCurrentClient, getOtherClient, isMobile } from 'helper/HelperFunctions';
import { Toaster } from 'common/components/Toasts';
import { saveClientDetails } from 'store/services/clients';
import { useNavigate } from 'react-router-dom';
import { useClientId } from 'common/hooks/client-hook';
import { TAXPAYER } from '../../route/paths';

const MyAccountMobile: FC = () => {

    const taxpayer: ClientModel = useAppSelector((state: AppState) => state.clientReducer?.taxpayer)

    const spouse: ClientModel = useAppSelector((state: AppState) => state.clientReducer?.spouse)

    const [currentClient, setCurrentClient] = useState(initialClientModel);

    const [otherClient, setOtherClient] = useState(initialClientModel);

    const [clientId] = useClientId()


    useEffect(() => {
        setCurrentClient(getCurrentClient(taxpayer, spouse))
        setOtherClient(getOtherClient(taxpayer, spouse))
    }, [taxpayer, spouse])

    const dispatch = useAppDispatch();

    const navigate = useNavigate()

    const saveDetils = (currentClient: ClientModel, otherClient: ClientModel) => {

        if (currentClient &&
            currentClient.mobileNumber &&
            currentClient.countryCode &&
            !ValidateMobileNumber(currentClient.mobileNumber, currentClient.countryCode)) {
            Toaster.warn("Please Enter Valid Number");
            return;
        }
        if (otherClient &&
            otherClient.mobileNumber &&
            otherClient.countryCode &&
            !ValidateMobileNumber(otherClient.mobileNumber, otherClient.countryCode)) {
            Toaster.warn("Please Enter Valid Number");
            return;
        }
        if (otherClient &&
            otherClient.emailAddress &&
            !ValidateEmailAddress(otherClient?.emailAddress)) {
            Toaster.warn("Please Enter Valid Email");
            return;
        }
        if (taxpayer.current) {
            if (currentClient.mobileNumber !== taxpayer.mobileNumber || currentClient.countryCode !== taxpayer.countryCode) {
                dispatch(saveClientDetails(clientId, currentClient))
            }
            if (otherClient.mobileNumber !== spouse.mobileNumber || otherClient.emailAddress !== spouse.emailAddress || otherClient.countryCode !== spouse.countryCode) {
                dispatch(saveClientDetails(clientId, otherClient))
            }
            else if (spouse.current) {
                if (currentClient.mobileNumber !== spouse.mobileNumber || currentClient.countryCode !== spouse.countryCode) {
                    dispatch(saveClientDetails(clientId, currentClient))
                }
                if (otherClient.mobileNumber !== taxpayer.mobileNumber || otherClient.emailAddress !== taxpayer.emailAddress || otherClient.countryCode !== taxpayer.countryCode) {
                    dispatch(saveClientDetails(clientId, otherClient))
                }
            }
        }
        else if (spouse.current) {
            if (currentClient.mobileNumber !== spouse.mobileNumber || currentClient.countryCode !== spouse.countryCode) {
                dispatch(saveClientDetails(clientId, currentClient))
            }
        }
        isMobile() && navigate(TAXPAYER.LANDING_PAGE + clientId)
    }
    return <div>
        <MobileHeader />
        <div className='padding-25-px margin-left-right-15-px my-account-wrapper'>
            <div className="margin-bottom-15-px title-h3">My Account</div>
            <MyAccountForm
                currentClient={currentClient}
                otherClient={otherClient}
                setCurrentClient={setCurrentClient}
                setOtherClient={setOtherClient}
            />
            <ButtonComponent buttonClassName='margin-top-25-px block' onClick={() => saveDetils(currentClient, otherClient)}>Save</ButtonComponent>
        </div>
    </div>;
};

export default MyAccountMobile;