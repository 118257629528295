import { ButtonComponent } from "cp-common-ui-components";
import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { FinishDocumentUploadModalConstants } from "../../helper/Constants";
import { useAppDispatch } from "../../common/hooks/redux-hooks";
import { changeSourceDocumentStatusToCompleted } from "../../store/services/sourceDocumentUpload";
import { useClientId } from "../../common/hooks/client-hook";
import { Toaster } from '../../common/components/Toasts';
import { useNavigate } from "react-router-dom";
import { TAXPAYER } from "../../route/paths";

interface IFinishDocumentUploadModalProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}
const FinishDocumentUploadModal: FC<IFinishDocumentUploadModalProps> = ({ show, setShow }) => {
    const dispatch = useAppDispatch();
    const [clientId] = useClientId();
    const navigate = useNavigate();
    const handleConfirm = () => {
        dispatch(changeSourceDocumentStatusToCompleted(
            clientId,
            (description) => {
                setShow(false);
                Toaster.success(description);
                navigate(TAXPAYER.LANDING_PAGE + `${clientId}`);
            },
            (errorDescription) => {
                setShow(false);
                Toaster.error(errorDescription);
            }));
    };
    return <Modal className="white-modal finish-modal" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
            <Modal.Title>{FinishDocumentUploadModalConstants.Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {FinishDocumentUploadModalConstants.Content}
        </Modal.Body>
        <Modal.Footer>
            <ButtonComponent variant="outline-tertiary" onClick={() => setShow(false)}>Cancel</ButtonComponent>
            <ButtonComponent onClick={handleConfirm}>Confirm</ButtonComponent>
        </Modal.Footer>
    </Modal>
};

export default FinishDocumentUploadModal;