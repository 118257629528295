import { ErrorStatus, StatusType } from "common/enum";
import { ThunkAction } from "redux-thunk";
import { AppState } from "store";
import { Action } from "@reduxjs/toolkit";
import Axios from "services/api/axios-interceptor"
import { API } from "services/api/api-config";
import { ClientResponse } from "common/model/clientResponse";
import { setDocumentRequestList, setDocumentRequestNames, setIsLoading } from "store/slices/document-request-slice";
import { setNotification } from "store/slices/notification";
import { ErrorMessages } from "helper/Constants";
import { ICategory, IDocumentRequestName } from "../../common/model/document-request-model";
import { setLoader } from "store/slices/loader";


export const getDocumentRequestList = (
    clientId: string,
    errorCallback?: (errorDescription: string) => void
): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
    try {
        dispatch(setLoader(true))

        Axios.get(`${API.DOCUMENT_REQUEST.GET_GATHER_DRL_NAMES}/${clientId}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then((response) => response)
            .then((data) => {
                let result: ClientResponse<IDocumentRequestName[]> = data;
                dispatch(setDocumentRequestNames(data))
                dispatch(setLoader(false))
                if (!result?.isSuccess) {
                    errorCallback && errorCallback(result.errorDescription)
                    dispatch(setLoader(false))
                    return;
                }
                
            })
    }
    catch (error: any) {
        dispatch(setNotification({
            type: StatusType.Error,
            message: ErrorMessages.RequestDocumentsError
        }))
        dispatch(setLoader(false))
    }
}

export const updateDocumentType = (
    clientId: string,
    payload: any,
    callback?: (data: any) => void,
    errorCallback?: (errorDescription: string) => void): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
        try {
            dispatch(setLoader(true))

            Axios.put(`${API.DOCUMENT_REQUEST.UPDATE_DRL_MAPPING}/${clientId}`, payload, {
                method: 'PUT',
                credentials: 'include'
            })
                .then((response) => response)
                .then((data) => {
                    const result: ClientResponse<any> = data;
                    if (!result?.isSuccess) {
                        errorCallback && errorCallback(result.errorDescription)
                        dispatch(setLoader(false))
                        return;
                    }

                    if (callback) {
                        callback && callback(result.data);
                    }
                    dispatch(setLoader(false))
                })
        }
        catch (error: any) {
            dispatch(setNotification({
                type: StatusType.Error,
                message: ErrorMessages.RequestDocumentsError
            }))
            dispatch(setLoader(false))
        }
    }


export const getCategoryList = (
    clientId: string,
    errorCallback?: (errorDescription: string) => void
): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
    try {
        dispatch(setLoader(true))

        Axios.get(`${API.DOCUMENT_REQUEST.GET_GATHER_DRL_MAPPINGS}/${clientId}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then((response) => response)
            .then((data) => {
                let result: ClientResponse<{ categorizedDocumenRequestList: ICategory[], isDRLExist: boolean }> = data;
                dispatch(setDocumentRequestList(data))
                if (!result?.isSuccess) {
                    errorCallback && errorCallback(result.errorDescription)
                    dispatch(setLoader(false))
                    return;
                }
                dispatch(setLoader(false))

            })
    }
    catch (error: any) {
        dispatch(setNotification({
            type: StatusType.Error,
            message: ErrorMessages.RequestDocumentsError
        }))
        dispatch(setLoader(false))
    }
}

export const updateNotApplicableStatus = (
    clientId: string,
    payload: any,
    callback?: (data: any) => void,
    errorCallback?: (errorDescription: string) => void): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
        try {
            dispatch(setLoader(true))

            Axios.put(`${API.DOCUMENT_REQUEST.UPDATE_NOT_APPLICABLE_STATUS}/${clientId}`, payload, {
                method: 'PUT',
                credentials: 'include'
            })
                .then((response) => response)
                .then((data) => {
                    const result: ClientResponse<any> = data;
                    if (!result?.isSuccess) {
                        errorCallback && errorCallback(result.errorDescription)
                        dispatch(setLoader(false))
                        return;
                    }

                    if (callback) {
                        callback && callback(result.data);
                    }
                    dispatch(setLoader(false))
                })
        }
        catch (error: any) {
            dispatch(setNotification({
                type: StatusType.Error,
                message: ErrorMessages.RequestDocumentsError
            }))
            dispatch(setLoader(false))
        }
    }