import * as React from "react";
import { CaretRight, CaretLeft } from "../../assets/svg/SVGIconsCollection";
import { ESignDocumentControlState } from "common/model/esign";
import { ButtonComponent } from "cp-common-ui-components";
import { isMobile } from "../../helper/HelperFunctions";

interface FooterProps {
  onFinish(): void;
  onBack(): void;
  onSkip(): void;
  finishEnabled: boolean;
  controlStatus: ESignDocumentControlState;
  isLast:boolean
}

export const Footer: React.FC<FooterProps> = ({ onFinish, onBack, onSkip, finishEnabled, controlStatus,isLast }) => {
    return (
        <div className="esign-footer">
            {!isMobile() &&
                <ButtonComponent
                    variant="outline-primary"
                    onClick={onBack}
                > <CaretLeft color={"#0973ba"} /> <span className="margin-left-5-px">Back</span>
                </ButtonComponent>
            }
            {(controlStatus && controlStatus != ESignDocumentControlState.RequiredControlExists)
                ?
                <ButtonComponent id="btnSkip"
                    onClick={onSkip}
                    variant="danger"
                > Decline
                </ButtonComponent> : isMobile() ? <div /> : <></>

            }
            {(controlStatus && controlStatus != ESignDocumentControlState.NoControles)
                ?
                <div title="Complete & proceed">
                    <ButtonComponent id="btnFinish"
                        onClick={onFinish}
                        disabled={!finishEnabled}
                        buttonClassName={"w-100 m-0"}
                    > {isLast ? 'Finish':'Next'} 
                    
                    {!isLast && <span className="margin-left-5-px"><CaretRight color={!finishEnabled ? "#C4C6C8" : "#fff"} /></span>}
                    </ButtonComponent>
                </div>
                : <></>
            }

        </div>
    );
};
