import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig, ResponseType } from 'axios';
import { store } from 'store';
import { isMobile } from 'helper/HelperFunctions';
import appInsights from 'ai-logger/TelemetryService';


const defaultOptions = {
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Is-Mobile-Device': isMobile() ? 'true' : 'false',
    },
};
// To handle multiple unautohrized response (401)
let navigatedToLogin =false;
// Axios Interceptor to intercept all the API requests
export class AxiosService {
    axiosInstance: AxiosInstance;
    constructor() {
        this.axiosInstance = axios.create(defaultOptions);
        this.setUpInterceptors();
    }
    logException = (params: any, method: any, message: string) => {
        let operationText = "";
        switch (method) {
          case 'get':
            operationText = "Fetching";
            break;
          case 'post':
            operationText = "Adding";
            break;
          case 'put':
            operationText = "Updating";
            break;
          case 'delete':
            operationText = "Deleting";
            break;
          default:
            operationText = "API Request"
        }
        let errorText = params ? `failed for the request having parameters ${params} with Error` : 'failed with Error';
        let exception = { exception: new Error(`${operationText} ${errorText} ${message}`) };
        appInsights.trackException(exception);
      }
    setUpInterceptors() {
        // Request interceptor
        this.axiosInstance.interceptors.request.use(
            (config: InternalAxiosRequestConfig) => {
                config.withCredentials = true;
                const appState: any = store.getState();
                config.withCredentials = true
                if (appState?.authReducer?.authentication?.user?.access_token) {
                    if (config.headers) {
                        config.headers.Authorization = `Bearer ${appState.authReducer.authentication.user.access_token}`;
                        // you can update more header properties here
                    }
                }
                return config;
            },
            (error: AxiosError) => {
                return Promise.reject(error);
            }
        );
        // Response interceptor
        this.axiosInstance.interceptors.response.use(
            (response: AxiosResponse) => {
                return response;
            },
            (error: AxiosError) => {
                const errorResponse: any = error?.response;
                const errCode = errorResponse?.status;
                const errors = errorResponse?.data?.errors;
                this.logException(error.config?.params, error.config?.method, errors?.gatherId[0] || error?.message);
                
                if (errCode) {
                    switch (errCode) {
                        case 400:
                        // handle client-side error
                        break;
                        case 401:
                            const clientId=(window.location.href).split('/')[4];
                            if(clientId){
                                const encodedUrl = localStorage.getItem('encodedClientId_'+clientId)
                                if (encodedUrl) {
                                    window.location.href = '/' + encodedUrl;
                                    localStorage.removeItem('encodedClientId_'+clientId);
                                    navigatedToLogin =true
                                }
                                else if(!navigatedToLogin){
                                    window.location.href = '/'
                                }
                            
                            }
                            else{
                                window.location.href = '/'
                            }
                            break;
                        case 404:
                        // handle client-side error
                        break;
                        case 419:
                        // handle client-side error
                        break;
                        case 500:
                        // handle server-side error
                        break;
                        case 504:
                        // handle server-side error
                        break;
                        case 512:
                        // handle server-side error
                        break;
                        default:
                            if (window.location.href.includes("restricted-access")) {
                                // 
                            }
                            break;
                    }
                }
                else {
                    return Promise.reject(error);
                }
            }
        );
    }
    get = (url: string, headers?: any, includeFullResponse?:boolean,responseType:ResponseType='json') => {
        return this.axiosInstance.get(url, {
            headers: { ...defaultOptions.headers, ...headers },
            responseType
        }).then((response) => {
            return (response?.data && !includeFullResponse ? response.data : response)
        })
    }

    post = (url: string, data: any, headers?: any) => {
        return this.axiosInstance.post(url, data, {
            headers: { ...defaultOptions.headers, ...headers },
        }).then((response) => (response?.data ? response.data : response));
    }

    delete = (url: string, headers: any = {}) =>
        this.axiosInstance.delete(url, {
           ...defaultOptions.headers, ...headers,
        }).then((response) => (response?.data ? response.data : response));

    deleteWithPayload = (url: string, data?: any, headers?: any) =>
        this.axiosInstance.delete(url, {
            data,
            headers: { ...defaultOptions.headers, ...headers },
        }).then((response) => (response?.data ? response.data : response));

    put = (url: string, data?: any, headers?: any) =>
        this.axiosInstance.put(url, data, {
            headers: { ...defaultOptions.headers, ...headers },
        }).then((response) => (response?.data ? response.data : response));

    patch = (url: string, data?: any, headers?: any) => {
        return this.axiosInstance.patch(url, data, {
            headers: { ...defaultOptions.headers, ...headers },
        }).then((response) => (response?.data ? response.data : response));
    }
}

const Axios = new AxiosService();
export default Axios;