import Axios from "services/api/axios-interceptor"
import {  Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { API } from "services/api/api-config";
import { AppState } from "store";
import { IFormData } from "common/model/custom-questions-model";
import { setQuestionnaire } from "store/slices/customQuestion";
import { setNotification } from "store/slices/notification";
import { FormGroupStatus, StatusType } from "common/enum";
import { setLoader } from "store/slices/loader";

export const getFormData = 
    (clientId : string, callback?:() => void)
     : ThunkAction<void, AppState, unknown, Action> => (dispatch) =>{
        dispatch(setLoader(true))  
        Axios.get(`${API.CUSTOM_QUESTION.GET_FORM_DATA}/${clientId}`,{
            method:'GET',
            credentials :'include'
        })
        .then((response)=>response)
        .then((data)=>{            
            const response:IFormData =data
            if (response){                
                dispatch(setQuestionnaire(response))
                dispatch(setLoader(false)) 
            }
        })
        .catch((error:any)=>{
            dispatch(setNotification({
                message : error.response,
                type :StatusType.Error
            }))
            dispatch(setLoader(false)) 
        })
    }

export const getCustomQuestionStatus = (clientId: string) =>{
    return Axios.get(`${API.CUSTOM_QUESTION.GET_CUSTOM_STATUS}/${clientId}`)
            .then((response)=>response.data)
            .then((data:FormGroupStatus) =>  data)
            .catch((error) => Promise.reject(error.message))
}

export const onSaveForm =(
    clientId:string,
    formData:IFormData,
    callback:()=>void):ThunkAction<void, AppState, unknown, Action> => (dispatch) =>{
        dispatch(setLoader(true))  
        Axios.post(`${API.CUSTOM_QUESTION.SAVE_FORM_DATA}/${clientId}`,formData)
            .then((response)=>response)
            .then((data) => {
                if(data){
                    callback();
                }
                dispatch(setLoader(false))  
            })
            .catch((error:any)=>{
                dispatch(setNotification({
                    message : error.response,
                    type :StatusType.Error
                }))
                dispatch(setLoader(false))  
            })
    }

export const onFinishForm = (
    clientId : string,
    formData : IFormData,
    callback :()=>void
):ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
    dispatch(setLoader(true))  
    Axios.post(`${API.CUSTOM_QUESTION.FINISH_FORM}/${clientId}`,formData)
    .then((response)=>response)
    .then((data)=>{
        if(data){
            callback();
        }
        dispatch(setLoader(false)) 
    })
    .catch((error:any)=>{
        dispatch(setNotification({
            message:error.response,
            type:StatusType.Error
        }))
        dispatch(setLoader(false)) 
    })
}

export const onAutoSave =(
    clientId :string,
    formData :IFormData,
    callback :()=>void
):ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
    Axios.post(`${API.CUSTOM_QUESTION.AUTO_SAVE_FORM}/${clientId}`,formData)
    .then((response)=>response)
    .then((data)=>{
        if(data){
            callback()
        }
    })
    .catch((error:any)=>{
        dispatch(setNotification({
            message:error.response,
            type:StatusType.Error
        }))
    })
}
