import React, { FC, useEffect, useState } from "react";
import { useAppDispatch } from "common/hooks/redux-hooks";
import { ButtonComponent, FooterComponent } from "cp-common-ui-components";
import Header from "../../common/components/layout/Header";
import { GatherStepStatus, HeaderType, SignatureStatus } from "../../common/enum";
import { useAppSelector } from "../../common/hooks/redux-hooks";
import { AppState } from "store";
import "./index.scss";
import { ArrowRight, InfoIcon, LandingPageImageMobile } from "../../assets/svg/SVGIconsCollection";
import VerticalSteps from "./VerticalSteps";
import ContactPopup from "../../common/components/layout/ContactPopup";
import { requestPreparerMessage, requestGatherStepsWithStatus, downloadEngagementLetter } from "../../store/services/landingPageStore";
import { gatherStepsWithStatus } from "common/model/gatherStepsWithStatus";
import { LandingPageConstants, PageURL, StepName } from "../../helper/Constants";
import { LandingPageCompleted, LandingPageImage } from "../../assets/svg/LogoCollection";
import { Col, Row } from "react-bootstrap";
import MessageSection from "./MessageSection";
import { isMobile } from "../../helper/HelperFunctions";
import StepComponent from "../../common/components/layout/StepComponent";
import { useNavigate } from "react-router-dom";
import { ClientModel, initialClientModel } from "common/model/clients";
import { useClientId } from "common/hooks/client-hook";
import { MemoizedSignalRWebSocket } from "common/components/signalR/SignalRWebSocket";
import { TAXPAYER } from "route/paths";
import { getGatherCompanySettingsModel } from "store/services/companySettings";
import { GatherMetadata } from "common/model/organizer";
import { getGatherMetaData } from "store/services/esign";
import { getDataForAppInsights } from "ai-logger/TelemetryService";

const LandingPage: FC = () => {
  const [clientId] = useClientId();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getGatherCompanySettingsModel(clientId))
    dispatch(requestPreparerMessage(clientId));
    dispatch(requestGatherStepsWithStatus(clientId));
    dispatch(getGatherMetaData(clientId, (metaData: GatherMetadata) => { }));
    if (clientId) {
      getDataForAppInsights(clientId);
    }
  }, []);

  const isStepLoading: boolean = useAppSelector(
    (state: AppState) => state.gatherStepsWithStatusReducer?.isLoading
  );


  const stepsWithStatus: gatherStepsWithStatus[] = useAppSelector(
    (state: AppState) => state.gatherStepsWithStatusReducer?.stepsWithStatus
  );

  const taxPayer: ClientModel = useAppSelector((state: AppState) => state.clientReducer?.taxpayer);
  const spouse: ClientModel = useAppSelector((state: AppState) => state.clientReducer?.spouse);

  const contactPerson = useAppSelector((state: AppState) => state.coverPageReducer?.contactPerson);

  const [client, setClient] = useState(initialClientModel);
  const [otherClient, setOtherClient] = useState(initialClientModel);

  const [isSignatureDisabled, setIsSignatureDisabled] = useState(false);


  const [firstPendingIndex, setFirstPendingIndex] = useState(-1)

  useEffect(() => {
    if (taxPayer?.current) {
      setClient(taxPayer);
      setOtherClient(spouse)
    } else {
      setClient(spouse);
      setOtherClient(taxPayer)
    }
  }, [taxPayer, spouse]);

  const signingStepPresent = () => {
    return (stepsWithStatus?.findIndex(step => step.name === StepName.Sign && step.status === 1) >= 0)
  }

  const hasUploadStep = () => stepsWithStatus?.some((step) => step.name === StepName.Upload);

  useEffect(() => {
    const status = client.signatureStatus === SignatureStatus.None
      && client.signingOrder === 2
      && otherClient.signatureStatus === SignatureStatus.None
      && signingStepPresent();
    setIsSignatureDisabled(status);
  }, [client, otherClient, stepsWithStatus])

  useEffect(() => {

    setFirstPendingIndex(stepsWithStatus?.findIndex(x => x.status === GatherStepStatus.Pending))

  }, [stepsWithStatus])

  const getButtonText = () => {
    const notYetStarted = stepsWithStatus?.every((step) => step.status === GatherStepStatus.Pending);
    if (notYetStarted) {
      return LandingPageConstants.GetStarted;
    } else {
      return (
        <span>
          {LandingPageConstants.Continue} <ArrowRight />
        </span>
      );
    }
  };

  const isStepsLoaded = () => {
    return stepsWithStatus?.length !== 0;
  }

  const isAllTheStepCompleted = () => stepsWithStatus?.every((step) => step.status === GatherStepStatus.Done);
  const getRemainingTime = () => {
    const totalSteps = stepsWithStatus?.length;
    const completedSteps = stepsWithStatus && stepsWithStatus?.filter((step) => step.status === GatherStepStatus.Done)?.length;
    return (totalSteps - completedSteps) * 5;
  };

  getRemainingTime();

  const staticTextWhenNoSignAndUploadStepsAvailable =
    (
      <div>
        <p className="margin-bottom-15-px">
          You do not have any task option available to be accessed via Mobile.
        </p>
        <p className="margin-bottom-15-px">
          To fill out your organizer, print your organizer, or download your tax documents please login using a PC or tablet.
        </p>
      </div>);
  return (
    <div>
      <Header type={HeaderType.BICOLOR} hideContact={true} />
      <div className="landing-page-container">
        {!isMobile() && (
          <div className="landing-page-side-bar">
            <div className="landing-page-steps-container">
              <p>{LandingPageConstants.IntoText}</p>
              <div className={`tag ${isAllTheStepCompleted() ? "done" : ""} ${!isStepLoading ? "visible" : "invisible"}`}>
                <InfoIcon color="#fff" />
                <span className="margin-left-5-px">
                  {isAllTheStepCompleted() ? (isStepsLoaded()
                    ? LandingPageConstants.Completed
                    : '') : `${LandingPageConstants.EstimatedText} ${getRemainingTime()} minutes`}
                </span>
              </div>
              <VerticalSteps />
              {!isMobile() && stepsWithStatus?.length !== 0 && <button
                onClick={() => navigate(PageURL[stepsWithStatus[firstPendingIndex].name] + clientId)}
                className={`accent-button ${isAllTheStepCompleted() ? "hide" : ""}`}
                disabled={isSignatureDisabled}
                title={isSignatureDisabled ? LandingPageConstants.AwaitingText : ''}
              >
                {getButtonText()}
              </button>}
            </div>
            <div className="support-section">
              <div className="margin-bottom-5-px">{LandingPageConstants.NeedHelp}</div>
              <div className="cursor-pointer" title="Support Contact">
                <ContactPopup
                  placement="right"
                  name={`${contactPerson?.firstName} ${contactPerson?.lastName}`}
                  emailAddress={contactPerson?.emailAddress}
                  mobileNo={contactPerson?.phone}
                />
              </div>
            </div>
          </div>
        )}

        <div className="landing-page-content">
          {isMobile() && <StepComponent />}
          <Row className={`greetings d-flex align-items-center justify-content-between w-100 ${!isStepLoading ? 'visible' : 'invisible'}`}>
            <Col xs={isMobile() ? 12 : 8} s={6}>
              {isMobile() && (
                stepsWithStatus?.length > 0 && (<div className={`tag ${isAllTheStepCompleted() ? "done" : ""} margin-top-10-px margin-bottom-20-px`}>
                  <InfoIcon color={isAllTheStepCompleted() ? "#669440" : "#075C95"} />
                  <span className="margin-left-5-px">
                    {isAllTheStepCompleted() ? (isStepsLoaded()
                      ? LandingPageConstants.Completed
                      : '') : `${LandingPageConstants.EstimatedText} ${getRemainingTime()} minutes`}
                  </span>
                </div>)
              )}
              <h2 className="welcome-title">
                <span className="landing-page-welcome">{isAllTheStepCompleted() ? LandingPageConstants.Hi : LandingPageConstants.Welcome}</span>
                <span className="ellipsis landing-page-name">
                  {taxPayer.current ? taxPayer.name : spouse.name}!
                </span>
              </h2>
              {
                isMobile() && (stepsWithStatus?.length > 0
                  ?
                  <h2>{isAllTheStepCompleted() ? (isStepsLoaded() ? LandingPageConstants.CompletedText : '') : LandingPageConstants.ToGetStarted}</h2>
                  : staticTextWhenNoSignAndUploadStepsAvailable)
              }
              {!isMobile() && (<h2 className="landing-page-subtext">{isAllTheStepCompleted() ? (isStepsLoaded() ? LandingPageConstants.CompletedText : '') : LandingPageConstants.ToGetStarted}</h2>)}
              
            </Col>
            <Col xs={isMobile() ? 12 : 4} s={6} className={isMobile() ? "text-center" : ""}>
              {isAllTheStepCompleted() && stepsWithStatus?.length !== 0 ? (
                <LandingPageCompleted />
              ) : isMobile() ? (
                <LandingPageImageMobile />
              ) : (
                <LandingPageImage />
              )}
            </Col>
          </Row>
          {!isAllTheStepCompleted() && !isMobile() && <MessageSection />}
          {isMobile() ? (
            isAllTheStepCompleted() && stepsWithStatus?.length !== 0 ? (
              <>
                <ButtonComponent buttonClassName="margin-top-15-px block" onClick={() => dispatch(downloadEngagementLetter(clientId))}>Download Documents</ButtonComponent>
                {hasUploadStep() ? <ButtonComponent
                  buttonClassName="margin-top-10-px block"
                  onClick={() => navigate(TAXPAYER.UPLOAD_DOCUMENTS + clientId)}
                >
                  View Uploads
                </ButtonComponent> : <></>}
              </>
            ) : ((stepsWithStatus?.length !== 0) && stepsWithStatus?.length > 0 ? (
              <button
                onClick={() => navigate(PageURL[stepsWithStatus[firstPendingIndex].name] + clientId)}
                className={`accent-button ${isAllTheStepCompleted() ? "hide" : ""}`}
                disabled={isSignatureDisabled}
                title={isSignatureDisabled ? LandingPageConstants.AwaitingText : ''}
              >
                {getButtonText()}
              </button>
            ) : <></>)
          ) : (
            <></>
          )}
        </div>
      </div>
      <MemoizedSignalRWebSocket client_id={clientId} />
      {!isMobile() && <FooterComponent />}
    </div>
  );
};

export default LandingPage;
