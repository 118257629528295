import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OrganizerStoreState } from "common/model/organizer";

const initialOrganizerState: OrganizerStoreState  = {
    loading : false,
}

const organizerPageSlice = createSlice({
    name :'organizerPage',
    initialState : initialOrganizerState,
    reducers :{
        setIsLoading :(state,action:PayloadAction<boolean>)=>{
            state.loading = action.payload
        },
    }
})

export const {setIsLoading} = organizerPageSlice.actions;

export default organizerPageSlice.reducer;