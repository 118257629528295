import { ClientType, DocumentStatus, EngagementType, SignatureStatus, SignatureType } from "common/enum"
import { TaxClient } from "./taxClient"
import { Taxpayer } from "./taxPayer"

export type TaxDocumentModel = {
    documentId: number,
    taxYear: string,
    engagementType: EngagementType,
    clientID: string,
    signatureType: SignatureType,
    signatureStatus: SignatureStatus,
    isKBAEnabled: boolean,
    deliverFirstTo: ClientType,
    documentStatus: DocumentStatus,
    taxpayer: Taxpayer,
    spouse: Taxpayer,
    partnership: TaxClient
}

export const initialTaxDocumentModel: TaxDocumentModel = {
    documentId: 0,
    taxYear: "",
    engagementType: EngagementType.None,
    clientID: "",
    signatureType: SignatureType.None,
    signatureStatus: SignatureStatus.None,
    isKBAEnabled: false,
    deliverFirstTo: ClientType.Undefined,
    documentStatus: DocumentStatus.None,
    taxpayer: {
        clientGuid: "",
        email: "",
        id: 0,
        isModified: false,
        name: "",
        ssn: "",
        address: "",
        city: "",
        dob: new Date(),
        isDeceased: false,
        state: "",
        zip: "",
        mobileNumber: ""
    },
    spouse: {
        clientGuid: "",
        email: "",
        id: 0,
        isModified: false,
        name: "",
        ssn: "",
        address: "",
        city: "",
        dob: new Date(),
        isDeceased: false,
        state: "",
        zip: "",
        mobileNumber: ""
    },
    partnership: {
        clientGuid: "",
        email: "",
        id: 0,
        isModified: false,
        name: "",
        ssn: "",
        mobileNumber: ""
    }
}