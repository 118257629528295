import * as React from "react";
import { Form } from "react-bootstrap";
import { IChoiceModel } from "../../../common/model/custom-questions-model";
import { ColorCode } from "../../../helper/BrandColors";
import { ClientType } from "../../../common/enum";
import { IHandleChoiceIdentifierPayload } from "../../../store/types/cq-preview";
import { FormPreviewConstants } from "../../../helper/Constants";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { handleMcqOnChange } from "store/slices/customQuestion";
import { GatherMetadata } from "common/model/organizer";
import { AppState } from "store";

interface MultiChoiceQuestionProps {
    choices: IChoiceModel[];
    isRequired: boolean;
    isInvalid?: boolean;
    subQuestionId?: number;
    sectionIndex: number;
    sectionItemIndex: number;
    isEditMode?: boolean;
    clientType?: ClientType;
    isFollowUpQuestion?: boolean;
}

const MultiChoiceQuestion: React.FC<MultiChoiceQuestionProps> = ({
    choices,
    subQuestionId,
    isRequired,
    sectionIndex,
    sectionItemIndex,
    clientType,
    isInvalid,
    isFollowUpQuestion,
}) => {
    const [isInvalidState, setIsInvalidState] = React.useState(isInvalid);
    const [options, setOptions] = React.useState<IChoiceModel[]>([...choices].sort((a, b) => a.id - b.id));
    const isClientView = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isClientView);
    const isPreview = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isPreview);

    React.useEffect(() => {
        setIsInvalidState(isInvalid);
    }, [isInvalid]);

    const dispatch =useAppDispatch();

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, choice: IChoiceModel) => {
        if (choice.isSelected) {
            e.preventDefault();
            return;
        }
        setIsInvalidState(false);
    };

    const onOptionClick = (e: React.MouseEvent<HTMLInputElement>, choice: IChoiceModel) => {
        if (choice.isSelected) {
            e.preventDefault();
            return;
        }
        let choiceId: number = choice.id;
        const payload: IHandleChoiceIdentifierPayload = {
            choiceId,
            sectionIndex,
            sectionItemIndex,
            isFollowUpQuestion,
            subQuestionId
        };
        setIsInvalidState(true);
        dispatch(handleMcqOnChange(payload));
    }

    const getColor = (clientType: any, choice: IChoiceModel) => {
        let color: string = ColorCode.DEFAULT;
        if (choice.isSelected) {
            if (clientType === ClientType.Spouse) {
                color = ColorCode.SPOUSE_COLOR;
            } else if (clientType === ClientType.Taxpayer) {
                color = ColorCode.TAXPAYER_COLOR;
            }
        }
        return color;
    };

    React.useEffect(() => {
        if (choices) {
            setOptions([...choices].sort((a, b) => a.id - b.id));
        }
    }, [choices]);

    return (
        <div className={`multi-choice-preview-wrapper answer-wrapper ${sectionItemIndex >= 99 ? "padding-24" : sectionItemIndex >= 9 ? "padding-12" : ""} ${isFollowUpQuestion ? "follow-up-answer" : ""}`}>
            <div className={isClientView || isPreview ? "non-editable" : ""}>
                {options?.length > 0 &&
                    options.map((choice, choiceIndex) => (
                        <Form.Check
                            id={`s-${sectionIndex}_q-${sectionItemIndex}`}
                            checked={choice.isSelected}
                            title={choice.text}
                            label={choice.text}
                            type="radio"
                            key={choiceIndex + choice.id}
                            onChange={(e) => onChangeHandler(e, choice)}
                            onClick={(e) => onOptionClick(e, choice)}
                            style={{ color: getColor(clientType, choice) }}
                            className={isInvalidState && isRequired ? "error-radio" :
                                clientType ? clientType === ClientType.Taxpayer ? "taxpayer-radio" : "spouse-radio" : ""}
                        />
                    ))
                }
            </div>
            {isInvalidState && isRequired && <p className="error-text">{FormPreviewConstants.RequiredValidation}</p>}
        </div>
    );
};

export default MultiChoiceQuestion;