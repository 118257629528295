import React, { useEffect } from "react";
import { FooterComponent } from "cp-common-ui-components";
import { isMobile } from "../../helper/HelperFunctions";
import { ClosedByFirmConstants } from "../../helper/Constants";
import "../link-lock/index.scss";

const ClosedPage: React.FC = () => {

    return (
        <div className="link-lock-page-continer invalid-page">
            <div className="header-placeholder"></div>
            <div className="link-lock-page">
                <div className="link-lock-text-container">
                    {ClosedByFirmConstants.ClosedByFirm}
                </div>
            </div>
            {!isMobile() && <FooterComponent />}
        </div>
    );
};

export default ClosedPage;