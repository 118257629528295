/* Contains all the routes of the application */
import React, { FC, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LoadingView from "common/components/LoadingScreen";
import { useAppSelector } from "common/hooks/redux-hooks";
import { AppState } from "store";
import OtpAuthPage from "pages/otp-auth";
import LandingPage from "../pages/landing-page";
import { LinkLockPage } from "pages/link-lock";
import CustomQuestions from "../pages/custom-questions";
import ESignComponent from "pages/esign";
import OrganizerComponent from "pages/organizer";
import DRLPage from "../pages/drl-page";
import { isMobile } from "../helper/HelperFunctions";
import ContactInformation from "../pages/common/ContactInformation";
import MyAccountMobile from "../pages/common/MyAccountMobile";
import { ERROR_PAGE, LINK_LOCKED, TAXPAYER } from "./paths";
import InvalidPage from "../pages/invalid-page";
import ClosedPage from "pages/closed-by-firm";
import IdleTimer from "common/components/UIComponents/IdleTimer";
import PreviewPage from "pages/preview";
import Loader from "common/components/UIComponents/Loader";
import AppLayout from "common/components/AppLayout";
import { InvalidPageConstants } from "helper/Constants";

const AppRoutes: React.FC = () => {
  const isLayoutLoading: boolean = useAppSelector((state: AppState) => state.layoutReducer?.isLoading);

  return (
    <Suspense fallback={<LoadingView />}>
      {isLayoutLoading ? (
        <LoadingView />
      ) : (
        <AppLayout>
          <Routes>
          <Route path={TAXPAYER.LANDING_PAGE + ":clientId"} element={<WrapperComponent component={<LandingPage />} includeIdleTimer={true}/>} />
          <Route path={LINK_LOCKED + ":clientId"} element={<LinkLockPage />} />
          <Route
            path={TAXPAYER.CUSTOM_QUESTIONS + ":clientId"}
            element={<WrapperComponent component={<CustomQuestions />} showInDesktopOnly={true} includeIdleTimer={true}/>}
          />
          <Route path={TAXPAYER.E_SIGN + ":clientId"} element={<WrapperComponent component={<ESignComponent />} includeIdleTimer={true}/>} />
          <Route
            path={TAXPAYER.ORGANIZER + ":clientId"}
            element={<WrapperComponent component={<OrganizerComponent />} showInDesktopOnly={true} includeIdleTimer={true}/>}
          />
          <Route path={TAXPAYER.UPLOAD_DOCUMENTS + ":clientId"} element={<WrapperComponent component={<DRLPage />} includeIdleTimer={true} />} />
          <Route
            path={TAXPAYER.CONTACT_INFORMATION + ":clientId"}
            element={<WrapperComponent component={<ContactInformation />} showInMobileOnly={true} includeIdleTimer={true}/>}
          />
          <Route
            path={TAXPAYER.MY_ACCOUNT + ":clientId"}
            element={<WrapperComponent component={<MyAccountMobile />} showInMobileOnly={true} includeIdleTimer={true}/>}
          />
          <Route path={TAXPAYER.Preview + ":clientId"} element={ <WrapperComponent component={<PreviewPage />} showInDesktopOnly={true} includeIdleTimer={true}/>} />
          <Route path={TAXPAYER.ClientView + ":clientId"} element={ <WrapperComponent component={<PreviewPage />} showInDesktopOnly={true} includeIdleTimer={true}/>} />
          <Route path="/" element={<InvalidPage />} />
          <Route path="/session-timeout" element={<InvalidPage message={InvalidPageConstants.SessionTimeOut} />} />
          <Route path={TAXPAYER.CLOSED + ":clientId"} element={<ClosedPage />} />
          <Route path="/:clientId" element={ <WrapperComponent component={<OtpAuthPage />} includeIdleTimer={false}/>} />
          <Route path={ERROR_PAGE + ":clientId"} element={<InvalidPage />} />
          <Route path="*" element={<InvalidPage />} />
        </Routes>
        </AppLayout>        
      )}
    </Suspense>
  );
};

const WrapperComponent:FC<{component:JSX.Element, showInMobileOnly?:boolean, showInDesktopOnly?:boolean, includeIdleTimer?: boolean}>=({component:Component,showInMobileOnly,showInDesktopOnly,includeIdleTimer}) =>{

  const isLoading =  useAppSelector((state: AppState) => state.loaderReducer?.isLoading || state.gatherStepsWithStatusReducer?.isLoading);

  if((showInMobileOnly && !isMobile()) 
  || (showInDesktopOnly && isMobile())
    ){
      return <Navigate to="/" />
  }

  return(<>
    {includeIdleTimer && <IdleTimer/>}
    {Component}
    {isLoading && <Loader />}
</>)
}

export default AppRoutes;

