import { StatusType } from "common/enum"
import { AuthResponse } from "common/model/authResponse"
import { Action } from "redux"
import { ThunkAction } from "redux-thunk"
import { API } from "services/api/api-config"
import Axios from "services/api/axios-interceptor"
import { AppState } from "store"
import { setNotification } from "store/slices/notification";
import { setIsLoading } from "store/slices/organizer"
import { IOrganizerSaveModel, IOrganizerSignModel, ReviewStatus } from "common/model/organizer"
import { setLoader } from "store/slices/loader"

export const getOrganizerData = (
    clientId: string,
    successCallback: (data: IOrganizerSignModel) => void,
    errorCallback?: () => void
): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
            dispatch(setIsLoading(true))
            Axios.get(`${API.ORGANIZER.GET_ORGANIZER_DOCUMENT}/${clientId}`)
                .then((response) => response)
                .then((data: IOrganizerSignModel) => {
                    const result: IOrganizerSignModel = data
                    if (result) {
                        dispatch(setIsLoading(false))
                        successCallback && successCallback(result);
                    }
                    else {
                        errorCallback && errorCallback();
                        return;
                    }
                })
        .catch ((error: any) => {
            dispatch(setNotification({
                type: StatusType.Error,
                message: "Error while fetching organizer data"
            }))
            errorCallback && errorCallback();
        })
    }

export const getUploadedSourceDocuments = (
    clientId: string,
    successCallback: (data: any) => void,
    errorCallback?: () => void
): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
            dispatch(setIsLoading(true))
            Axios.get(`${API.SOURCE_DOCUMENT.GET_SOURCE_DOCUMENTS_FOR_ORGANIZER}/${clientId}`)
                .then((response) => response)
                .then((data: any) => {
                    const result: any = data
                    if (result) {
                        dispatch(setIsLoading(false))
                        successCallback && successCallback(result);
                    }
                    else {
                        errorCallback && errorCallback();
                        return;
                    }
                })
        .catch ((error: any) => {
            dispatch(setNotification({
                type: StatusType.Error,
                message: "Error while fetching uploaded source documents for organizer"
            }))
            errorCallback && errorCallback();
        })
    }

export const saveOrganizer = (
    clientId: string,
    organizerFormData: IOrganizerSaveModel,
    successCallback: (data: any) => void,
    errorCallback?: () => void
): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
            dispatch(setLoader(true))

            Axios.post(`${API.ORGANIZER.SAVE_ORGANIZER_DOCUMENT}/${clientId}`, organizerFormData)
                .then((response) => response)
                .then((data) => {
                    const result: AuthResponse<any> = data

                    if (result) {
                        successCallback && successCallback(result);
                        setTimeout(()=>{
                            dispatch(setLoader(false))
                        },2000)
                    }
                    else {
                        errorCallback && errorCallback();
                        return;
                    }
                })
        .catch ((error: any) => {
            dispatch(setNotification({
                type: StatusType.Error,
                message: "Error while saving organizer document"
            }))
            errorCallback && errorCallback();
            dispatch(setLoader(false))
        })
    }

export const autoSaveOrganizer = (
    clientId: string,
    organizerFormData: IOrganizerSaveModel,
    successCallback: (data: any) => void,
    errorCallback?: () => void
): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
            dispatch(setLoader(true))
            Axios.post(`${API.ORGANIZER.AUTO_SAVE_ORGANIZER_DOCUMENT}/${clientId}`, organizerFormData)
                .then((response) => response)
                .then((data) => {
                    const result: AuthResponse<any> = data

                    if (result) {
                        successCallback && successCallback(result);
                        dispatch(setLoader(false))
                    }
                    else {
                        errorCallback && errorCallback();
                        dispatch(setLoader(false))
                        return;
                    }
                })
        .catch ((error: any) => {
            dispatch(setLoader(false))
            dispatch(setNotification({
                type: StatusType.Error,
                message: "Error while auto saving organizer document"
            }))
            errorCallback && errorCallback();
        })
    }

export const finishOrganizer = (
    clientId: string,
    organizerData: any,
    successCallback: (data: any) => void,
    errorCallback?: () => void
): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
            dispatch(setLoader(true))
            Axios.post(`${API.ORGANIZER.FINISH_ORGANIZER_DOCUMENT}/${clientId}`, organizerData)
                .then((response) => response)
                .then((data) => {
                    const result: AuthResponse<any> = data

                    if (result) {
                        successCallback && successCallback(result);
                        dispatch(setLoader(false))
                    }
                    else {
                        errorCallback && errorCallback();
                        dispatch(setLoader(false))
                        return;
                    }
                })
        .catch ((error: any) => {
            dispatch(setNotification({
                type: StatusType.Error,
                message: "Error while fetching organizer data"
            }))
            dispatch(setLoader(false))
            errorCallback && errorCallback();
        })
    }

export const sendOrganizerForReview = (
    clientId: string,
    successCallback: (data: any) => void,
    errorCallback?: () => void
): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
            dispatch(setLoader(true))
            Axios.post(`${API.ORGANIZER.SEND_ORGANIZER_FOR_REVIEW}/${clientId}`, {})
                .then((response) => response)
                .then((data) => {
                    const result: AuthResponse<any> = data

                    if (result) {
                        dispatch(setLoader(false))
                        successCallback && successCallback(result);
                    }
                    else {
                        errorCallback && errorCallback();
                        dispatch(setLoader(false))
                        return;
                    }
                })
        .catch ((error: any) => {
            dispatch(setNotification({
                type: StatusType.Error,
                message: "Error while sending organizer document for review."
            }))
            errorCallback && errorCallback();
            dispatch(setLoader(false))
        })
    }
 
export const getFormControlsJson = (
    clientId: string,
    successCallback: (data: any) => void,
    errorCallback?: () => void
): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
            dispatch(setIsLoading(true))
            Axios.get(`${API.ORGANIZER.GET_ORGANIZER_CONTROL_DATA}/${clientId}`, {
                method: 'GET',
                credentials: 'include'
            })
                .then((data) => {
                    const result: string = data
                    if (result) {
                        dispatch(setIsLoading(false))
                        getFormControlsJsonFromUrl(result).then((jsonData) => {
                            successCallback && successCallback(jsonData);
                        });
                    }
                    else {
                        errorCallback && errorCallback();
                        return;
                    }
                })
        .catch ((error: any) => {
            dispatch(setNotification({
                type: StatusType.Error,
                message: "Error while fetching organizer controls blob url."
            }))
            errorCallback && errorCallback();
        })
    }

export const getFormControlsJsonFromUrl = (url: string): Promise<any> => {
    return new Promise(function (resolve, reject) {

        fetch(url).
            then(data => data.json())
            .then(function (data) {
                resolve(data);

            }).catch(function (error) {
                reject(error);
            });

    });
}
export const saveTaxPayerNotes = (
    clientId: string,
    formId: number,
    notes: string,
    pageNumber: number,
    successCallback: (data: any) => void,
    errorCallback?: () => void
): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
            dispatch(setIsLoading(true))
            Axios.post(`${API.ORGANIZER.SAVE_ORGANIZER_TAXPAYER_NOTES}/${clientId}`, {
                formId: formId,
                message: notes,
                pageNo: pageNumber
            })
                .then((response) => response)
                .then((data) => {
                    const result: AuthResponse<any> = data

                    if (result) {
                        dispatch(setIsLoading(false))
                        successCallback && successCallback(result);
                    }
                    else {
                        errorCallback && errorCallback();
                        return;
                    }
                })
        .catch ((error: any) => {
            dispatch(setNotification({
                type: StatusType.Error,
                message: "Error while Saving Organizer TaxPayer Notes."
            }))
            errorCallback && errorCallback();
        })
    }

export const deleteTaxPayerNotes = (
    clientId: string,
    formId: number,
    successCallback: (data: any) => void,
    errorCallback?: () => void
): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
            dispatch(setIsLoading(true))
            Axios.delete(`${API.ORGANIZER.DELETE_ORGANIZER_TAXPAYER_NOTES}/${clientId}`, {
                params: {
                    formId: formId
                }
            })
                .then((response) => response)
                .then((data) => {
                    const result: AuthResponse<any> = data

                    if (result) {
                        dispatch(setIsLoading(false))
                        successCallback && successCallback(result);
                    }
                    else {
                        errorCallback && errorCallback();
                        return;
                    }
                })
        .catch ((error: any) => {
            dispatch(setNotification({
                type: StatusType.Error,
                message: "Error while Deleting Organizer TaxPayer Notes."
            }))
            errorCallback && errorCallback();
        })
    }

export const reviewOrganizer = (
    clientId: string,
    reviewStatus: ReviewStatus,
    successCallback?: () => void,
    errorCallback?: () => void
): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
            dispatch(setLoader(true));
            let apiEndPoint = `${API.ORGANIZER.REVIEW_ORGANIZER_DOCUMENT}/${clientId}?reviewStatus=${reviewStatus}`;
            Axios.post(apiEndPoint, {
               method :'POST',
               credentials : 'include'
            })
                .then((response) => response)
                .then((data) => {
                    const result: AuthResponse<any> = data

                    if (result) {
                        dispatch(setLoader(false))
                        successCallback && successCallback();
                    }
                    else {
                        errorCallback && errorCallback();
                        dispatch(setLoader(false))
                        return;
                    }
                })
        .catch ((error: any) => {
            dispatch(setNotification({
                type: StatusType.Error,
                message: "Error while sending review of organizer document."
            }))
            errorCallback && errorCallback();
        })
};
