import { FC, useCallback, useEffect, useState } from 'react';
import Accordian from 'common/components/accordian/Accordian';
import { AccordianItemType, Bookmarks, GroupType } from 'common/enum/index';
import { OrganizerBookmarks } from 'common/enum/index';
import { AddCircleSmallIcon, BookMarkAttachIcon, BookMarkIcon, BookMarkSavedNotesIcon, FileEarMarkIcon, FolderBookMarkIcon, RestoreIcon, SignatureRequiredIcon } from 'assets/svg/SVGIconsCollection';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

interface BookmarkPanelContentProps {
    expanded?: boolean;
    toggleExpand?: () => void;
    bookmarks?: OrganizerBookmarks | Bookmarks;
    deletedPages?: any;
    onPageSelect: (pageNo: number) => void;
    handleAddDocumentClick?: () => void;
    isBatchEsign: boolean;
    isReadOnlyMode?: boolean;
    additionalDocuments?: any[];
    accordianItems?: any;
    formType: GroupType;
    isSourceDocumentSettingEnabled: boolean;
}

enum BookmarkType {
    All = 0,
    Notes = 1,
    SourceDocument = 2,
}

const BookmarkPanelContent: FC<BookmarkPanelContentProps> = ({
    expanded,
    accordianItems,
    toggleExpand,
    bookmarks,
    deletedPages,
    onPageSelect,
    handleAddDocumentClick,
    isBatchEsign,
    formType,
    additionalDocuments,
    isReadOnlyMode,
    isSourceDocumentSettingEnabled }) => {
    const [accordianTabs, setAccordianTabs] = useState<any>([]);
    const [selectedFilter, setSelectedFilter] = useState<number>(0);

    const organizerFilterOptions = [
        { value: BookmarkType.Notes, label: 'Notes' },
        { value: BookmarkType.SourceDocument, label: 'Source Document' }
    ];

    const GetIcon = (sourceDocumentEnabled: boolean,
        message: string,
        isBatchEsign?: boolean) => {

        if (sourceDocumentEnabled && isSourceDocumentSettingEnabled) {
            return <BookMarkAttachIcon />

        }
        if (message && message.length > 0) {
            return <BookMarkSavedNotesIcon />
        }
        else {
            return <></>
        }
    }
    const getAccordionItems = useCallback((bookmarkPages: any[]): any[] => {
        let items: any[] = [];
        if (accordianItems?.length > 0) {
            accordianItems.forEach((item: any): any => {
                switch (item.type) {
                    case AccordianItemType.Bookmark:
                        items.push({
                            title: item.caption,
                            content: (<div>
                                {bookmarkPages && bookmarkPages?.map((item: any, index: number) => {
                                    return <div
                                        key={index}
                                        className="bookmark-row"
                                        title={item.pageTitle}
                                        onClick={() => onPageSelect(item.pageNo)}>
                                        <div className="bookmark-item" id={`page-${index}`}>
                                            <BookMarkIcon className="bookmark-icon" />
                                            <label>
                                                {item.pageTitle}
                                            </label>
                                        </div>
                                        {GetIcon(item.enabledSourceDocument, item.message)}
                                    </div>
                                })}
                            </div>
                            )
                        });
                        break;
                    case AccordianItemType.EngagementLetter:
                        items.push({
                            title: <span><FolderBookMarkIcon /> <span style={{ marginLeft: "10px" }}>{item.caption}</span></span>,
                            headerClassName: 'engagement-letter-accordion-header',
                            itemClassName: 'engagement-letter-accordion-item',
                            content: <div>
                                {bookmarks?.pages?.map((item: any, index: number) => {
                                    return <div
                                        key={index}
                                        className="bookmark-row engagement-letter-bookmark-row"
                                        onClick={() => onPageSelect(item.pageNo)}>
                                        <div className="bookmark-item" id={`page-${index}`}>
                                            <FileEarMarkIcon />
                                            <label>
                                                {item.pageTitle}
                                            </label>
                                        </div>
                                    </div>
                                })}
                            </div>
                        })
                        break;
                    case AccordianItemType.AdditionalDocuments:
                        items.push({
                            title: <span><FolderBookMarkIcon /> <span style={{ marginLeft: "10px" }}>{item.caption}</span></span>,
                            headerClassName: 'engagement-letter-accordion-header',
                            itemClassName: 'engagement-letter-accordion-item',
                            content: <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {additionalDocuments?.map((item: any, index: number) => {
                                    return <div
                                        key={index}
                                        className="bookmark-row engagement-letter-bookmark-row"
                                        onClick={() => onPageSelect(item.pageNo)}>
                                        <div className="bookmark-item" id={`page-${index}`}>
                                            <div style={{ width: '35px' }}></div>
                                            <label>
                                                {item.pageTitle}
                                            </label>
                                        </div>
                                    </div>
                                })}


                            </div>
                        })
                        break;
                    case AccordianItemType.ConsentForm:
                        items.push({
                            title: item.caption,
                            content: <div>
                                {[].map((item: any, index: number) => {
                                    return <div
                                        key={index}
                                        className="bookmark-row"
                                        onClick={() => onPageSelect(item.pageNo)}>
                                        <div className="bookmark-item" id={`page-${index}`}>
                                            <label>
                                                {item.pageTitle}
                                            </label>
                                        </div>

                                    </div>
                                })}
                            </div>
                        })
                        break;
                    case AccordianItemType.Deleted:
                        items.push({
                            title: item.caption,
                            content: <div>
                                {deletedPages?.map((item: any, index: number) => {
                                    return <div
                                        key={index}
                                        className="bookmark-row"
                                        onClick={() => onPageSelect(item.pageNo)}>
                                        <div className="bookmark-item" id={`page-${index}`}>
                                            <label>
                                                {item.title}
                                            </label>
                                        </div>
                                        <RestoreIcon />

                                    </div>
                                })}
                            </div>
                        });
                        break;
                    default:
                        break;
                }
            });
        }
        return items;
    }, [accordianItems, bookmarks, additionalDocuments, isBatchEsign, onPageSelect]);

    useEffect(() => {
        if (bookmarks && bookmarks.pages) {
            let bookmarkPages = bookmarks.pages;
            if (formType === GroupType.Organizer) {
                if (selectedFilter === BookmarkType.Notes) {
                    bookmarkPages = bookmarks.pages.filter((page: any) => page.message);
                } else if (selectedFilter === BookmarkType.SourceDocument) {
                    bookmarkPages = bookmarks.pages.filter((page: any) => page.enabledSourceDocument);
                } else {
                    bookmarkPages = bookmarks.pages;
                }
            }
            setAccordianTabs(getAccordionItems(bookmarkPages));
        }
    }, [bookmarks, selectedFilter, getAccordionItems, formType])



    const handleOnFilterChange = (selectedFilter: number) => {
        setSelectedFilter(selectedFilter);
    };

    return (<>
        <Form.Group className='form-group'>
            <Form.Label className='form-label'>{"Filter By"}</Form.Label>
            <Select
                placeholder={"Show All"}
                value={organizerFilterOptions && organizerFilterOptions.find((option: any) => option.value === selectedFilter)}
                options={organizerFilterOptions}
                onChange={(option: any) => handleOnFilterChange(option && option.value)}
            ></Select>
        </Form.Group>
        <Accordian flush
            className='accordian-container'
            accordianItems={accordianTabs}
            isAlwaysOpen={false} />
    </>
    );
}
export default BookmarkPanelContent;
