import { FC } from "react";
import { DeleteIcon, EditIcon, PreviewIcon } from "../../assets/svg/SVGIconsCollection";
import { GatherUploadedDocuments, PreviewConstants } from "../../helper/Constants";

interface PreviewOrClientViewModeProps {
    isHoveredIconsVisible: boolean,
    document: IDocument;
    category: ICategory;
    onPreviewButtonClick: any
    onEditButtonClick: any
    onDeleteButtonClick: (id: number) => void;
    isPreviewOrClientViewMode: boolean;
    isStepCompleted: boolean;
}

interface IDocument {
    id: number;
    name: string;
    isMapped: boolean;
    documentId?: number;
    type?: string;
    fileName?: string;
}

interface ICategory {
    categoryId: number;
    categoryName: string;
}

const PreviewOrClientViewMode: FC<PreviewOrClientViewModeProps> = ({ isHoveredIconsVisible, document, category, onPreviewButtonClick, onEditButtonClick, onDeleteButtonClick, isPreviewOrClientViewMode, isStepCompleted }) => {

    return <>
        {isHoveredIconsVisible && <div className="list-item-actions">
            <button title={isPreviewOrClientViewMode ? PreviewConstants.PreviewNotAllowed : GatherUploadedDocuments.ClickToPreview}
                onClick={() => onPreviewButtonClick(document, category.categoryName)}
                disabled={isPreviewOrClientViewMode}>
                <PreviewIcon />
            </button>
            <button title={isPreviewOrClientViewMode ? PreviewConstants.EditNotAllowed : GatherUploadedDocuments.ClickToEdit}
                onClick={() => onEditButtonClick(document, category.categoryId)}
                disabled={isStepCompleted || isPreviewOrClientViewMode}>
                <EditIcon />
            </button>
            <button title={isPreviewOrClientViewMode ? PreviewConstants.DeleteNotAllowed : GatherUploadedDocuments.ClickToDelete}
                onClick={() => onDeleteButtonClick(document.documentId ?? 0)}
                disabled={isStepCompleted || isPreviewOrClientViewMode}>
                <DeleteIcon />
            </button>
        </div>
        }
    </>
}
export default PreviewOrClientViewMode;