import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { BackIconMobile } from "../../../assets/svg/SVGIconsCollection";
import { useAppSelector } from "common/hooks/redux-hooks";
import { AppState } from "store";
import companyImage from 'assets/images/GtrLogo.png'
import "./header.scss";


const MobileHeader: FC = () => {
    const navigate = useNavigate();

    const icon = useAppSelector((state: AppState) => state.coverPageReducer?.companyLogoPath)
    
    return <div className="mobile-header">
        <div onClick={() => navigate(-1)}>
            <BackIconMobile />
        </div>
        <div className="logo-container">     
            <img src={icon} alt="company-logo" />
        </div>
        <div className="placeholderbox" />
    </div>
};

export default MobileHeader;