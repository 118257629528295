import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GatherMetadata } from "common/model/organizer";

interface Metadata {
    gatherMetadata: GatherMetadata;
}

const initialData: Metadata = {
    gatherMetadata: GatherMetadata?.createNullObject()
}

const gatherMetadataSlice = createSlice({
    name: 'metadata',
    initialState: initialData,
    reducers: {
        setGatherMetadata(state, action: PayloadAction<GatherMetadata>) {
            state.gatherMetadata = action.payload;
        },
    }
});

export const { setGatherMetadata } = gatherMetadataSlice.actions;
export default gatherMetadataSlice.reducer;