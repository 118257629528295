import { ChoosableControlData, Control, ControlType, Form, FormGroup } from "common/enum";
import { IElDocument } from "common/model/document";
import { SignatureControlConstants } from "./Constants";
import { CheckBox, ControlBaseProps, ControlData, RadioButton, RadioButtonGroupData, TextBoxData } from "awesome-pdf-viewer/dist/Controls/ControlBase";
import Enumerable from "linq";
import { CheckBoxControl, ChoosableControlResult, ControlValue, CustomTextControl, IControl, RadioButtonControl, SignatureData, TextboxData, TextData } from "common/model/controls";
import { getCurrentDate } from "./DateUtilities";
import { EngagementLetterFormData, IESignModel, ValueFormData } from "common/model/esign";
import { UploadMethod } from "common/model/organizer";

export class esignHelper {

    public static getServerModel(controls: any[], organizerDocumentModel: IESignModel, isAdditionalDocument: boolean = false): IESignModel {

        if ((organizerDocumentModel).uploadMethod === UploadMethod.Batch && !isAdditionalDocument) {

            (organizerDocumentModel).formGroups && (organizerDocumentModel).formGroups.forEach(function (formGroup: FormGroup) {
                formGroup.forms.forEach(function (form: Form) {

                    const formData: EngagementLetterFormData = form.formData as EngagementLetterFormData;

                    const tmpBatchFormData: ValueFormData = ValueFormData.createNullObject();

                    formData && formData.controlList && formData.controlList.forEach(function (control: Control) {

                        const tmpControl = controls.find(x => x && x.props.id === SignatureControlConstants.ControlIdPrefix + control.id);

                        if (tmpControl) {

                            switch (control.controlType) {

                                case ControlType.Textbox: // TextBox

                                    tmpBatchFormData.controlValues.push(ControlValue.create(form.formGuid,
                                        control.id,
                                        tmpControl.getControlData().text));

                                    break;

                                case ControlType.Date: // Date

                                    tmpBatchFormData.controlValues.push(ControlValue.create(form.formGuid,
                                        control.id,
                                        getCurrentDate().toString()));

                                    break;

                                case ControlType.Signature: // Signature

                                    const controlData = tmpControl.getControlData();


                                    if (controlData) {
                                        const signatureData: SignatureData = SignatureData.create(controlData.name, controlData.signature, controlData.signatureMode);

                                        tmpBatchFormData.controlValues.push(ControlValue.create(form.formGuid,
                                            control.id,
                                            JSON.stringify(signatureData)));
                                    }
                                    break;
                                case ControlType.SignatureCheckBoxControl:
                                    const checkBoxControlData = tmpControl.getControlData();
                                    if(checkBoxControlData){
                                        var choosableControlsData=checkBoxControlData.items?.map((x:any)=>{
                                            return ChoosableControlData.create(x.id,
                                                x.name,
                                                x.top,
                                                x.left,
                                                x.width,
                                                x.height,
                                                x.value
                                            )
                                        })

                                        const checkBoxGroupData = CheckBoxControl.create(checkBoxControlData.id,
                                            ControlType.SignatureCheckBoxControl,
                                            checkBoxControlData.name,
                                            checkBoxControlData.readOnly,
                                            checkBoxControlData.tooltip,
                                            checkBoxControlData.required,
                                            choosableControlsData,
                                            undefined,
                                            undefined,
                                            checkBoxControlData.disabled
                                        )

                                        tmpBatchFormData.controlValues.push(ControlValue.create(form.formGuid,
                                            control.id,
                                            JSON.stringify(checkBoxGroupData)
                                        ))                                        
                                    }
                                    break;
                                case ControlType.SignatureRadioButtonControl:
                                    const radiobuttonControlData = tmpControl.getControlData()

                                    if(radiobuttonControlData){
                                        var radioButtonChoosableControlsData=radiobuttonControlData.items?.map((x:any)=>{
                                            return ChoosableControlData.create(x.id,
                                                x.name,
                                                x.top,
                                                x.left,
                                                x.width,
                                                x.height,
                                                x.value
                                            )
                                        })

                                        const radioButtonGroupData = CheckBoxControl.create(radiobuttonControlData.id,
                                            ControlType.SignatureRadioButtonControl,
                                            radiobuttonControlData.name,
                                            radiobuttonControlData.readOnly,
                                            radiobuttonControlData.tooltip,
                                            radiobuttonControlData.required,
                                            radioButtonChoosableControlsData,
                                            undefined,
                                            undefined,
                                            radiobuttonControlData.disabled
                                        )

                                        tmpBatchFormData.controlValues.push(ControlValue.create(form.formGuid,
                                            control.id,
                                            JSON.stringify(radioButtonGroupData)
                                        ))                                        
                                        console.log(radioButtonGroupData);
                                    }

                                    
                                    break;
                                default:
                                     throw new Error("Selected control not implemented !!");
                            }
                        }
                    });
                    form.formData = tmpBatchFormData;
                });
            });
        }
        else { // perfoma,blank

            (organizerDocumentModel).formGroups?.forEach(function ({ forms }: FormGroup) {

               forms.forEach(function (form: Form) {

                   const formData: EngagementLetterFormData = form.formData as EngagementLetterFormData;

                   if (formData && formData.controlList) {

                       formData.controlList.forEach(function (control: Control) {

                           const uiControl = controls.find(x => x && x.props.id == SignatureControlConstants.ControlIdPrefix + control.id);

                           if (uiControl) {
                               control = esignHelper.getControlData(control, uiControl);
                           }
                       });
                   }
                });
            });
        }

        return organizerDocumentModel;
    }

    private static getControlData = (actualControl: any, uiControl: any): any => {
        switch (actualControl.controlType) {

            case ControlType.Textbox: // TextBox

                actualControl.controlData = new TextboxData(uiControl.getControlData().text);

                break;

            case ControlType.Date: // Date

                actualControl.controlData = { value: getCurrentDate() }

                break;

            case ControlType.Signature: // Signature

                const controlData = uiControl.getControlData();
                if (controlData) {
                    actualControl.controlData = SignatureData.create(controlData.name, controlData.signature, controlData.signatureMode);
                }

                break;

            case ControlType.SignatureCheckBoxControl: //Checkbox

                const controlCheckData = uiControl.getControlData();

                actualControl.items.forEach((item: any) => {
                    item.value.checked = controlCheckData.items.find((x: any) => x.id == item.id).value.checked;
                })

                break;

            case ControlType.SignatureRadioButtonControl: // Radio button

                const controlRadioData = uiControl.getControlData();

                actualControl.items.forEach((item: any) => {
                    item.value.checked = controlRadioData.items.find((x: any) => x.id == item.id).value.checked;
                })
                break;

            default:
                throw new Error("Selected control not implemented !!");
        }
        return actualControl;
    }

    public static getAdditionalEsignServerModel(controls: any[], organizerDocumentModel: IESignModel): IESignModel {

        (organizerDocumentModel).additionalESignDocuments?.forEach((document: any) => {
            document.documentControls.formData.forEach((form: any) => {

                form.controlList.forEach((control: any) => {
                    const uiControl = controls.find(x => x && x.props.id == SignatureControlConstants.ControlIdPrefix + control.id);
                    if (uiControl) {
                        control = esignHelper.getControlData(control, uiControl);
                    }
                });
            });
        });
        return organizerDocumentModel;
    }

    public static updateControlData(documents: IElDocument[], controlData: ControlData, controlProps: ControlBaseProps): boolean {
        let signingCompleted: boolean = false;
        try {
            let total: number = 0;
            let controlsWithValue: number = 0;

            for (var i = 0; i <= documents.length - 1; i++) { // doument loop

                const pages = documents[i].forms;

                for (var j = 0; j <= pages.length - 1; j++) { // document pages loop

                    const controls = pages[j].controls;

                    if (controls && controls.length > 0) {
                        for (var k = 0; k <= controls.length - 1; k++) { // page controls loop

                            const control = controls[k];

                            this.update(control, controlData, controlProps);

                        }

                        const totalRequiredControls = Enumerable.from(controls).count((x: any) => x.required == true && x.controlType != ControlType.Date);
                        total = total + totalRequiredControls;

                        const tmpControlsWithValues = Enumerable.from(controls).count((x: any) => x.required == true &&
                            x.data &&
                            ((x.data["text"] && x.data["text"].trim() !== '') ||
                                (x.data["uri"]) || x.data["signature"] ||
                                (x.data["checked"] && x.data["checked"] === true) ||
                                (this.controlDataExist(x.data) === true)));

                        controlsWithValue = controlsWithValue + tmpControlsWithValues;

                        pages[j].allControlSigned = totalRequiredControls === tmpControlsWithValues;
                    }
                    else if (controls.length === 0) {
                        pages[j].allControlSigned = true;
                    }
                }
                const totalAllSignedPages = Enumerable.from(documents[i].forms).count(x => x.allControlSigned == true);
                documents[i].signCompleted = documents[i].forms.length === totalAllSignedPages;
            }

            signingCompleted = controlsWithValue === total;
        }
        catch (error) {
            console.log(error);
        }

        return signingCompleted;
    }

    private static controlDataExist(controlData: any[]): boolean {
        if (controlData && controlData.length > 0) {
            return controlData.filter(x => x.checked === true).length > 0;
        }
        return false;
    }

    public static update(control: IControl, controlData: ControlData, controlProps: ControlBaseProps) {

        if (controlProps.data["controlType"] === 1 || (controlProps.data["controlType"] === 2)
        ) {
            if (control.id === controlProps.data["controlId"] || (control.controlType === ControlType.Signature && control.data !== undefined)) { // add signature to the particluar control or update already signed controls
                const tmpSignatureData = SignatureData.create((controlData as SignatureData).name, (controlData as any).signature, (controlData as SignatureData).signatureMode);
                control.data = tmpSignatureData;
            }
        }
        else if (controlProps.data["controlType"] === 11) {
            if (control.id === controlProps.data.controlId) {
                let tempControl = control as CheckBoxControl;
                let tmpCheckBoxData: ChoosableControlResult[] = [];
                let checkBoxData = controlData as CheckBox[];
                tempControl.items.forEach(function (checkbox: CheckBox) {
                    const index = checkBoxData.findIndex(x => x.id === checkbox.id);
                    if (index !== -1) {
                        tmpCheckBoxData.push(ChoosableControlResult.create(checkbox.id, checkBoxData[index].value.checked));
                    }
                });
                control.data = tmpCheckBoxData;
            }
        }
        else if (controlProps.data["controlType"] === 12) {
            if (control.id === controlProps.data.controlId) {
                let tempControl = control as RadioButtonControl;
                let tmpRadioButtonData: ChoosableControlResult = ChoosableControlResult.create('', false);
                let radiobuttonData = controlData as RadioButton[];
                tempControl.items.forEach(function (radio: RadioButton) {
                    const index = radiobuttonData.findIndex(x => x.id === radio.id);
                    if (index !== -1 && radiobuttonData[index].value.checked) {
                        tmpRadioButtonData = ChoosableControlResult.create(radio.id, true);
                    }
                });
                control.data = tmpRadioButtonData;
            }
        }
        else if (control.id === controlProps.data["controlId"]) {
            if (controlProps.data["controlType"] === 6
                || controlProps.data["controlType"] === 7
                || (controlProps.data["controlType"] === 8)
                || (controlProps.data["controlType"] === 9)) {
                const tmpTextData: TextBoxData = TextData.create((controlData as TextBoxData).text || "");
                control.data = tmpTextData;
            }
        }

        else if ((control instanceof CustomTextControl)) {

            if (control.id === controlProps.data["controlId"]) {

                const tmpTextData: TextBoxData = TextData.create((controlData as TextBoxData).text || "");
                control.data = tmpTextData;

            }
        }
    }
}
