import React, { useEffect, useState } from "react";
import { ICategory, IDocumentRequestName } from "../../common/model/document-request-model";
import DocumentMappingProgressBar from "./DocumentMappingProgressBar";
import { useAppSelector } from "../../common/hooks/redux-hooks";
import { isMobile } from "../../helper/HelperFunctions";
import useOtherCategoryId from "../../common/hooks/useOtherCategoryId";
import { GatherUploadedDocuments } from "../../helper/Constants";

export interface DocumentRequestUploadProgressbarProps {
  onEmptySDStateChange: (isEmpty: boolean) => void;
}
export const DocumentRequestUploadProgressbar: React.FC<DocumentRequestUploadProgressbarProps> = (props) => {
  const { onEmptySDStateChange } = props;
  const { documentRequestList, documentRequestNames } = useAppSelector((state) => state.documentRequestReducer);
  const totalDocumentRequest: number =
    documentRequestNames?.filter(
      (item: IDocumentRequestName) =>
        !!item.id &&
        item.type !== GatherUploadedDocuments.NotAvailableFormType &&
        item.categoryName !== GatherUploadedDocuments.OtherCategoryName
    ).length || 0;
  const totalDRLsMarkedAsNA: number =
    documentRequestNames?.filter((item: IDocumentRequestName) => item.notApplicable).length || 0;
  const otherCategoryId = useOtherCategoryId();

  const [isEmptySD, setIsEmptySD] = useState<boolean>(false);

  useEffect(() => {
    onEmptySDStateChange(isEmptySD);
  }, [isEmptySD, onEmptySDStateChange]);

  const getMappedDocumentRequest = (): number => {
    const documentRequestListWithoutOtherOption = documentRequestList.filter(
      (list: ICategory) => list.categoryId !== otherCategoryId
    );
    return (
      documentRequestListWithoutOtherOption.reduce((total: number, value: ICategory) => {
        return total + value.documents.filter((item) => item.isMapped).length;
      }, 0) + totalDRLsMarkedAsNA
    );
  };

  const getOtherCategoryDocuments = (): number => {
    const drlWithOtherCategory = documentRequestList.filter((list: ICategory) => list.categoryId === otherCategoryId);
    return drlWithOtherCategory.reduce((totalCategory: number, value: ICategory) => {
      return totalCategory + value.documents.filter((item) => item.isMapped).length;
    }, 0);
  };

  const getTotalUncategorizedDocuments = () => {
    return documentRequestList.reduce((total: number, category: ICategory) => {
      if (category.categoryId === null) {
        total += category?.documents.length;
      }
      if (category.categoryId !== null) {
        total += category?.unRecognizedDocuments.length;
      }
      if (category.categoryId === otherCategoryId) {
        total += category?.documents.length;
      }
      return total;
    }, 0);
  };

  const getTotalNumberOfDocuments = (): number => {
    const mappedDocuments = getMappedDocumentRequest();
    const uncategorizedDocuments = getTotalUncategorizedDocuments();
    const mappedToOther = getOtherCategoryDocuments();
    return mappedDocuments + uncategorizedDocuments + mappedToOther;
  };

  useEffect(() => {
    const totalNumberOfDocuments = getTotalNumberOfDocuments();
    if (totalNumberOfDocuments > 0) {
      setIsEmptySD(false);
    } else {
      setIsEmptySD(true);
    }
  });

  const getProgressPercentage = () => {
    let percentage = 0;
    if (totalDocumentRequest !== 0) {
      percentage = Math.round((getMappedDocumentRequest() / totalDocumentRequest) * 100);
      if (percentage < 1 && percentage > 0) {
        return 1;
      } else if (percentage > 99 && percentage < 100) {
        return 99;
      }
    }
    return percentage;
  };

  return (
    <div className="progress-bar-container">
      {!isMobile() && (
        <>
          <div className="count-box">
            <label className="count">{totalDocumentRequest}</label>
            <div>Requested Documents</div>
          </div>
          <div className="count-box">
            <label className="count">{getMappedDocumentRequest()}</label>
            <div>Uploaded Documents</div>
          </div>
          <div className="count-box">
            <label className="count">{getTotalUncategorizedDocuments()}</label>
            <div>Additional Documents</div>
          </div>
        </>
      )}
      <DocumentMappingProgressBar
        progressBarSize={isMobile() ? 64 : 100}
        strokeWidth={5}
        uploadedDocumentRequested={getMappedDocumentRequest()}
        totalDocumentRequested={totalDocumentRequest}
      />
      {isMobile() && (
        <div>
          <div className="document-title">{getProgressPercentage()}% Documents In Progress</div>
          <div className="d-flex document-description">
            <div>
              <span>{totalDocumentRequest}</span> Requested
            </div>
            <div>
              <span>{getMappedDocumentRequest()}</span> Uploaded
            </div>
            <div>
              <span>{getTotalUncategorizedDocuments()}</span> Additional
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentRequestUploadProgressbar;
