import React, { FC, useEffect, useRef, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { ButtonComponent } from "cp-common-ui-components";
import { getCategoryList, getDocumentRequestList, updateDocumentType } from "../../../store/services/documentRequestList";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { ICategory, IDocumentRequest, IDocumentRequestName } from "../../model/document-request-model";
import { Toaster } from '../../../common/components/Toasts';
import useOtherCategoryId from "../../hooks/useOtherCategoryId";
import { useClientId } from "../../hooks/client-hook";
import { GatherUploadedDocuments } from "helper/Constants";

interface IEditDocuemntModalProps {
    show: boolean;
    onHide: () => void;
    selectedDocument: any;
}

const EditDocuemntModal: FC<IEditDocuemntModalProps> = ({ show, onHide, selectedDocument }) => {
    const [enableOthers, setEnableOthers] = useState<boolean>(false);
    const [selectedType, setSelectedType] = useState<number>(0);
    const [customLabel, setCustomLabel] = useState<string>("");
    const labelInputRef = useRef<HTMLInputElement>(null);
    const { documentRequestNames, documentRequestList } = useAppSelector(state => state.documentRequestReducer);
    const otherCategoryId = useOtherCategoryId();
    const dispatch = useAppDispatch();
    const [clientId] = useClientId();
    const getFileName = () => {
        const category = documentRequestList.find((list: ICategory) => list.categoryId === selectedDocument?.categoryId);
        return category?.documents.find((file: IDocumentRequest) => file.documentId === selectedDocument.documentId)?.fileName ?? "";
    };

    const handleSubmit = () => {
        const payload = {
            documentId: selectedDocument.documentId,
            name: enableOthers ? customLabel || "" : selectedDocument.name,
            gatherDocumentRequestId: enableOthers ? selectedDocument.id : selectedType,
            isOtherOptionEnabled: enableOthers,
            categoryId: enableOthers ? otherCategoryId : selectedDocument.categoryId
        }
        onHide();
        dispatch(updateDocumentType(
            clientId,
            payload,
            () => {
                Toaster.success(GatherUploadedDocuments.DocumentRequestTypeUpdated);
                dispatch(getCategoryList(clientId));
                dispatch(getDocumentRequestList(clientId));
                setEnableOthers(false);
                setCustomLabel("");
            },
            (errorDescription) => { Toaster.warn(errorDescription); }
        ))
    };

    const handleTypeChange = (id: number) => {
        setSelectedType(id);
        setEnableOthers(false);
        setCustomLabel("");
    };

    useEffect(() => {
        selectedDocument && setSelectedType(selectedDocument.id);
    }, [selectedDocument]);

    return <Modal className="edit-document-modal white-modal" show={show} onHide={onHide} >
        <Modal.Header closeButton>
            <Modal.Title>Edit Document Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <div className='file-name ellipsis' title={getFileName()}>{getFileName()}</div>
                {documentRequestNames.map((file: IDocumentRequestName, index: number) => {
                    return <div key={index}>
                        <Form.Check type="radio" name="file-type-group" checked={file.id === selectedType} label={file.name} title={file.name} onChange={() => handleTypeChange(file.id)} />
                    </div>;
                })}
                <div className="title">Uncategorized</div>
                <div className="d-flex margin-top-10-px">
                    <Form.Check type="radio"
                        name="file-type-group"
                        label="Other"
                        onChange={(e) => {
                            setEnableOthers(e.target.checked)
                            setSelectedType(0);
                        }
                        } />
                    <Form.Control value={customLabel} onChange={(e) => setCustomLabel(e.target.value)} disabled={!enableOthers} className="other-label margin-left-10-px" size="sm" />
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <ButtonComponent variant="outline-tertiary" onClick={onHide}>Cancel</ButtonComponent>
            <ButtonComponent disabled={!(!!enableOthers && !!customLabel) && !selectedType} onClick={handleSubmit}>Update</ButtonComponent>
        </Modal.Footer>
    </Modal>
};

export default EditDocuemntModal;