import { BlobForm, ClientType, ControlType, ControlValue, DataType, Form, FormType, GroupType, OrganizerFormData } from "common/enum/index";
import { BookmarkPage, Bookmarks, Control, DateControl, FormGroup, OrganizerBookmarkPage, OrganizerBookmarks, OrganizerForm, QuestionControl, SectionControl, Signature, TextPlacement, Textbox } from "common/enum/index";
import ControlBase from "awesome-pdf-viewer/dist/Controls/ControlBase";
import { Guid } from "./guid";
import { HelpTextConstant, SignatureControlConstants } from "../../../../helper/Constants";
import moment from "moment";

export const getBookmarks = (formGroups: FormGroup[], type: GroupType, heading: string): Bookmarks => {
    const index = formGroups?.findIndex(formGroup => formGroup.type === type);
    if (index === -1) {
        return {
            heading: "",
            pages: []
        }
    }
    const bookmarkPages = formGroups && formGroups[index].forms.map(form => {
        return {
            pageNo: form.pageNo,
            pageTitle: form.bookmark
        } as BookmarkPage
    });

    return {
        heading: heading,
        pages: bookmarkPages
    }
}

export const getEsignBookmarks = (formGroups: FormGroup[], heading: string): Bookmarks => {
    const index = formGroups?.findIndex(formGroup => formGroup.type === GroupType.Engagement);
    if (index === -1) {
        return {
            heading: "",
            pages: []
        }
    }
    const bookmarkPages = [];
    if (formGroups) {
        bookmarkPages.push({
            pageNo: formGroups[index]?.forms[0]?.pageNo,
            pageTitle: formGroups[index]?.forms[0]?.bookmark
        } as BookmarkPage);
    }

    return {
        heading: heading,
        pages: bookmarkPages
    }
}

export const getOrganizerBookmarks = (formGroups: FormGroup[], heading: string): OrganizerBookmarks => {
    let bookmarks: OrganizerBookmarkPage[] = []
    const organizerBookmarks = getOrganizerBookmarksByType(formGroups, GroupType.Organizer);
    const organizerWithSignBookmarks = getOrganizerBookmarksByType(formGroups, GroupType.OrganizerWithSignature);
    bookmarks = Array.isArray(organizerBookmarks) && Array.isArray(organizerWithSignBookmarks) ?
        organizerBookmarks.concat(organizerWithSignBookmarks) : organizerBookmarks ?? [];
    bookmarks.sort((a: OrganizerBookmarkPage, b: OrganizerBookmarkPage) => {
        return a.pageNo - b.pageNo;
    })
    return bookmarks.length == 0 ? {
        heading: "",
        pages: []
    } : {
        heading: heading,
        pages: bookmarks
    }
}

export const getOrganizerBookmarksByType = (formGroups: FormGroup[], type: GroupType, sourceDocumentEnabled: boolean = true): OrganizerBookmarkPage[] => {
    if (formGroups && formGroups.length === 0) return [];
    const index = formGroups?.findIndex(formGroup => formGroup.type === type);
    if (index === -1) {
        return []
    }
    const bookmarkPages = formGroups && formGroups[index]?.forms?.map((form: any) => {
        const organizerform = form as OrganizerForm;
        return {
            pageNo: form.individualFilePageNo,
            pageTitle: form.bookmark,
            enabledSourceDocument: sourceDocumentEnabled && organizerform.enabledSourceDocument,
            message: organizerform.message
        } as OrganizerBookmarkPage
    });
    return bookmarkPages;
}

export const getPages = (bookmarks: Bookmarks): number[] => {
    return bookmarks.pages.map((page: any) => page.pageNo);
}

const TopAdjustmentForSignatureControl = 12;
// export const getTopAdjustmentSignatureControlValue = (controlType: ControlType): number => {
//     switch (controlType) {
//         case ControlType.SignatureCheckBoxControl:
//         case ControlType.SignatureRadioButtonControl:
//             return 0;
//         default:
//             return TopAdjustmentForSignatureControl;
//     }
// }

export function getControlText(controlType: ControlType, dataType: DataType): string {
    switch (controlType) {
        case ControlType.Date:
            return "Date";
        case ControlType.Signature:
            return "Signature";
        case ControlType.Textbox:
            {
                switch (dataType) {
                    case DataType.Initial:
                        return "Initials";
                    case DataType.Name:
                        return "Name";
                    case DataType.Email:
                        return "Email";
                    case DataType.Text:
                        return "Text";
                    default:
                        return "";
                }
            }
        case ControlType.Checkbox:
            return "Checkbox";
        case ControlType.RadioButton:
            return "Radio Button";
        default:
            return "";
    }
}

export function getControlIcon(controlType: ControlType, dataType: DataType): string {
    switch (controlType) {
        case ControlType.Date:
            return "fas fa-calendar-alt";
        case ControlType.Signature:
            return "fas fa-signature";
        case ControlType.Textbox:
            {
                switch (dataType) {
                    case DataType.Email:
                        return "fas fa-envelope";
                    case DataType.Initial:
                        return "fas fa-font";
                    case DataType.Name:
                        return "fas fa-user-circle";
                    case DataType.Text:
                        return "fas fa-text-width";
                    default:
                        return "";
                }
            }
        case ControlType.Checkbox:
            return "fas fa-check-square";
        case ControlType.RadioButton:
            return "fas fa-dot-circle";
        default:
            return "";
    }
}

export function getControlTopPositionPDFJS(page: number, top: number, scale: number): number {
    const pageElement: any = ControlBase.getPageElement(page);
    const AWESOME_PDF_VIEWER_LETTER_HEIGHT = 1056;
    const AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_A4 = 842;
    const AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_LETTER = 792;
    const pageHeight = pageElement ? pageElement.offsetHeight : AWESOME_PDF_VIEWER_LETTER_HEIGHT;
    const baseHeight = pageHeight > AWESOME_PDF_VIEWER_LETTER_HEIGHT ? AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_A4
        : AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_LETTER;
    const topRatio = (pageHeight * scale) / baseHeight;

    top = pageHeight - top;
    top = (top / topRatio) - 5;

    return top;

}

export function getRadioButtonLeftAdjustmentValue(question: QuestionControl): number {
    if (question.parentQuestionId && question.parentQuestionId !== Guid.empty && question.sectionId && question.sectionId !== Guid.empty)
        return 20;
    else if (question.parentQuestionId && question.parentQuestionId !== Guid.empty || question.sectionId && question.sectionId !== Guid.empty)
        return 13.3;
    return 6.6;
}

// TODO: Below code might be required at later point (Can be removed if not required)

// export function getControlHeight(control: Control): number {
//     let height = 0;
//     switch (control.controlType) {
//         case ControlType.quw: {
//             const questionControl = control as QuestionControl;
//             height = questionControl.textbox.boundingRectangle.height;
//             if (questionControl.inputTextbox && questionControl.inputTextbox?.boundingRectangle?.height) {
//                 height += questionControl.inputTextbox.boundingRectangle.height;
//             }
//             return height;
//         }
//         case ControlType.Section: {
//             const sectionControl = control as SectionControl;
//             return sectionControl.section.boundingRectangle.height;
//         }

//     }
//     return height;
// }
export function getControl(controlType: ControlType, dataType: DataType, left: number, top: number): Control {
    switch (controlType) {
        case ControlType.Date:
            return {
                controlType: controlType,
                textPlacement: TextPlacement.Left
            } as DateControl;
        case ControlType.Signature:
            return {
                controlType: controlType
            } as Signature;
        case ControlType.Textbox:
            return {
                controlType: controlType,
                dataType: dataType,
                textPlacement: TextPlacement.Left,
                required: true
            } as Textbox;
        default:
            return {} as Control;
    }
}
export const getSignatureControls = (): any[] => {
    return [
        {
            caption: 'Signature',
            controlType: ControlType.Signature,
            dataType: DataType.None,
            helpText: HelpTextConstant.ClientSignature
        },
        {
            caption: 'Initials',
            controlType: ControlType.Textbox,
            dataType: DataType.Initial,
            helpText: HelpTextConstant.Initial
        },
        {
            caption: 'Date Signed',
            controlType: ControlType.Date,
            dataType: DataType.None,
            helpText: HelpTextConstant.SignatureDate
        }];
}
// export const getTextControls = (): any[] => {
//     return [
//         {
//             caption: 'Print Name',
//             controlType: ControlType.Textbox,
//             dataType: DataType.Name,
//             helpText: HelpTextConstant.Name
//         },
//         {
//             caption: 'Company Field',
//             controlType: ControlType.Textbox,
//             dataType: DataType.None,
//             helpText: HelpTextConstant.Title
//         },
//         {
//             caption: 'Title',
//             controlType: ControlType.TextBox,
//             dataType: DataType.None,
//             helpText: HelpTextConstant.Title
//         },

//         {
//             caption: 'E-Mail',
//             controlType: ControlType.TextBox,
//             dataType: DataType.Email,
//             helpText: HelpTextConstant.Email
//         }
//     ];
// }
// export const getFormControls = () => {
//     return [
//         {
//             caption: 'Text',
//             controlType: ControlType.Textbox,
//             dataType: DataType.None,
//             helpText: HelpTextConstant.Title
//         }
//         , {
//             caption: 'Check Box',
//             controlType: ControlType.SignatureCheckBoxControl,
//             dataType: DataType.None,
//             helpText: HelpTextConstant.Checkbox
//         },
//         {
//             caption: 'Radio Button',
//             controlType: ControlType.SignatureRadioButtonControl,
//             dataType: DataType.None,
//             helpText: HelpTextConstant.RadioButton
//         }];
// };

// export const getEroControls = () => {
//     return [
//         {
//             caption: 'Add Signature Stamp',
//             controlType: ControlType.Textbox,
//             dataType: DataType.None,
//             helpText: HelpTextConstant.Title
//         }];
// };

/* ORG Integration Helper */

export const compareFormControl = (a: any, b: any) => {

    if (Math.abs(a.boundingRectangle.top - b.boundingRectangle.top) > 5) {

        if (a.boundingRectangle.top > b.boundingRectangle.top) return -1;

        if (a.boundingRectangle.top < b.boundingRectangle.top) return 1;
    }
    if (a.boundingRectangle.left < b.boundingRectangle.left) return -1;

    if (a.boundingRectangle.left > b.boundingRectangle.left) return 1;

    return 0;
}

export const getCheckBoxClassName = (userType: ClientType) => {
    if (userType === ClientType.Taxpayer) {
        return "checkbox-taxpayer";
    } else if (userType === ClientType.Spouse) {
        return "checkbox-spouse";

    }
}

export const getDatePickerClassName = (userType: ClientType) => {
    if (userType === ClientType.Taxpayer) {
        return "datepicker-taxpayer";
    } else if (userType === ClientType.Spouse) {
        return "datepicker-spouse";
    }
}