import React, { FC, createRef, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { LandingPageConstants } from "../../../helper/Constants";
import { useAppSelector } from "common/hooks/redux-hooks";
import { AppState } from "store";

interface ICpaMessageModalProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}
const CpaMessageModal: FC<ICpaMessageModalProps> = ({ show, setShow }) => {
    const messageRef = useRef<HTMLDivElement>(null);

    const message = useAppSelector((state : AppState)=>state.preparerMessageReducer?.message);

    useEffect(() => {     
        if(messageRef.current){
            messageRef.current.innerHTML = message;
        }
    }, [message,show]);

    return (
        <Modal className="cpa-message-modal" size="sm"  show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                {LandingPageConstants.MessageFromCPA}
            </Modal.Header>
            <Modal.Body ref={messageRef} />
        </Modal>
    );
}

export default CpaMessageModal;