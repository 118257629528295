import React, { FC } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { ChatIcon } from "../../../assets/svg/SVGIconsCollection";
import { HeaderConstants } from "../../../helper/Constants";
import ContactContent from "./ContactContent";

interface IContactPopupProps {
    placement?: "top" | "right" | "bottom" | "left";
    fromCoverPage?: boolean;
    name: string;
    mobileNo: string;
    emailAddress: string;
}

const popover = (name: string, mobileNo: string, emailAddress: string) => (
    <Popover id="popover-contact-popup">
        <Popover.Header>{HeaderConstants.ContactInformation}</Popover.Header>
        <Popover.Body>
            <ContactContent name={name} mobileNo={mobileNo} emailAddress={emailAddress} />
        </Popover.Body>
    </Popover>
);

const ContactPopup: FC<IContactPopupProps> = ({ placement, fromCoverPage, name, mobileNo, emailAddress }) => {
    return <>
        <OverlayTrigger trigger="click" placement={placement ?? "bottom"} overlay={popover(name, mobileNo, emailAddress)} rootClose>
            <div className="contact-popup ">
                {!fromCoverPage && <ChatIcon />}
                {fromCoverPage && <span className="otp-auth-contact-text" style={{cursor:'pointer'}}>Contact Us</span>}
            </div>
        </OverlayTrigger>
    </>;
};

export default ContactPopup;