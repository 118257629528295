import {
  ChangeEvent, FC,
  ReactElement,
  useEffect, useRef, useState
} from "react";
import './FileUpload.scss';
import { FileUploadIcon } from "assets/svg/SVGIconsCollection";
import { ButtonComponent } from "cp-common-ui-components";
import { ButtonConstant, FileUploadConstants } from "helper/Constants";
import { isMobile } from "../../../helper/HelperFunctions";
import { PreviewPageConstant } from "../../../helper/Constants";
import { useAppSelector } from "common/hooks/redux-hooks";
import { AppState } from "store";
import { Toaster } from "../Toasts";


interface FileDragAndDropProps {
  onUpload?: (files: File[]) => void;
  children?: ReactElement | ReactElement[];
  maxFilesCount?: number;
  allowedFileFormats?: string[];
  openDialogOnClick?: boolean;
  hoverText?: (({ maxFilesCount, allowedFileFormats }: { maxFilesCount?: number, allowedFileFormats?: string[] }) => string) | string;
  className?: string;
  onDragEnter?: () => void;
  onDragLeave?: () => void;
  onDrop?: (files: File[]) => void;
}

const FileDragAndDrop: FC<FileDragAndDropProps> = ({
  onUpload,
  children,
  maxFilesCount,
  allowedFileFormats,
  openDialogOnClick = true,
  hoverText = 'Drop files here',
  className,
  onDragEnter,
  onDragLeave,
  onDrop,
}) => {
  const [dragging, setDragging] = useState(false);
  const isClientView = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isClientView);
  const isPreview = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isPreview);

  const drag = useRef(null);
  const drop = useRef(null);
  const input = useRef(null);

  useEffect(() => {
    // @ts-ignore
    drop.current.addEventListener('dragover', handleDragOver);
    // @ts-ignore
    drop.current.addEventListener('drop', handleDrop);
    // @ts-ignore
    drop.current.addEventListener('dragenter', handleDragEnter);
    // @ts-ignore
    drop.current.addEventListener('dragleave', handleDragLeave);

    return () => {
      // @ts-ignore
      drop.current && drop.current.removeEventListener('dragover', handleDragOver);
      // @ts-ignore
      drop.current && drop.current.removeEventListener('drop', handleDrop);
      // @ts-ignore
      drop.current && drop.current.removeEventListener('dragenter', handleDragEnter);
      // @ts-ignore
      drop.current && drop.current.removeEventListener('dragleave', handleDragLeave);
    };
  }, []);

  const handleDragOver = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if(isPreview || isClientView){
      Toaster.warn(PreviewPageConstant.UploadedRestrictedMessage);
      return;
    }
    setDragging(false);
    const files = event.dataTransfer && event.dataTransfer.files ? [...event.dataTransfer.files] : [];
    onDrop && onDrop(files);
    handleUpload(files);
  };

  const handleDragEnter = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target !== drag.current) {
      setDragging(true);
      onDragEnter && onDragEnter();
    }
  };

  const handleDragLeave = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target === drag.current) {
      setDragging(false);
      onDragLeave && onDragLeave();
    }
  };

  const handleSelectFiles = (event: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const files = event.target ? [...event.target.files] : [];
    handleUpload(files);
  };

  const handleUpload = (files: File[]) => {
    if (allowedFileFormats &&
      files.some((file: File) => !allowedFileFormats.some((format) => file.name.toLowerCase().endsWith(format.toLowerCase())))) {
      return;
    }
    else if (files?.length > 0 && onUpload) {
      onUpload(files);
    }
  };

  const openFileDialog = (event: any) => {
    if (event.target.nodeName === 'BUTTON') {
      // @ts-ignore
      input && input.current.click();
    }
  };

  return (
    <div
      ref={drop}
      onClick={openDialogOnClick ? (e) => openFileDialog(e) : undefined}
    >
      <input style={{ display: "none" }}
        ref={input}
        type='file'
        accept={allowedFileFormats ? allowedFileFormats.map((format) => `.${format}`).join(', ') : undefined}
        multiple={!maxFilesCount || maxFilesCount > 1}
        onChange={handleSelectFiles}
      />
      <div className={`file-upload-container ${className}`}>
        {children}
      </div>
    </div>
  );
}

interface FileUploadProps extends FileDragAndDropProps {
  onButtonClick?: () => void;
  isIncludeHint?: boolean;

}
const FileUpload: React.FC<FileUploadProps> = ({ children, isIncludeHint, onButtonClick, ...props }) => {

  return (
    <FileDragAndDrop {...props}>
      {children ? children :
        <>
          <FileUploadIcon />
          {!isMobile() ? <>
            <p>Click or drag file<br /> to this area to upload</p>
            <ButtonComponent className='ss-btn-primary choose-file-button'
              onClick={onButtonClick}>
              {ButtonConstant.ChooseFile}
            </ButtonComponent>
            {isIncludeHint && <p className='file-warn-label'>{FileUploadConstants.MaxFileName}</p>}
          </> : <></>}
        </>
      }
    </FileDragAndDrop>
  );
}

export default FileUpload;
