import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { initialGatherStepsWithStatusModel, gatherStepsWithStatus } from "common/model/gatherStepsWithStatus";
import { store } from "..";

const gatherStepsWithStatusSlice = createSlice({
    name: 'gatherStepsWithStatus',
    initialState: initialGatherStepsWithStatusModel,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setGatherStepsWithStatus(state, action: PayloadAction<gatherStepsWithStatus[]>) {
            state.stepsWithStatus = action.payload;
            state.isLoading = false;
        },
        setCurrentStep(state, action: PayloadAction<string>) {
            let stepWithStatus: gatherStepsWithStatus[] = state.stepsWithStatus;
            stepWithStatus = stepWithStatus.map((step) => {
                if (step.name === action.payload) {
                    return { ...step, current: true };
                }
                else {
                    return { ...step, current: false };
                }
            })
            state.stepsWithStatus = stepWithStatus
            return state
        }
    }
})

export const { setIsLoading, setGatherStepsWithStatus, setCurrentStep } = gatherStepsWithStatusSlice.actions;

export default gatherStepsWithStatusSlice.reducer;