import { useEffect } from "react"
import { useAppDispatch } from "./redux-hooks";
import { requestGatherStepsWithStatus } from "store/services/landingPageStore";
import { useClientId } from "./client-hook";
import { setCurrentStep } from "store/slices/gatherStepsWithStatusSlice";


export const useUpdateCurrentStep = (currentStep: string) => {

    const dispatch = useAppDispatch();
    const [clientId] = useClientId();

    const stepCallBack = () => {
        dispatch(setCurrentStep(currentStep))
    }
    
    useEffect(() => {
            dispatch(requestGatherStepsWithStatus(clientId, stepCallBack))
    }, [])

}