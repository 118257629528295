import React, { useEffect, useState } from "react";
import { FooterComponent } from "cp-common-ui-components";
import { isMobile } from "../../helper/HelperFunctions";
import { PreviewPageConstant } from "../../helper/Constants";
import "../link-lock/index.scss";
import { useClientId } from "common/hooks/client-hook";
import { PreviewStatus } from "common/enum";
import { getPreviewDocumentStatus } from "store/services/preview";
import { useAppDispatch } from "common/hooks/redux-hooks";
import { GatherMetadata } from "common/model/organizer";
import { useNavigate } from "react-router-dom";
import { getGatherMetaData } from "store/services/esign";
import { TAXPAYER } from "route/paths";

const PreviewPage: React.FC = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [clientId] = useClientId();
    const [message, setMessage] = useState<string>(PreviewPageConstant.PreviewLoading);
    const [gatherMetadata, setGatherMetadata] = useState<GatherMetadata>(GatherMetadata?.createNullObject());
    const MAX_RETRY_COUNT = 10;

    useEffect(() => {
        dispatch(getGatherMetaData(clientId, (metaData: GatherMetadata) => {
            setGatherMetadata(metaData);
            checkForPreviewStatus(0);
        }));
    }, []);

    useEffect(() => {
        const message = gatherMetadata?.isPreview ? PreviewPageConstant.PreviewLoading : PreviewPageConstant.ClientViewLoading;
        setMessage(message);
    }, [gatherMetadata])

    const checkForPreviewStatus = (retryCount: number) => {
        dispatch(getPreviewDocumentStatus(clientId, (status: PreviewStatus) => {

            if (status == 2) {
                navigate(`${TAXPAYER.LANDING_PAGE}${clientId}`);
                return;
            }

            if (status == 3) {
                setMessage(gatherMetadata?.isPreview ? PreviewPageConstant.GeneratingPreviewFailedMessage : PreviewPageConstant.GeneratingClientViewFailedMessage);
                return;
            }

            if (retryCount === MAX_RETRY_COUNT) {
                setMessage(gatherMetadata?.isPreview ? PreviewPageConstant.GeneratingPreviewTimeLimitExceededMsg : PreviewPageConstant.GeneratingClientViewTimeLimitExceededMsg);
                return;
            }

            if (retryCount < MAX_RETRY_COUNT) {
                setTimeout(() => {
                    checkForPreviewStatus(retryCount + 1);
                }, 3000);
            }
        }));
    }

    return (
        <div className="link-lock-page-continer invalid-page">
            <div className="header-placeholder"></div>
            <div className="link-lock-page">
                <div className="link-lock-text-container">
                    {message}
                </div>
            </div>
            {<FooterComponent />}
        </div>
    );
};

export default PreviewPage;