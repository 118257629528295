import { FC, useState } from "react";

interface RightPanelContentProps {
    selectedSigner?: any,
    signers?: any[],
    isTogglable?: boolean,
    isReadOnlyMode?: boolean,
    rightPanelContent?: any,
    onSignerChange?: () => void,
    onFocusChange?: () => void;
}

const signers: any = [{ value: 1, label: "Taxpayer" }, { value: 2, label: "Spouse" }];

const RightPanelContent: FC<RightPanelContentProps> = ({ isTogglable, isReadOnlyMode, rightPanelContent }) => {
    
    return (<div className='right-panel-container'>
      {rightPanelContent}
    </div>);
}
export default RightPanelContent;