import Axios from "services/api/axios-interceptor"
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { API } from "services/api/api-config";
import { AppState } from "store";
import { setIsLoading as otpLoading } from "store/slices/otpPage";
import { setNotification } from "store/slices/notification";
import { StatusType } from "common/enum";
import { DisplayError, OtpPageConstants } from "helper/Constants";
import { LoginResponse } from "common/model/loginResponse";
import { ClientResponse } from "common/model/clientResponse";
import { setIsLoggedIn } from "../slices/auth-slice";
import { setLoader } from "store/slices/loader";


export const validateLink = (clientId: string, errorCallback?: () => void,callback?:(valid:boolean)=>void)
    : ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        try {
            dispatch(setLoader(true))

            Axios.get(`${API.OTP.VALIDATE_LINK}/${clientId}`, {
                method: 'GET',
                credentials: 'include'
            })
                .then((response) => response)
                .then((data) => {
                    const result: ClientResponse<any> = data

                    if (result.isSuccess) {
                        dispatch(setLoader(false))
                        callback && callback(result.isSuccess)
                    }
                    else {
                        const errorDesc = result.errorDescription
                        const errorCode = result.errorCode

                        if (errorCode && DisplayError.includes(errorCode)) {
                            dispatch(setNotification({
                                type: StatusType.Error,
                                message: errorDesc
                            }))
                            dispatch(setLoader(false));
                            errorCallback && errorCallback();
                        }
                        else {
                            dispatch(setNotification({
                                type: StatusType.Error,
                                message: errorDesc
                            }))
                            dispatch(setLoader(false));
                        }
                    }
                })
        }
        catch (error: any) {
            dispatch(setNotification({
                type: StatusType.Error,
                message: OtpPageConstants.serverError
            }))
            dispatch(setLoader(false));
        }
    }

export const generateOTP = (clientId: string, isMobile: boolean, callback: () => void)
    : ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
        try {
            dispatch(otpLoading(true))
            let apiEndPoint = `${API.OTP.GENERATE_OTP}/${clientId}`;
            if (isMobile) {
                apiEndPoint = `${API.OTP.GENERATE_MOBILE_OTP}/${clientId}`
            }
            Axios.get(apiEndPoint, {
                method: 'GET',
                credentials: 'include'
            })
                .then((response) => response)
                .then((data) => {
                    let result: boolean = data
                    if (result) {
                        dispatch(otpLoading(false))
                        callback()
                    }
                    else {
                        dispatch(setNotification({
                            type: StatusType.Error,
                            message: OtpPageConstants.serverError
                        }))
                    }
                })
        }
        catch (error: any) {
            dispatch(setNotification({
                type: StatusType.Error,
                message: OtpPageConstants.serverError
            }))
        }
    }

export const verifyOTP = (OTP: string, clientId: string, isMobile: boolean, handleVerify: (guid: string) => void, errorCallback: (errorState: string, retryLeft: number) => void, inValidCallback: () => void)
    : ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
        try {
            let apiEndPoint = `${API.OTP.VALIDATE_OTP}/${clientId}`;
            if (isMobile) {
                apiEndPoint = `${API.OTP.VALIDATE_MOBILE_OTP}/${clientId}`
            }
            Axios.post(apiEndPoint, OTP)
                .then((response) => response)
                .then((data) => {
                    const result: LoginResponse = data
                    if (result?.isSuccess) {
                        handleVerify && handleVerify(result?.data?.clientId)
                        dispatch(setIsLoggedIn(true))
                    }
                    else {
                        const errorDesc = result?.errorDescription
                        const errorCode = result?.errorCode
                        const retryLeft = result?.retryLeft

                        if (errorCode && DisplayError.includes(errorCode)) {

                            if (errorCode !== "ERROR_INVALID_OTP") {
                                dispatch(setNotification({
                                    type: StatusType.Error,
                                    message: errorDesc
                                }))
                            } else {
                                inValidCallback();
                            }
                            errorCallback && errorCallback(errorCode, retryLeft)
                        }
                    }
                })
        }
        catch (error: any) {
            dispatch(setNotification({
                type: StatusType.Error,
                message: OtpPageConstants.serverError
            }))
        }
    }

export const logOut = (clientId: string, callback:
    (clientId: string) => void): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
        Axios.get(`${API.OTP.LOGOUT}/${clientId}`)
            .then((response) => response)
            .then((data) => {

                const encodedClientId: string = data;
                if (encodedClientId.length) {
                    callback(encodedClientId)
                }
            })
            .catch((error: any) => {
                dispatch(setNotification({
                    type: StatusType.Error,
                    message: OtpPageConstants.serverError
                }))
            })
    }

export const refreshToken = 
(clientId:string)
: ThunkAction<void, AppState, unknown, Action> =>(dispatch)=>{
    Axios.get(`${API.OTP.REFRESH_TOKEN}/${clientId}`)
}