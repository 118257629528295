import { createSlice , PayloadAction } from "@reduxjs/toolkit";

export interface LoadingState {
    isLoading :boolean
    isModalLoading:boolean
}

const intialLoadingState : LoadingState={
    isLoading:false,
    isModalLoading:false
}


const loaderSlice = createSlice({
    name : 'loader',
    initialState:intialLoadingState,
    reducers:{
        setLoader(state,action:PayloadAction<boolean>){
            state.isLoading = action.payload
        },
        setModalLoader(state,action:PayloadAction<boolean>){
            state.isModalLoading = action.payload
        }
    }

})

export const { setLoader , setModalLoader } = loaderSlice.actions;

export default loaderSlice.reducer;
