import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { StatusType } from "common/enum";
import { NotificationState } from "common/model/notification";


const initialNotificationState: NotificationState = {
    message: "",
    type: StatusType.None
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState: initialNotificationState,
    reducers: {
        setNotification(state, action: PayloadAction<NotificationState>) {
            state.message = action.payload.message
            state.type = action.payload.type
        }
    }
})


export const { setNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
