import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialCommonState: {showNoticePopup: boolean} = {
    showNoticePopup: false,
}

const commonSlice = createSlice({
    name: 'commonSlice',
    initialState: initialCommonState,
    reducers: {
        setNoticePopup: (state, action: PayloadAction<boolean>) => {
            state.showNoticePopup = action.payload
        },
    }
})

export const { setNoticePopup } = commonSlice.actions;

export default commonSlice.reducer;