import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OtpPageStoreState } from "common/model/oTPPage";

const initialOTPState : OtpPageStoreState={
    loading:false,
}

const otpPageSlice = createSlice({
    name :'otpPage',
    initialState : initialOTPState,
    reducers :{
        setIsLoading :(state,action:PayloadAction<boolean>)=>{
            state.loading = action.payload
        },
    }
})

export const {setIsLoading} = otpPageSlice.actions;

export default otpPageSlice.reducer;