import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { ButtonComponent } from 'cp-common-ui-components';
import { FileViewer } from "./FileViewer";
import { GatherUploadedDocuments } from '../../../helper/Constants';
import DropdownComponent from '../UIComponents/DropdownComponent';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { getCategoryList, getDocumentRequestList, updateDocumentType } from '../../../store/services/documentRequestList';
import { ICategory } from '../../model/document-request-model';
import { useClientId } from '../../hooks/client-hook';

interface PreviewUplodedDRLFileModalProps {
    url: string,
    showPDFPreview: boolean;
    customClass: string | '';
    onHidePDFPreview: () => void;
    fileType: string | undefined;
    documentType: string;
    fileName: string;
    organizerStep?: boolean;
    documentId: number;
    isStepCompleted: boolean;
    isEncrypted: boolean;
}

const PreviewUplodedDRLFileModal: React.FC<PreviewUplodedDRLFileModalProps> = ({
    url,
    showPDFPreview,
    customClass,
    documentType,
    fileType,
    fileName,
    onHidePDFPreview,
    organizerStep,
    documentId,
    isStepCompleted,
    isEncrypted
}) => {

    const [docUrl, setDocUrl] = React.useState("")

    React.useEffect(() => {
        setDocUrl(url)
    }, [url])

    const { documentRequestNames, documentRequestList } = useAppSelector(state => state.documentRequestReducer);
    const documentRequestListOptions = documentRequestNames.map((item: any) => {
        return { label: item.name, value: item.id }
    });
    const [selectedDocumentType, setSelectedDocumentType] = React.useState<string | undefined>(undefined);
    const selectedDocumentCategory = documentRequestList.filter((item: ICategory) => item.documents.some(doc => doc.id === Number(selectedDocumentType)));
    const selectedDocument = selectedDocumentCategory[0]?.documents.find((doc: any) => doc.id === Number(selectedDocumentType));
    const dispatch = useAppDispatch();
    const [clientId] = useClientId();

    const handleUpdate = () => {
        const payload = {
            documentId: documentId,
            name: selectedDocument.name,
            gatherDocumentRequestId: selectedDocument.id,
            isOtherOptionEnabled: false,
            categoryId: selectedDocumentCategory[0].categoryId
        }
        dispatch(updateDocumentType(clientId, payload, () => {
            dispatch(getCategoryList(clientId));
            dispatch(getDocumentRequestList(clientId));
            onHidePDFPreview();
            setDocUrl("")
        }))
    };
    const PreviewNotAvailable = () => <div className='my-5 text-center'>Preview not supported for the file</div>
    return <div>
        <Modal className={`file-preview-modal white-modal ${customClass}`}
            show={showPDFPreview} onHide={() => {
                setSelectedDocumentType(undefined);
                onHidePDFPreview();
                setDocUrl("")
            }}>
            <div>
                <Modal.Header closeButton data-test-auto="">
                    <Modal.Title className='file-preview-modal-title'>
                        {GatherUploadedDocuments.PreviewDocument}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='file-preview-modal-body'>
                    {!organizerStep && <div className='file-name'>{fileName}</div>}
                    {!organizerStep &&
                        <>
                            <div className='preview-label'>Document Type</div>
                            <DropdownComponent id='file-type-dropdown'
                                clearable={false}
                                options={documentRequestListOptions}
                                selectedValue={selectedDocumentType}
                                onChange={(selectedValue) => setSelectedDocumentType(selectedValue)}
                                disabled={isStepCompleted} />
                        </>
                    }
                    <div className='preview-label'>Preview</div>
                    {docUrl ? isEncrypted ? <PreviewNotAvailable /> : <FileViewer url={docUrl} fileType={fileType} /> : <></>}
                </Modal.Body>
                {!organizerStep ? <Modal.Footer className='file-preview-modal-footer'>
                    <ButtonComponent disabled={isStepCompleted} onClick={handleUpdate}>Update</ButtonComponent>
                </Modal.Footer> : <></>}
            </div>
        </Modal>
    </div >
}
export default PreviewUplodedDRLFileModal;

