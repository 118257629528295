import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialSourceDocumentState, IPreviewFileData, UploadedDocumentStatus } from "common/model/document-request-model";

const sourceDocumentUploadSlice = createSlice({
    name: 'sourceDocumentUpload',
    initialState: initialSourceDocumentState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setSourceDocumentStatus(state, action: PayloadAction<UploadedDocumentStatus>) {
            state.uploadedDocumentStatus = action.payload;
        },
        setPreviewDocumentData(state, action: PayloadAction<IPreviewFileData>) {
            state.previewDocumentData = action.payload;
        }
    }
})

export const { setIsLoading, setSourceDocumentStatus, setPreviewDocumentData } = sourceDocumentUploadSlice.actions;

export default sourceDocumentUploadSlice.reducer;