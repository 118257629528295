import { API } from "services/api/api-config";
import Axios from "services/api/axios-interceptor";
import { HeaderConstants, SignalRConstants } from "helper/Constants";
import { ISignalRConnectionInfo } from "common/model/signal-r-connection-Info";

export const getWebSocketUrl = (
    clientId:string,
    callback: (data: any) => void) => {
    Axios.get(`${API.GET_WEBSOCKET_CONNECTION_URL}/${clientId}`, {
        credentials: 'include',
    })
    .then((response) => {
        if (callback)
        {
            callback(response);
        }
    })
    .catch((error: any) => {
    });
}

export const signalRConnectionInit = (user: string, apiBaseUrl: string, token: string, callback: (data: ISignalRConnectionInfo) => void) => {
    var userIdHeader = SignalRConstants.headers.userId.toString();
    var clientType = SignalRConstants.headers.clientType.toString();      
    let Authorization = HeaderConstants.Authorization;
    let Bearer = HeaderConstants.Bearer;
    Axios.post(`${apiBaseUrl}${API.SIGNALR_NEGOTIATE_URL}`, '',
    {
        [userIdHeader]: user,
        [clientType]: SignalRConstants.TP,
        [Authorization]: `${Bearer} ${token}`
    })
    .then((response) => response)
    .then((data:ISignalRConnectionInfo) => {
        if (callback)
        {
            callback(data);
        }
    })
    .catch((error: any) => {
    });
}

export const addGroup = (user: string, group: string, apiBaseUrl: string, token: string) => {
    var userIdHeader = SignalRConstants.headers.userId.toString();
    var clientType = SignalRConstants.headers.clientType.toString();    
    let Authorization = HeaderConstants.Authorization;
    let Bearer = HeaderConstants.Bearer;
    var data = JSON.stringify({
        recipient: user,
        groupname: group
    })

    Axios.post(`${apiBaseUrl}${API.SIGNALR_ADD_TO_GROUP_URL}`, data, {
        [userIdHeader]: user,
        [clientType]: SignalRConstants.TP,
        [Authorization]: `${Bearer} ${token}`
    })
    .then((resp: any) => {
        if (resp.status == 200) {
            console.log("User added to the group successfully")
        }
    })
    .catch((error: any) => {
    });
}