import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import { ClientModel, intialClients } from "common/model/clients";


const clientSlice = createSlice({
    name : 'client',
    initialState : intialClients,
    reducers:{
        setTaxpayerInfo(state, action: PayloadAction<ClientModel>){
            state.taxpayer.name = action.payload.name;
            state.taxpayer.emailAddress = action.payload.emailAddress;
            state.taxpayer.mobileNumber = action.payload.mobileNumber;
            state.taxpayer.clientGuid =action.payload.clientGuid;
            state.taxpayer.ero = action.payload.ero;
            state.taxpayer.countryCode = action.payload.countryCode;
            state.taxpayer.gatherStatus = action.payload.gatherStatus;
            state.taxpayer.signatureStatus = action.payload.signatureStatus;
            state.taxpayer.signingOrder = action.payload.signingOrder;
            state.taxpayer.reviewStatus = action.payload.reviewStatus;
            state.taxpayer.signedOn = action.payload.signedOn;
            state.taxpayer.clientType = action.payload.clientType;
            state.taxpayer.hideWarning = action.payload.hideWarning;
            state.taxpayer.isSigner = action.payload.isSigner;
            state.taxpayer.locationId = action.payload.locationId;
            state.taxpayer.id = action.payload.id;
            state.taxpayer.ssn = action.payload.ssn;
            state.taxpayer.isDeceased = action.payload.isDeceased;
        },
        setSpouseInfo(state,action:PayloadAction<ClientModel>){
            state.spouse.name = action.payload.name;
            state.spouse.emailAddress = action.payload.emailAddress;
            state.spouse.mobileNumber = action.payload.mobileNumber;
            state.spouse.clientGuid =action.payload.clientGuid;
            state.spouse.ero = action.payload.ero;
            state.spouse.countryCode = action.payload.countryCode;
            state.spouse.gatherStatus = action.payload.gatherStatus;
            state.spouse.signatureStatus = action.payload.signatureStatus;
            state.spouse.signingOrder = action.payload.signingOrder;
            state.spouse.reviewStatus = action.payload.reviewStatus;
            state.spouse.signedOn = action.payload.signedOn;
            state.spouse.clientType = action.payload.clientType;
            state.spouse.hideWarning = action.payload.hideWarning;
            state.spouse.isSigner = action.payload.isSigner;
            state.spouse.locationId = action.payload.locationId;
            state.spouse.id = action.payload.id;
            state.spouse.ssn = action.payload.ssn;
            state.spouse.isDeceased = action.payload.isDeceased;
        },
        setCurrentClient(state,action:PayloadAction<string>){
            if(state.taxpayer.clientGuid === action.payload){
                state.taxpayer.current = true;
            }
            else if(state.spouse.clientGuid === action.payload){
                state.spouse.current = true;
            }
        }
    }
})

export const { setTaxpayerInfo , setSpouseInfo,setCurrentClient} = clientSlice.actions;

export default clientSlice.reducer;
