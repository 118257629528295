import { Role } from "common/enum";

export type LoggedInUserInfo={
	role: Role;
	permission: string
}

export const initialLoggedInUserInfo: LoggedInUserInfo = {
	role: Role.None,
	permission: ""
}