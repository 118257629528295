import React, { useEffect, useRef, useState } from 'react';
import { ClientType, ControlType, Form, FormGroup, FormGroupStatus, GroupType, HeaderType } from 'common/enum';
import { CustomModal } from 'common/components/UIComponents/CustomModal';
import './index.scss';
import Header from 'common/components/layout/Header';
import { Footer } from 'pages/esign/Footer';
import { ButtonConstant, EsignConstants, StepName } from 'helper/Constants';
import { useAppDispatch } from 'common/hooks/redux-hooks';
import { downloadFile, getBatchEngagementLtterControlsData, getESignModelData, getESignStatus, getGatherMetaData, getSoupseInfo, skipEsign, submitSignedDocument } from 'store/services/esign';
import { useNavigate, useParams } from 'react-router-dom';
import { ELDocType, ElDocument, IElDocument, IElForm } from 'common/model/document';
import { PageProperties } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import { IDocumentType } from 'common/model/viewModel/DocumentSignatureDataViewModel';
import { IControl } from 'common/model/controls';
import { ESignDocumentControlState, IESignModel } from 'common/model/esign';
import { GatherMetadata, IGatherClientDetail, UploadMethod } from 'common/model/organizer';
import Enumerable from 'linq';
import { ControlBaseProps, ControlData } from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import { esignHelper } from 'helper/esignHelper';
import { Guid } from 'common/model/common';
import { FileList } from 'common/components/pdf-viewer/components/FileList';
import { useUpdateCurrentStep } from 'common/hooks/step-hook';
import useNextNavigation from '../../common/hooks/useNextNavigation';
import { TAXPAYER } from '../../route/paths';
import { Viewer } from 'common/components/pdf-viewer/Viewer';
import { FooterComponent } from 'cp-common-ui-components';
import { saveClientDetails } from 'store/services/clients';
import { ClientModel, initialClientModel } from 'common/model/clients';
import { isValidEmailAddress } from '../../helper/Validations';
import { isMobile } from 'helper/HelperFunctions';
import { setNoticePopup } from 'store/slices/common-slice';
import { useCompleted } from 'common/hooks/useCompletedHook';

const ESignComponent: React.FC = () => {

  const dispatch = useAppDispatch();

  const [esignModel, setEsignModel] = useState<IESignModel>({} as IESignModel);
  const [spouseEmail, setSpouseEmail] = useState('');
  const [finishEnabled, setFinishEnabled] = useState(false);
  const [showSpouseInfo, setShowSpouseInfo] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [engagementLetterStatus, setEngagementLetterStatus] = useState<FormGroupStatus>(FormGroupStatus.None);
  const [isSkipped, setIsSkipped] = useState(false);
  const [selectedPageNo, setSelectedPageNo] = useState<number>(1);
  const [isAdditionalDocument, setIsAdditionalDocument] = useState(false);
  const [controlList, setControlList] = useState<any[]>([]);
  const [documents, setDocuments] = useState<IElDocument[]>([]);
  const [document, setDocument] = useState<IElDocument>(ElDocument.createNullObject());
  const [startNavigationOnDocumentLoad, setStartNavigationOnDocumentLoad] = useState<boolean>(false);
  const [clientType, setClientType] = useState<ClientType>(ClientType.Taxpayer);
  const [hideNavigationControl, setHideNavigationControl] = useState<boolean>(false);
  const [signingCompleted, setSigningCompleted] = useState<boolean>(false);
  const [eSingcontrolStatus, setESingcontrolStatus] = useState<ESignDocumentControlState>(ESignDocumentControlState.None);
  const [spouseInfo, setSpouseInfo] = useState<ClientModel>(initialClientModel);
  const [gatherMetadata, setGatherMetadata] = useState<GatherMetadata>(GatherMetadata?.createNullObject());

  const nextNavigation = useNextNavigation();
  const navigate = useNavigate();

  const params = useParams();
  const clientId = params?.clientId ?? "";

  let _viewerRef: any = useRef<any>(null);

  useEffect(() => {
    dispatch(getESignStatus(clientId, (status: FormGroupStatus) => {
      validateEngagementStatus(status);
    }));

    dispatch(getGatherMetaData(clientId, (metaData: GatherMetadata) => {
      setGatherMetadata(metaData);
    }));

    dispatch(getSoupseInfo(clientId, (spouse: ClientModel) => {
      setSpouseEmail(spouse?.emailAddress);
      setSpouseInfo(spouse);
    }));
    dispatch(setNoticePopup(true));
  }, []);

  useUpdateCurrentStep(StepName.Sign)

  useEffect(() => {
    if (esignModel && esignModel.gatherId) {
      setDocumentControls(esignModel);
    }
  }, [esignModel]);


  useCompleted(StepName.Sign)

  const validateEngagementStatus = (esignStatus: FormGroupStatus) => {
    if (esignStatus === FormGroupStatus.ESigned || esignStatus === FormGroupStatus.ManuallySigned || esignStatus === FormGroupStatus.Reviewed) {
      // handleRedirect(`${API.COVER_PAGE}/${clientId}`);
    } else {
      dispatch(getESignModelData(clientId, (model: IESignModel) => {
        if (model.uploadMethod === UploadMethod.Batch && model.formGroups.some(x => x.type == GroupType.Engagement)) {
          setBatchEngagementLtterControlsData(model);
        }
        else {
          setEsignModel(model);
        }
      }));
      setEngagementLetterStatus(esignStatus);
      setStartNavigationOnDocumentLoad(true);
    }
  }

  const setBatchEngagementLtterControlsData = (model: IESignModel) => {

    dispatch(getBatchEngagementLtterControlsData(clientId, (jsondata: any) => {
      const formGroup: FormGroup | undefined = model.formGroups.find((e: any) => e.type === GroupType.Engagement);

      jsondata.forms.forEach((jsonform: any) => {
        formGroup?.forms.forEach((form: Form) => {
          if (jsonform.PageNo === form.pageNo) {
            form.formData = jsonform.formData
          }
        })
      })

      formGroup?.forms.forEach(function (form: any) { // sort for next naviagation
        if (form.formData && form.formData.controlList) {
          form.formData.controlList = form.formData.controlList.sort((x: any, y: any) => x.boundingRectangle.top - y.boundingRectangle.top).reverse();
        }
      });

      setEsignModel(model);
    }));
  }

  const clearAllControls = () => {
    _viewerRef.current._controlList.filter((x: any) => x != null).forEach((control: any) => {
      _viewerRef.current._controlLayer.removeControl(control.props.id);
    });
  }

  const onPageChanging = (pageProperties: PageProperties) => {
    setSelectedPageNo(pageProperties.page > 0 ? pageProperties.page : 1);
  }

  const showSpouse = () => {
    let client = esignModel.clients.find(x => x.clientGuid === clientId);
    return client?.signingOrder === 1 && spouseInfo && spouseInfo.id > 0 && spouseInfo.isDeceased === false
      && engagementLetterStatus !== FormGroupStatus.PartiallyReviewed
      && engagementLetterStatus !== FormGroupStatus.PartiallySigned
      && spouseInfo.isSigner
  }

  const setDocumentControls = (document: IESignModel) => {
    let documents: IElDocument[] = [];
    const engagementDocument = document;
    const client = engagementDocument.clients && engagementDocument.clients.find((x: any) => x.clientGuid === clientId);

    if (engagementDocument.formGroups !== undefined) {
      const engagementFormGroup = engagementDocument.formGroups.find((x: any) => x.type === GroupType.Engagement);

      if (engagementFormGroup !== undefined) {
        const controlsFound = engagementFormGroup.forms.length <= 0 ?
          true : engagementFormGroup.forms.some((x: any) => x.formData
            && x.formData.controlList.some((x: any) => x.controlRole === client?.clientType && x.controlType !== ControlType.Date) === true && x.formData.controlList.length > 0);
        let elForms: IElForm[] = [];
        if (controlsFound) {
          const formWithControls = engagementFormGroup.forms.filter((x: any) => x.formData
            && x.formData.controlList.filter((x: any) => x.controlRole === client?.clientType) && x.formData.controlList.length > 0);

          formWithControls.forEach((form: any) => {
            elForms.push({
              controls: form.formData.controlList.filter((x: any) => x.controlRole === client?.clientType),
              pageNo: form.individualFilePageNo
            })
          });
        }
        documents.push({
          id: engagementDocument.gatherId,
          url: engagementDocument.engagementLetterUrl,
          forms: elForms,
          type: ELDocType.EL,
          documentType: IDocumentType.None,
          fileGuid: Guid.newGuid(),
        });
      }
    }

    if (engagementDocument.additionalESignDocuments.length > 0) {
      const additionalDocs = engagementDocument.additionalESignDocuments;

      for (let doc of additionalDocs) {
        let elForms: IElForm[] = [];
        const sortFormData = doc.documentControls?.formData?.sort((a: any, b: any) => a.pageNo - b.pageNo);
        sortFormData?.forEach((form: any) => {
          elForms.push({
            controls: form.controlList.filter((x: any) => x.controlRole === client?.clientType),
            pageNo: form.pageNo
          })
        });
        documents.push({
          id: doc.id,
          url: doc.sasURL,
          forms: elForms,
          type: ELDocType.Additional,
          fileName: doc.fileName,
          documentType: doc.documentType,
          fileGuid: doc.fileGuid
        });
      }
    }

    const organizerFormGroup = engagementDocument.formGroups.find((x: any) => x.type === GroupType.OrganizerWithSignature);
    if (organizerFormGroup !== undefined) {
      const controlsFound = organizerFormGroup.forms.length <= 0 ?
        true : organizerFormGroup.forms.some((x: any) => x.formData
          && x.formData.controlList.some((x: any) =>
            x.controlRole === client?.clientType &&
            x.controlType !== ControlType.Date) === true &&
          x.formData.controlList.length > 0);
      const organizerSignForms: IElForm[] = [];
      if (controlsFound) {
        const formWithControls = organizerFormGroup.forms.filter((x: any) => x.formData
          && x.formData.controlList.filter((x: any) => x.controlRole === client?.clientType) && x.formData.controlList.length > 0);

        formWithControls.forEach((form: any) => {
          organizerSignForms.push({
            controls: form.formData.controlList.filter((x: any) => x.controlRole === client?.clientType),
            pageNo: form.individualFilePageNo
          })
        });
      }

      documents.push({
        id: engagementDocument.gatherId + 1,
        url: engagementDocument.organizerSignPageUrl,
        forms: organizerSignForms,
        type: ELDocType.OrganizerSignDocument,
        documentType: IDocumentType.Others,
        fileGuid: Guid.newGuid(),
      });
    }

    let controlStatus: ESignDocumentControlState = getControlStatus(documents);
    setDocuments(documents);
    setDocument(documents[0]);
    setClientType(client?.clientType ?? ClientType.Taxpayer);
    setESingcontrolStatus(controlStatus);
  }

  const getControlStatus = (documents: IElDocument[]) => {
    let allControls: IControl[] = [];

    documents.forEach(({ forms }) => {
      forms.forEach(({ controls }) => {
        allControls = [...allControls, ...controls];
      })
    })

    if (allControls.length < 1) {
      return ESignDocumentControlState.NoControles;
    }
    if (allControls.filter(({ required }) => required === true).length > 0) {
      return ESignDocumentControlState.RequiredControlExists;
    }
    else {
      return ESignDocumentControlState.RequiredControlNotExists;
    }
  }

  const toggleDeclineModal = () => setShowDeclineModal(!showDeclineModal);

  const setNextDocument = (nextDocument: IElDocument) => {
    if (nextDocument) {
      setDocument(nextDocument);
      setStartNavigationOnDocumentLoad(true);
      setSelectedPageNo(1);
      setIsAdditionalDocument(nextDocument && nextDocument.type === ELDocType.Additional ? true : false);
    }

  }
  const setLastDocument = (nextDocument: IElDocument) => {
    if (nextDocument) {
      setDocument(nextDocument);
      setStartNavigationOnDocumentLoad(true);
      setSelectedPageNo(1);
      setIsAdditionalDocument(nextDocument.type === ELDocType.Additional ? true : false);
    }
  }

  const setFirstDocument = () => {
    setDocument(documents[0]);
    setSelectedPageNo(1);
  }

  const isLastDocument = (document: IElDocument) => {
    if (document) {
      const filteredDocuments = documents.filter(x => x.forms.some(x => x.controls.length > 0));
      // set next document using index
      const nextDocumentIndex = filteredDocuments.indexOf(document) + 1;

      const nextDocument: IElDocument | undefined =
        Enumerable.from(filteredDocuments).elementAtOrDefault(nextDocumentIndex);

      if (!nextDocument) {
        return true;
      }
      return false;
    }
    return true;
  }

  const navigateToFirstUnsignedDocument = () => {
    const unsignedDocuments = documents.filter(x => !x.signCompleted);
    if (unsignedDocuments.length > 0) {
      if (unsignedDocuments?.length === 1) {
        if (unsignedDocuments[0].id !== document.id)
          clearAllControls();
        setLastDocument(unsignedDocuments[0]);
      }
      else {
        clearAllControls();
        setNextDocument(unsignedDocuments[0]);
      }
    }
    else
      return;
  }

  const setPdfDocument = () => {

    if (document.id > 0) {
      const esignDocuments = documents;
      const filteredDocuments = esignDocuments.filter((x: any) => x.forms.some((x: any) => x.controls.length > 0));
      const nextDocumentIndex = filteredDocuments.indexOf(document) + 1;

      const nextDocument: IElDocument | undefined =
        Enumerable.from(filteredDocuments).elementAtOrDefault(nextDocumentIndex);

      if (nextDocument === undefined) {
        navigateToFirstUnsignedDocument();
        return;
      }

      if (documents.length === nextDocumentIndex + 1) {
        clearAllControls();
        setLastDocument(nextDocument);
      }
      else {
        clearAllControls();
        setNextDocument(nextDocument);
      }
    }
    else {
      clearAllControls();
      setFirstDocument();
    }
  }

  const checkControlFound = () => {
    let controlpresent = documents.filter(y => y.forms.some((x) => x.controls.some((x: any) => x.controlRole === clientType
      && x.controlType !== ControlType.Date && x.controlData === null) === true)).length > 0;

    let requiredControlPresent = documents.filter(y => y.forms.some((x) => x.controls.some((x: any) => x.controlRole === clientType
      && x.controlType !== ControlType.Date && x.required === true) === true)).length > 0;

    return controlpresent && requiredControlPresent;
  }

  const checkAllDocumentsSigned = () => {
    return documents.filter(x => x.signCompleted).length === documents.length;
  }

  const setNavigationStatus = (show: boolean) => {
    setHideNavigationControl(show);
  }

  const updateControlData = (controlData: ControlData, controlProps: ControlBaseProps) => {

    const signCompleted = esignHelper.updateControlData(
      documents,
      controlData,
      controlProps
    );
    const updatedDocument = documents.filter(x => x.id === document.id)[0];
    setDocument(updatedDocument);
    setSigningCompleted(signCompleted);
  }

  const setControlValues = (callback?: any) => {
    const controls = _viewerRef.current._controlList.filter((x: any) => x != null);
    let tempcontrols = [...controlList];

    // Update existing signature controls data with the latest signature data
    var signatureControl = controls.find((c: any) => c.props.data["controlType"] === 1 && c.state.signatureData !== undefined);
    if (signatureControl) {
      const controlData = signatureControl.getControlData();
      tempcontrols.forEach((control: any) => {
        if (control.props.data["controlType"] === 1 && control.state.signatureData !== undefined) {
          control.state = { ...control.state, signatureData: controlData }
        }
      });
    }

    controls.forEach((control: any) => {
      const index = tempcontrols?.findIndex((x: any) => x?.props?.id === control?.props?.id);
      if (index < 0) {
        tempcontrols.push(control);
      }
      else {
        tempcontrols[index] = control;
      }
    });
    setControlList(tempcontrols);
    callback && callback(tempcontrols);
  }

  const handleFileClick = (engagementDocument: IElDocument, pageNo: number, isAdditionalDocument: boolean) => {
    const selectedDocument = documents.filter(x => x.id === engagementDocument.id).length > 0 ?
      documents.filter(x => x.id === engagementDocument.id)[0] : ElDocument.createNullObject();
    setControlValues();
    documents.length > 1 && clearAllControls();

    setSelectedPageNo(pageNo);
    setIsAdditionalDocument(isAdditionalDocument);
    setStartNavigationOnDocumentLoad(true);
    setDocument(selectedDocument);

    setTimeout(() => { _viewerRef.current._viewPanel.gotoPage(pageNo); }, 1000);
  }

  const handleSigningCompleted = () => {
    var signDocuments = [...documents]
    setFinishEnabled(signDocuments.filter(x => x.signCompleted).length === documents.length)
  }

  const handleNavigationFinish = () => {
    setControlValues();
    setPdfDocument();
  }

  const handleSkipAndFinishForPreview = () => {
    const isPreview = gatherMetadata?.isPreview;
    const isClientView = gatherMetadata?.isClientView;
    if (isPreview) {
      navigate(nextNavigation.nextURL);
      return true;
    }
    if (isClientView) {
      navigate(`${TAXPAYER.LANDING_PAGE}${clientId}`);
      return true;
    }
    return false;
  }

  const handleFinish = () => {
    if (!handleSkipAndFinishForPreview()) {
      setIsSkipped(false);
      if (showSpouse()) { // spouse found with signature controls
        setShowSpouseInfo(true);
      } else {
        setControlValues((controls: any[]) => { initializeSignDocument(controls) });
      }
    }
  }

  const handlePostEmailUpdate = () => {
    if (isSkipped) {
      dispatch(skipEsign(clientId, esignModel, () => {
        navigate(`${TAXPAYER.LANDING_PAGE}${clientId}`);
      }));
    }
    else {
      setControlValues((controls: any[]) => { initializeSignDocument(controls) });
    }
  }

  const initializeSignDocument = (controls: any[]) => {
    let esignDocumentModel = esignHelper.getServerModel(controls, esignModel);
    esignDocumentModel = esignHelper.getAdditionalEsignServerModel(controls, esignModel);
    dispatch(
      submitSignedDocument(clientId, esignDocumentModel, () => {
        navigate(nextNavigation.nextURL);
      })
    );
  }

  const skipESignature = () => {
    setIsSkipped(true);
    if (showSpouse()) { // spouse found with signature controls
      setShowSpouseInfo(true);
    } else {
      dispatch(skipEsign(clientId, esignModel, () => {
        navigate(`${TAXPAYER.LANDING_PAGE}${clientId}`);
      }));
    }
  }

  const handleSkip = () => {
    if (handleSkipAndFinishForPreview()) return;
    if (eSingcontrolStatus === ESignDocumentControlState.RequiredControlNotExists) {
      setShowDeclineModal(true);
    } else {
      skipESignature();
    }
  }


  return (
    <div className='position-fixed w-100'>
      <Header type={HeaderType.WITHSTEP} hideContact={true} />
      <div className='esign-page-container'>
        <Viewer
          ref={_viewerRef}
          forms={esignModel}
          controlsFound={checkControlFound()}
          onSigningComplete={handleSigningCompleted}
          clientId={clientId}
          documentUrl={document.url}
          isAdditionalDocument={document.type === ELDocType.EL || document.type === ELDocType.OrganizerSignDocument ? false : true}
          onNavigationFinish={handleNavigationFinish}
          finishTarget={isLastDocument(document) ? "awesome-multi-steps-btn-next-container" : ""}
          elForms={document?.forms}
          hideNavigationControl={hideNavigationControl}
          onPageChanging={onPageChanging}
          startNavigationOnDocumentLoad={startNavigationOnDocumentLoad}
          setNavigationStatus={setNavigationStatus}
          pageNo={selectedPageNo}
          document={document}
          downloadFile={(url, fileName) => { dispatch(downloadFile(url, fileName)); }}
          uploadMethod={esignModel.uploadMethod}
          onAddControlData={updateControlData}
          signCompleted={signingCompleted}
          organizerMetadata={gatherMetadata}
          controlStatus={eSingcontrolStatus}
          fileList={<FileList
            elDocuments={documents}
            selectedDocumentId={document.id}
            onFileClick={handleFileClick}
            selectedPageNo={selectedPageNo} />}
          isReadMode={gatherMetadata?.isPreview || gatherMetadata?.isClientView}
        />
        {
          // Spouse Required Modal
          <CustomModal
            className='spouse-required-modal'
            show={showSpouseInfo}
            onHide={() => {
              setSpouseEmail(spouseInfo.emailAddress);
              setShowSpouseInfo(false);
            }}
            title={EsignConstants.SpouseRequiredModalTitle}
            confirmButtonName={ButtonConstant.SaveAndContinue}
            disableConfirmButton={spouseEmail?.trim().length === 0}
            onSubmit={() => {
              const spouse: ClientModel = { ...spouseInfo, emailAddress: spouseEmail };
              if (isValidEmailAddress(spouseEmail)) {
                dispatch(saveClientDetails(clientId, spouse, () => {
                  handlePostEmailUpdate();
                  setShowSpouseInfo(false);
                }))
              }
            }}
          >
            <div>
              <p>{EsignConstants.SpouseRequiredModalText}</p>

              <div className="form-group">
                <label>Spouse’s Name</label>
                <span>{spouseInfo.name}</span>
              </div>
              <div className="form-group">
                <label htmlFor="spouseEmail">Email <label className={"emailaddress-required-indicator"}>*</label></label>
                <input type="email" id="spouseEmail" value={spouseEmail} onChange={(e) => { setSpouseEmail(e.target.value.trim()); }} />
              </div>
            </div>
          </CustomModal>
        }

        {
          // Decline Modal
          <CustomModal
            className='spouse-required-modal'
            show={showDeclineModal}
            onHide={toggleDeclineModal}
            title={EsignConstants.DeclineTitle}
            confirmButtonName={ButtonConstant.Confirm}
            onSubmit={skipESignature}
          >
            <div>
              <p>By making this document as <b>reviewed,</b> you are agreeing that you have read through the document(s) and understand its contents.
                <br /><br />
                Please confirm you understand the contents and you are declining to sign or complete signing the document.
              </p>
            </div>
          </CustomModal>
        }
      </div>
      {
        <Footer
          onFinish={handleFinish}
          onSkip={handleSkip}
          onBack={() => {
            navigate(TAXPAYER.LANDING_PAGE + clientId);
          }}
          controlStatus={eSingcontrolStatus}
          finishEnabled={checkAllDocumentsSigned() || gatherMetadata?.isPreview || gatherMetadata?.isClientView || !checkControlFound()} 
          isLast = {nextNavigation.isLast}/>
          
      }
      {!isMobile() ? <FooterComponent /> : <></>}
    </div>
  );
};

export default ESignComponent;
