import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IAuthenticationState {
    isLoggedIn: boolean;
    user: string | null;
    isLoadingUser: boolean;
}
const authenticationInitialState: IAuthenticationState = {
    isLoggedIn: false,
    user: null,
    isLoadingUser: false,
};

const initialAuthState: any = {
    authentication: authenticationInitialState,
}

const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        setAuthentication(state, action: PayloadAction<any>) {
            state.authentication = { user: action.payload, isLoadingUser: false };
        },
        setIsLoggedIn(state, action: PayloadAction<boolean>) {
            state.authentication.isLoggedIn = action.payload;
        }
    }
});

export const { setAuthentication, setIsLoggedIn } = authSlice.actions;

export default authSlice.reducer;
