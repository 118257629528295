import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import hardSet from 'redux-persist/es/stateReconciler/hardSet';
import authReducer from './slices/auth-slice';
import coverPageReducer from './slices/coverPage';
import organizerReducer from './slices/organizer'
import otpPageReducer from './slices/otpPage';
import customQuestionPageReducer from './slices/customQuestion';
import notificationReducer from './slices/notification';
import documentRequestReducer from './slices/document-request-slice';
import sourceDocumentUploadReducer from './slices/source-document-upload-slice';
import preparerMessageReducer from './slices/preparerMessageSlice';
import statusUpdateReducer  from './slices/statusUpdateSlice';
import gatherStepsWithStatusReducer from './slices/gatherStepsWithStatusSlice';
import clientReducer from './slices/clients'
import loaderReducer from './slices/loader'
import companySettings from './slices/company-settings-slice';
import commonReducer from './slices/common-slice';
import metaDataReducer from './slices/gather-metadata-slice';
import sourceDocumentMappingReducer from './slices/sourceDocumentMappingSlice';
import sourceDocumentMappingDetailsReducer from './slices/sourceDocumentMappingDetailsSlice';

const reducer: any = combineReducers({
    authReducer,
    coverPageReducer,
    otpPageReducer,
    notificationReducer,
    organizerReducer,
    customQuestionPageReducer,
    documentRequestReducer,
    sourceDocumentUploadReducer,
    preparerMessageReducer,
    gatherStepsWithStatusReducer,
    clientReducer,
    statusUpdateReducer,
    loaderReducer,
    companySettings,
    commonReducer,
    metaDataReducer,
    sourceDocumentMappingReducer,
    sourceDocumentMappingDetailsReducer
})

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: hardSet,
    blacklist: []
}

export const store = configureStore({
    reducer: reducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    devTools: process.env.NODE_ENV !== 'production',
})
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;

//export const persistor = persistStore(store);
