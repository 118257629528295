import { ApplicationInsights, DistributedTracingModes, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import Axios from 'services/api/axios-interceptor';
import { APP_INSIGHT_URL } from 'services/api/api-config';
import moment from 'moment';

let profileData: any;
const browserHistory = createBrowserHistory();
var reactPlugin = new ReactPlugin();

var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: '',
    disableInstrumentationKeyValidation: true,
    extensions: [reactPlugin],
    disableFetchTracking: false,
    disableAjaxTracking: false,
    enableCorsCorrelation: true,
    distributedTracingMode: DistributedTracingModes.W3C,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    disableCorrelationHeaders: false,
    enableAutoRouteTracking: true,
    extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory }
    }
}
});
appInsights.loadAppInsights();

export const getDataForAppInsights = (clientId?: string) => {
  Axios.get(`${APP_INSIGHT_URL}/${clientId}`).then((response: any) => {
    profileData = response;
    setTelemetryData();
  })
};

//Note: Middleware will be executed for every track event (PageView/Dependency/Trace/Exception/etc)
const setTelemetryData = () => {
  let cloudRoleName = "";
  if (cloudRoleName.length === 0) {
    appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
      if (profileData) {
        appInsights.config.instrumentationKey = profileData.instrumentationKey;
      }
      envelope.tags = envelope.tags || []
      envelope.tags.push({ 'ai.cloud.role': 'GTRTaxpayerService-UI' })
      if (envelope.baseType === "PageviewData" &&
        envelope.baseData != undefined &&
        envelope.ext != undefined) {
        envelope.baseData.name = setPageViewName(envelope.ext.trace.name);
    }

      if (profileData !== undefined) {
        envelope.tags.push({ 'ai.user.accountId': profileData.companyId })
        envelope.tags.push({ 'ai.user.id': profileData.userId })
      }

      var telemetryItem = envelope.baseData
      if (telemetryItem !== undefined && profileData !== undefined) {
        telemetryItem.properties = telemetryItem.properties || {}
        telemetryItem.properties['UserId'] = profileData.userId
        telemetryItem.properties['CompanyId'] = profileData.companyId
        telemetryItem.properties['CompanyName'] = profileData.companyName
        telemetryItem.properties['DocumentId'] = profileData.documentId
        telemetryItem.properties['Origin'] = 'GTRTaxpayerService-UI'
        telemetryItem.properties["ClientTime"] = moment.utc(new Date()).local().format('MM/DD/YYYY hh:mm A');
        telemetryItem.properties["TimeZone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      cloudRoleName = envelope.tags['ai.cloud.role'];
    })
  }
}
const setPageViewName = (refurl: string) => {
  if (refurl.includes("/Organizer/sign/")) {
      return "Organizer-Esign"
  }
  else if (refurl.includes("/engagementletter/sign")) {
      return "EngagementLetter-Esign"
  }
  else if (refurl.includes("/uploaddocuments")) {
      return "SourceDocuments-Upload"
  }
  else if (refurl.includes("/signingcomplete")) {
      return "Signing-Completed"
  }
  else {
      return "Organizer-Home"
  }

}

export default appInsights;