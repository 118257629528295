import { useEffect } from "react"
import {  useAppSelector } from "./redux-hooks";
import { AppState } from "store";
import { gatherStepsWithStatus } from "common/model/gatherStepsWithStatus";
import { GatherStepStatus } from "common/enum";
import { useNavigate } from "react-router-dom";
import { useClientId } from "./client-hook";
import { TAXPAYER } from "route/paths";
import { StepName } from "helper/Constants";


export const useCompleted = (currentStep: string) => {

    const stepsWithStatus: gatherStepsWithStatus[]  = useAppSelector((state:AppState)=>state.gatherStepsWithStatusReducer?.stepsWithStatus)

    const navigate = useNavigate();
    const [clientId] =useClientId()

    useEffect(() => {

        const currentStepWithStatus:gatherStepsWithStatus = stepsWithStatus.find(x=>x.name===currentStep)
        if(currentStepWithStatus?.status ===GatherStepStatus.Done && currentStepWithStatus?.name !==StepName.Upload){
            navigate(`${TAXPAYER.LANDING_PAGE}${clientId}`)
        }

    }, [stepsWithStatus])

}