import React from "react";
import { isMobile } from "../../helper/HelperFunctions";
interface DocumentMappingProgressBarProps {
	progressBarSize: number;
	strokeWidth: number;
	uploadedDocumentRequested: number;
	totalDocumentRequested: number;
}

const DocumentMappingProgressBar: React.FC<DocumentMappingProgressBarProps> = ({
	progressBarSize,
	strokeWidth,
	uploadedDocumentRequested,
	totalDocumentRequested,
}) => {
	const radius = progressBarSize / 2 - strokeWidth;
	const circumference = 2 * Math.PI * radius;

	const getProgressPercentage = () => {
		let percentage = 0;
		if (totalDocumentRequested !== 0) {
			percentage = Math.round((uploadedDocumentRequested / totalDocumentRequested) * 100);
			if (percentage < 1 && percentage > 0) {
				return 1;
			} else if (percentage > 99 && percentage < 100) {
				return 99;
			}
		}
		return percentage;
	};

	return (
		<div className="progress-bar-wrapper uploaded-document-progress-circle">
			<div className="number-wrapper">
				{!isMobile() && <span>
					{getProgressPercentage()}
					<sup>%</sup>
				</span>}
				<span>
					{uploadedDocumentRequested}/{totalDocumentRequested}
				</span>
			</div>
			<svg>
				<circle
					strokeDasharray={circumference}
					strokeDashoffset={`${circumference - (circumference * getProgressPercentage()) / 100
						}px`}
					cx={progressBarSize / 2}
					cy={progressBarSize / 2}
					r={radius}
				></circle>
			</svg>
		</div>
	);
};

export default DocumentMappingProgressBar;
