import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { setNoticePopup } from "store/slices/common-slice";
import { Modal } from "react-bootstrap";
import { ButtonComponent } from "cp-common-ui-components";
import { AppState } from "store";

const NoticePopup:FC = () => {
    const show = useAppSelector((state : AppState) => state.commonReducer.showNoticePopup);
    const taxyear = useAppSelector((state: AppState) => state.coverPageReducer?.taxYear)
    const dispatch = useAppDispatch();
    return (
        <Modal className="notice-popup" onHide={()=>dispatch(setNoticePopup(false))} show={show}>
            <Modal.Header closeButton>
                <h2>Notice</h2>
            </Modal.Header>
            <Modal.Body>
                <h3 className="margin-bottom-25-px">{`Welcome! Your ${taxyear} Tax Return Documents are Ready.`}</h3>
                <p className="margin-bottom-25-px">You will be able to e-sign documents and upload tax documents using this mobile device.</p>
                <p>To fill out your organizer or forward your tax documents, Please login using a PC or tablet.</p>
            </Modal.Body>
            <Modal.Footer>
                <ButtonComponent onClick={()=>dispatch(setNoticePopup(false))}>Ok</ButtonComponent>
            </Modal.Footer>
        </Modal>
    );
};

export default NoticePopup;