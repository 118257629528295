import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthenticationOption } from "common/enum";
import { GatherCompanySettingsModel } from "common/model/company-settings";

const initialCompanySettings: GatherCompanySettingsModel = {
    defaultSettings: {
        savedMessage: 0,
        initialEmail: 0,
        engLetterReminderEmail: 0,
        gatherReminderEmail: 0,
        sourceDocReminderEmail: 0,
        downloadEngagementLetterEmail: 0,
    },
    authenticationSettings: {
        authenticationOption: AuthenticationOption.None,
    },
    sourceDocumentSettings: {
        enabledSourceDocuments: false,
        notifyEveryTimeUploadOrDeleteSrcDoc: false,
        schNotification1PerDayUploadOrDeleteSrcDoc: false,
        noAdditionalCPANotificationRequired: false,
        enabledDocumentUploadNotification: false,
        documentUploadPercentage: 0,
        k1UploadPercentage: 0,
        enabledDocumentUploadPercentageNotification: false,
        enabledK1UploadPercentageNotification: false
    },
    reminderSettings: {
        automaticReminders: true,
        organizerReminder: {
            reminderDays: 0,
            enabled: false,
            isReadOnly: false,
        },
        esignReminder: {
            reminderDays: 0,
            enabled: false,
            isReadOnly: false,
        },
    },
    sourceDocumentSettingChangeFlag: false
};

const companySettingsSlice = createSlice({
    name: 'companySettings',
    initialState: initialCompanySettings,
    reducers: {
        setCompanySettingData(state, action: PayloadAction<GatherCompanySettingsModel>) {
            state.defaultSettings = action.payload.defaultSettings;
            state.authenticationSettings = action.payload.authenticationSettings;
            state.reminderSettings = action.payload.reminderSettings;
            state.sourceDocumentSettings = action.payload.sourceDocumentSettings;
            state.sourceDocumentSettingChangeFlag = false;
        }
    },
});

export const { setCompanySettingData } = companySettingsSlice.actions;

export default companySettingsSlice.reducer;