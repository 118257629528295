import * as React from "react";
import { Form } from "react-bootstrap";
import ParagraphQuestion from "./ParagraphQuestion";
import MultiChoiceQuestion from "./QuestionTypes/MultiChoiceQuestion";
import YesNoQuestion from "./YesNoQuestion";
import { ISectionItems, QuestionType } from "../../common/model/custom-questions-model";

interface SectionItemProps {
    sectionIndex: number;
    sectionItemIndex: number;
    sectionItem: ISectionItems;
    sectionItemsCount?: number;
    calculateProgress: () => void;
}

const SectionItem: React.FC<SectionItemProps> = ({
    sectionItem,
    sectionItemIndex,
    sectionIndex,
    calculateProgress,
}) => {
    const [isInputFocused, setIsFocused] = React.useState(false);

    const handleInputBlur = () => {
        setIsFocused(false);
        calculateProgress();
    };
    const handleInputFocus = () => {
        setIsFocused(true);
    };

    const getQuestionControl = () => {
        switch (sectionItem.questionType) {
            case QuestionType.PARAGRAPH:
                return (
                    <ParagraphQuestion
                        sectionIndex={sectionIndex}
                        sectionItemIndex={sectionItemIndex}
                        isRequired={sectionItem.isRequired}
                        answer={sectionItem.answer}
                        clientType={sectionItem.answer?.clientType}
                        handleInputBlur={handleInputBlur}
                        handleInputFocus={handleInputFocus}
                        isInvalid={sectionItem.isInValid}
                    />
                );
            case QuestionType.YESNO:
                return (
                    <YesNoQuestion
                        sectionIndex={sectionIndex}
                        sectionItemIndex={sectionItemIndex}
                        isRequired={sectionItem.isRequired}
                        isInvalid={sectionItem.isInValid}
                        clientType={sectionItem.answer?.clientType}
                        choices={sectionItem.choices || []}
                    />
                );
            case QuestionType.MULTICHOICE:
                return (
                    <MultiChoiceQuestion
                        sectionIndex={sectionIndex}
                        sectionItemIndex={sectionItemIndex}
                        isRequired={sectionItem.isRequired}
                        isInvalid={sectionItem.isInValid}
                        clientType={sectionItem.answer?.clientType}
                        choices={sectionItem.choices}
                    />
                );

            default:
                return <p>Control Not found</p>;
        }
    };
    return (
        <Form.Group className="question-preview-container">
            <div
                className={`question-container ${isInputFocused ? "active-question" : ""
                    }`}
            >
                <div className="question">
                    <Form.Label>
                        <span className="text-sapphire">{sectionItemIndex + 1}. {" "}</span>
                        {sectionItem.questionTitle}
                        {sectionItem.isRequired && <span className="cq-error-red"> *</span>}
                    </Form.Label>
                    {getQuestionControl()}
                </div>
            </div>
        </Form.Group>
    );
};

export default SectionItem;
