import { IFormData } from "common/model/custom-questions-model";
import { AutoSaveTime } from "helper/Constants";
import  { useEffect, useState } from "react";

type Data = IFormData

export const useAutoSave =(formData:any,initialFormData:any,autoSave:()=>void)=>{

    const [lastCheckedTime, setLastCheckedTime] = useState(Date.now());

    useEffect(() => {
        const intervalId = setInterval(() => {
          const currentTime = Date.now();
          const elapsedTime = currentTime - lastCheckedTime;
    
          if (elapsedTime >=AutoSaveTime) {
            autoSave();
            setLastCheckedTime(currentTime);
          }
        }, 1000); // Check every second for elapsed time
    
        return () => clearInterval(intervalId);
      }, [formData, initialFormData, lastCheckedTime,autoSave])

}