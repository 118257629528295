import { GatherStepStatus, Action } from "../enum/index"

export type gatherStepsWithStatusModel = {
    stepsWithStatus : gatherStepsWithStatus[],
    isLoading: boolean
}

export const initialGatherStepsWithStatusModel : gatherStepsWithStatusModel = {
    stepsWithStatus : [],
    isLoading: false
}

export type gatherStepsWithStatus = {
        name: string,
        isRequired: boolean,
        order: number,
        status: GatherStepStatus,
        action: Action,
        current:boolean
}