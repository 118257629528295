import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialStatusUpdateModel, statusUpdateModel } from "common/model/statusUpdateModel";

const statusUpdateSlice = createSlice({
    name: 'statusUpdate',
    initialState: initialStatusUpdateModel,
    reducers:{
        setIsLoading(state, action: PayloadAction<boolean>){
            state.isLoading = action.payload;
        },
        setStatusUpdateModel(state, action: PayloadAction<statusUpdateModel>){
            state.isStatusChanged = action.payload.isStatusChanged;
            state.notificationType = action.payload.notificationType;
            state.isLoading =false;
        }
    }
});

export const { setIsLoading, setStatusUpdateModel } = statusUpdateSlice.actions;
export default statusUpdateSlice.reducer;