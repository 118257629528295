import * as React from "react";
import { ButtonComponent } from "cp-common-ui-components";
import { Button } from "react-bootstrap";
import { CaretRight } from "../../assets/svg/SVGIconsCollection";

interface FooterProps {
    onSave(): void;
    onFinish(): void;
    showDeclineBtn?: boolean;
    showSaveBtn?: boolean;
    nextDisabled?: boolean;
    isLast?:boolean;
}

export const Footer: React.FC<FooterProps> = ({ onFinish, onSave, showDeclineBtn, showSaveBtn, nextDisabled,isLast }) => {
    return (
        <div className="questions-footer">
            {showSaveBtn && <Button
                variant="link"
                onClick={onSave}
                title={"Save & Close"}
                disabled={false}
                className="btn-save-and-close"
                data-test-auto="2d123d32-c0cd-4272-8749-ce74da5e10b5"
            >
                Save & Close
            </Button>}
            {
                showDeclineBtn && <ButtonComponent
                    onClick={onFinish}
                    variant="danger"
                >
                    Decline
                </ButtonComponent>

            }
            <div title="Complete & proceed">
                <ButtonComponent
                    onClick={onFinish}
                    disabled={nextDisabled}
                >
                   {isLast? "Finish":"Next"} 
                   {!isLast && <span className="margin-left-5-px"><CaretRight color={nextDisabled ? "#C4C6C8" : "#fff"} /></span>}
                </ButtonComponent>
            </div>
        </div>
    );
};
