import { FC, useEffect, useRef } from "react";
import { useAppSelector } from '../../common/hooks/redux-hooks';
import { AppState } from "store";
import { LandingPageConstants } from "../../helper/Constants";

const { MessageFromCPA } = LandingPageConstants;

const MessageSection: FC = () => {
    const messageRef = useRef<HTMLDivElement>(null);
    const message = useAppSelector((state : AppState)=>state.preparerMessageReducer?.message);
    useEffect(() => {
        if (messageRef.current) {
            messageRef.current.innerHTML = message;
        }
    }, [message]);

    return !!message ? (
        <div className="message-section">
            <p className="margin-bottom-10-px"><b>{MessageFromCPA}</b></p>
            {/* THE BELOW MESSAGE WILL BE REPLACED WITH DYNAMNIC DATA FROM BACKEND */}
            <div className="content" ref={messageRef} />
        </div>
    ) : (<></>);
};

export default MessageSection;
